import React, { useState, useEffect, useMemo } from "react";
import { ListItemButton, Card, Box, Typography } from "@mui/material";
import * as MaterialIcons from "@mui/icons-material";

/* ICONS */
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";

/* CUSTOM LIBRARIES */
import NuevoTipoTrabajadorModal from "./nuevoTipoTrabajadorModal";

/* CONTROLLER VARIABLES */
import { GenerateIcon, transition } from "../../../controller/utils";

export default function SeleccionarTiposTrabajadores(props) {
  const {
    setContinueValue,
    tipoTrabajadoresTotal,
    tipoTrabajadores,
    setTipoTrabajadores,
    refetch = () => {}
  } = props;

  const [tiposTrabajadoresSeleccionados, setTiposTrabajadoresSeleccionados] =
    useState(tipoTrabajadores);
  const [tiposId, setTiposId] = useState([
    ...tipoTrabajadores.map((tipo) => tipo.id)
  ]);
  const [openModal, setOpenModal] = useState(false);

  // useEffect(() => {
  // if (tipoTrabajadores.length > 0) {
  //   setTiposTrabajadoresSeleccionados(tipoTrabajadores);
  //   setTiposId([...tipoTrabajadores.map((tipo) => tipo.id)]);
  // }
  // }, [tipoTrabajadores]);

  useEffect(() => {
    setContinueValue(tipoTrabajadores.length > 0);
  }, [tiposTrabajadoresSeleccionados, tipoTrabajadores]);

  const handleClick = (event, tipo) => {
    let tt = [...tipoTrabajadores];
    if (tiposId.indexOf(tipo.id) === -1) {
      tt.push(tipo);
    } else {
      tt.splice(tiposId.indexOf(tipo.id), 1);
    }
    setTipoTrabajadores([...tt]);
    setTiposId([...tt.map((tipo) => tipo.id)]);
  };
  const handleCrearTipoTrabajador = () => setOpenModal(true);

  const handleSetOpenModal = (value) => {
    if (!value) {
      refetch();
      setOpenModal(false);
    }
  };

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "75px",
    justifyContent: "center",
    alignItems: "center"
  };
  const card = {
    transition: transition,
    height: "160px",
    width: "165px",
    maxWidth: "170px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#1876D1",
    padding: "20px 10px",
    gap: "11px",
    borderRadius: "15px",
    background: "#FFFFFF",
    border: "3px solid transparent",
    boxShadow: 3,
    "&:hover": {
      boxShadow: 1
    },
    "&.Mui-selected": {
      border: "3px solid #1876D1",
      boxShadow: 1,
      "&:hover": {
        boxShadow: 1,
        opacity: 0.8
      }
    },
    "& .MuiSvgIcon-root": {
      width: "65px",
      height: "65px"
    }
  };
  const crearCard = {
    transition: transition,
    height: "160px",
    width: "165px",
    maxWidth: "170px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#1876D1",
    color: "#FFFFFF",
    padding: "20px 10px",
    gap: "11px",
    borderRadius: "15px",
    boxShadow: 3,
    "&:hover": {
      background: "#1876D1",
      boxShadow: 1,
      opacity: 0.7
    },
    "& .MuiSvgIcon-root": {
      width: "65px",
      height: "65px"
    }
  };
  const textCard = {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "150%",
    textAlign: "center"
  };

  return (
    <Box sx={selectDatosBoxBox}>
      {tipoTrabajadoresTotal.map((value) => (
        <ListItemButton
          sx={card}
          component={Card}
          selected={tiposId.indexOf(value.id) > -1}
          key={value.id}
          onClick={(event) => handleClick(event, value)}
        >
          {GenerateIcon(value.icono)}
          <Typography sx={textCard}>{value.nombre}</Typography>
        </ListItemButton>
      ))}
      <ListItemButton
        sx={crearCard}
        component={Card}
        key="crear"
        onClick={handleCrearTipoTrabajador}
      >
        <GroupAddOutlinedIcon />
        <Typography sx={textCard}>Crear nuevo tipo de trabajador</Typography>
      </ListItemButton>
      <NuevoTipoTrabajadorModal
        openModal={openModal}
        setOpenModal={handleSetOpenModal}
      />
    </Box>
  );
}

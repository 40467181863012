import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  TextField,
  ListItemButton,
  Select,
} from "@mui/material";

/* ICONS */
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/* CUSTOM LIBRARIES */
import CustomTable from "../common/components/customTable";
import CustomSelect from "../common/components/customSelect";
import FinishModal from "../common/modals/finishModal";
// import EditarFaenaModal from "./editarFaenaModal";
import NuevoTipoTrabajadorModal from "../Empresa/nuevoTipoTrabajadorModal";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import {
  estadosFaenas,
  headCellsListarFaenas,
  headCellsListarFaenasOutsourcing,
} from "../../../controller/listas";
import {
  transition,
  selectHeight,
  selectMaxHeight,
  POLLING_INTERVAL,
} from "../../../controller/utils";
import { selectCurrentType } from "../../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  currentEmpresa,
  currentFaena,
  setFaena,
} from "../../../redux/entidadesSlice";
import {
  useDeleteFaenaMutation,
  useGetFaenasEmpresaQuery,
  useGetFaenasQuery,
} from "../../../api/faenasApiSlice";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText,
} from "../../../redux/confirmModalSlice";
import EditarFaenaModal from "./editarFaenaModal";
import { useLazyGetTrabajadoresFaenaQuery } from "../../../api/trabajadoresApiSlice";

export default function ListarFaenas() {
  const { setSubtitleValue } = useOutletContext();
  const userType = useSelector(selectCurrentType);
  const selectedEmpresa = useSelector(currentEmpresa);
  const selectedFaena = useSelector(currentFaena);
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();

  const [deleteFaena, deleteResult] = useDeleteFaenaMutation();

  const { currentData, isSuccess, isLoading, isFetching, refetch } =
    selectedEmpresa.id
      ? useGetFaenasEmpresaQuery(selectedEmpresa.id, {
          pollingInterval: POLLING_INTERVAL,
        })
      : useGetFaenasQuery(null, {
          pollingInterval: POLLING_INTERVAL,
        });

  const [getTrabajadoresFaena, getTrabajadoresStatus] =
    useLazyGetTrabajadoresFaenaQuery();

  const [accion, setAccion] = useState("");
  const [estado, setEstado] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCrearTipoTrabajador, setOpenCrearTipoTrabajador] = useState(false);
  const [openBajaModal, setOpenBajaModal] = useState(false);
  const [openBajaModal2, setOpenBajaModal2] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [listaFaenas, setListaFaenas] = useState([]);
  const [listaFaenasFiltrada, seListaFaenasFiltrada] = useState([]);
  const [confirmationText, setConfirmationText] = useState("");
  const [trabajadoresFaenaNum, setTrabajadoresFaenaNum] = useState(0);

  useEffect(() => {
    if (selectedEmpresa.nombre) {
      setSubtitleValue(`Empresa: ${selectedEmpresa.nombre}`);
    } else {
      setSubtitleValue("");
    }
  }, []);

  useEffect(() => {
    if (isSuccess && currentData) {
      setListaFaenas([...currentData.data]);
      seListaFaenasFiltrada([...currentData.data]);
    }
  }, [currentData, isSuccess, isLoading, isFetching]);

  useEffect(() => {
    if (openBajaModal) {
      handleOpenDeleteModal();
    }
    if (!openBajaModal2) {
      setOpenBajaModal(false);
    }
  }, [openBajaModal, openBajaModal2]);

  useEffect(() => {
    if (isSuccess) {
      if (listaFaenas.length > 0 || listaFaenas === currentData?.data) {
        let listaF =
          listaFaenas.lenght > listaFaenasFiltrada.lenght
            ? [...listaFaenasFiltrada]
            : [...listaFaenas];
        if (estado) {
          listaF = listaF
            .map((faenaObj) => {
              let re = new RegExp(`${estado}`, "gi");
              if (re.test(faenaObj.estado)) {
                return faenaObj;
              }
              return null;
            })
            .filter((faenaObj) => faenaObj !== null);
          seListaFaenasFiltrada(listaF);
        } else {
          seListaFaenasFiltrada(listaF);
        }
      }
    }
  }, [estado, currentData, isFetching, isLoading, isSuccess]);

  const handleLimpiaFiltros = () => {
    setEstado("");
  };

  const handleOpenDeleteModal = () => {
    handleGetTrabajadoresFaena();
    setConfirmationText("");
    setOpenBajaModal2(true);
  };

  const handleGetTrabajadoresFaena = async () => {
    const { data, isSuccess } = await getTrabajadoresFaena(
      await selectedFaena.id
    );
    if (isSuccess) {
      setTrabajadoresFaenaNum(data.data.length);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteFaena(selectedFaena.id).unwrap();
      dispatch(setSuccessText("¡La faena ha sido eliminada correctamente!"));
      refetch();
    } catch (e) {
      dispatch(
        setErrorText(
          "¡Hubo un error, intente nuevamente o contáctese con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
    }
  };

  const setSelectedFaena = (value) => {
    dispatch(setFaena({ ...value }));
  };

  /* CSS */
  const contenido = {
    minHeight: "58vh",
    height: "fit-content",
    maxHeight: "84vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "5.17vh",
    transition: transition,
  };
  const barraFiltros = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: littleSize ? "center" : "start",
    gap: "11px",
  };
  const filtrosBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap-reverse",
    alignItems: "center",
    gap: "11px",
  };
  const formControl = {
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    width: "16vw",
    minWidth: "210px",
  };
  const textStyleFinishModal = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "20px",
    color: "#818181",
  };
  const buttonNuevoTipo = {
    height: "40px",
    textTransform: "none",
    minHeight: "100%",
    fontWeight: 600,
    borderRadius: "10px",
    padding: "5px 20px",
    border: "2px solid #1876D1",
    ":hover": {
      border: "2px solid #1876D1",
    },
  };
  const confirmationBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      // border: "2px solid #DE5C5C",
      fontWeight: 400,
    },
  };
  const buttonLimparFiltros = {
    height: "fit-content",
    width: "fit-content",
    transition: transition,
    textTransform: "none ",
    borderRadius: "12px",
    background: "#1876D1",
    color: "#FCFBFB",
    fontWeight: 800,
    fontSize: "12px",
    gap: "5px",
  };
  const seleccionAcciones = {
    width: "100%",
    borderRadius: "10px",
    transition: transition,
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    height: selectHeight,
    maxHeight: selectMaxHeight,
    color: "#FCFBFB",
    background: "#1876D1",
    textTransform: "none",
    ".MuiSelect-icon": {
      color: "#FCFBFB",
    },
  };
  const seleccionAccionesDefaultValue = {
    width: "100%",
    // borderRadius: "10px",
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "150%",
    color: "#FCFBFB",
  };
  const filtroVacio = {
    width: "100%",
    borderRadius: "10px",
    transition: transition,
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "150%",
    color: "#818181",
    display: "flex",
    alignItems: "center",
    height: selectHeight,
    maxHeight: selectMaxHeight,
    "&:hover": {
      color: "#1876D1",
    },
  };

  return (
    <Box sx={contenido}>
      <Box sx={barraFiltros}>
        {estado ? (
          <Box sx={formControl}>
            <Button
              onClick={handleLimpiaFiltros}
              disableElevation={true}
              variant="contained"
              sx={buttonLimparFiltros}
            >
              <FilterListOffOutlinedIcon />
              Restablecer filtros
            </Button>
          </Box>
        ) : null}
        <Box sx={filtrosBox}>
          <Box sx={formControl}>
            <CustomSelect
              label="Filtrar por estado"
              value={estado}
              setValue={setEstado}
            >
              {estadosFaenas}
            </CustomSelect>
          </Box>
          {userType === "Admin" ? (
            <Button
              variant="outlined"
              sx={buttonNuevoTipo}
              onClick={(e) => setOpenCrearTipoTrabajador(true)}
            >
              Crear tipo trabajador
            </Button>
          ) : null}
          {userType === "Admin" || userType === "A" ? (
            <Box sx={formControl}>
              <Select
                value={accion}
                IconComponent={KeyboardArrowDownIcon}
                displayEmpty
                onChange={(event) => setAccion(event.target.value)}
                sx={seleccionAcciones}
                renderValue={
                  accion !== ""
                    ? undefined
                    : () => (
                        <Typography sx={seleccionAccionesDefaultValue}>
                          Más acciones
                        </Typography>
                      )
                }
              >
                <ListItemButton
                  sx={filtroVacio}
                  component={Link}
                  to="Crear Faena"
                >
                  Crear nueva faena
                </ListItemButton>
                {userType === "Admin" || userType === "Supp" ? null : (
                  <ListItemButton
                    component={Link}
                    to="Carga masiva"
                    sx={filtroVacio}
                  >
                    Carga masiva
                  </ListItemButton>
                )}
              </Select>
            </Box>
          ) : null}
        </Box>
      </Box>
      <CustomTable
        headCells={
          userType === "Admin" || userType === "Supp"
            ? headCellsListarFaenas
            : headCellsListarFaenasOutsourcing
        }
        rows={listaFaenasFiltrada}
        setRows={seListaFaenasFiltrada}
        variant="faenas"
        setLineaSeleccionada={setSelectedFaena}
        setOpenEditModal={setOpenEditModal}
        setOpenBajaModal={setOpenBajaModal}
        setOpenErrorModal={setOpenErrorModal}
        isLoading={isLoading}
      />
      <EditarFaenaModal
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        refetch={refetch}
      />
      {trabajadoresFaenaNum > 0 ? (
        <FinishModal
          openModal={openBajaModal2}
          setOpenModal={setOpenBajaModal2}
        >
          <Typography sx={textStyleFinishModal}>
            {trabajadoresFaenaNum == 1
              ? `¡${trabajadoresFaenaNum} trabajador sigue activo en esta faena!`
              : `¡${trabajadoresFaenaNum} trabajadores siguen activos en esta faena!`}
          </Typography>
        </FinishModal>
      ) : (
        <FinishModal
          openModal={openBajaModal2}
          setOpenModal={setOpenBajaModal2}
          handleContinue={handleDelete}
          buttonText="Eliminar"
          navigatePath="./"
          confirmationValue={
            confirmationText === `ELIMINAR ${selectedFaena.nombre}`
          }
        >
          <Typography sx={textStyleFinishModal}>
            Estás a punto de eliminar la faena "{selectedFaena.nombre}" ¿Estás
            seguro que quieres continuar?
          </Typography>
          <Box sx={confirmationBox}>
            <Typography sx={textStyleFinishModal}>
              Escriba "ELIMINAR" seguido por el nombre de la faena, para
              confirmar la eliminación de esta faena.
            </Typography>
            <TextField
              sx={textField}
              size="small"
              error
              required
              id="confirmation"
              type="text"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </Box>
        </FinishModal>
      )}
      <FinishModal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <Typography sx={textStyleFinishModal}>
          {selectedFaena.razon_rechazo}
        </Typography>
      </FinishModal>
      <NuevoTipoTrabajadorModal
        openModal={openCrearTipoTrabajador}
        setOpenModal={setOpenCrearTipoTrabajador}
      />
    </Box>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  ListItemButton,
  Card,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";

/* ICONS */
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";

/* CUSTOM LIBRARIES */
import CustomStepper from "../common/components/customStepper";
import DatosEmpresa from "../common/components/datosEmpresa";
import SeleccionarTiposTrabajadores from "./seleccionarTiposTrabajadores";
import SubirPlantillasTiposTrabajadores from "./subirPlantillasTiposTrabajadores";
import MisDatosPersonales from "../Mi Perfil/misDatosPersonales";
import CustomSelect from "../common/components/customSelect";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { stepsNuevaEmpresaOutsourcing } from "../../../controller/listas";
import { emptyFileObj, transition } from "../../../controller/utils";
import {
  setSuccessText,
  setOpenConfirmModal,
  setErrorText,
  setInfoText,
} from "../../../redux/confirmModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { currentEmpresa } from "../../../redux/entidadesSlice";
import {
  useLazyGetTipoTrabajadorQuery,
  useLazyGetTiposDocumentosEmpresaQuery,
} from "../../../api/tipotrabajadoresApiSlice";
import { useCreateUserMutation } from "../../../api/authApiSlice";
import {
  useAddEmpresaMutation,
  useGetEmpresasQuery,
  useUpdateEmpresaMutation,
} from "../../../api/empresasApiSlice";
import LoadingRocket from "../common/things/loadingRocket";

function MandantePage(props) {
  const { setContinueValue, setMandanteG, listaMandantes } = props;
  const selectedEmpresa = useSelector(currentEmpresa);
  const [mandante, setMandante] = useState("");
  const littleSize = littleSizeFunc();

  useEffect(() => {
    if (selectedEmpresa.id) {
      setMandante(selectedEmpresa.id);
    }
  }, []);

  useEffect(() => {
    setContinueValue(mandante);
  }, [mandante]);

  const handleSetMandante = (value) => {
    setMandanteG(value);
    setMandante(value);
  };

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : "",
  };
  const selectDatosBox = {
    gap: "0px",
    maxWidth: "15.6vw",
    width: "14.58vw",
    minWidth: "210px",
  };

  return (
    <Box sx={selectDatosBoxBox}>
      <Box sx={selectDatosBox}>
        <CustomSelect
          req={true}
          label="Seleccionar Mandante"
          value={mandante}
          setValue={handleSetMandante}
          valueName="nombre"
        >
          {listaMandantes}
        </CustomSelect>
      </Box>
    </Box>
  );
}

function FirstPage(props) {
  const { continueValue, setContinueValue, datos, setDatos } = props;
  const littleSize = littleSizeFunc();

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : "",
  };

  return (
    <Box sx={selectDatosBoxBox}>
      <MisDatosPersonales
        dense
        create
        continueValue={continueValue}
        setContinueValue={setContinueValue}
        datosUsuario={datos}
        setDatosUsuario={setDatos}
      />
    </Box>
  );
}

function SecondPage(props) {
  const { setContinueValue, datosEmpresa, setDatosEmpresa } = props;
  const littleSize = littleSizeFunc();

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : "",
  };

  return (
    <Box sx={selectDatosBoxBox}>
      <DatosEmpresa
        dense
        create
        setContinueValue={setContinueValue}
        datosEmpresa={datosEmpresa}
        setDatosEmpresa={setDatosEmpresa}
      />
    </Box>
  );
}

export default function NuevaEmpresaOutsourcing() {
  const littleSize = littleSizeFunc();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setTitleValue, setSubtitleValue } = useOutletContext();

  const { currentData, isSuccess, isLoading, isFetching } =
    useGetEmpresasQuery("mandante");
  const [triggerGetData, TipoDocsResult] = useLazyGetTipoTrabajadorQuery();

  const [createUser, createUserResult] = useCreateUserMutation();
  const [createEmpresa, createEmpresaResult] = useAddEmpresaMutation();
  const [updateEmpresa, updateEmpresaResult] = useUpdateEmpresaMutation();

  const [stepCount, setStepCount] = useState(0);
  const [continueValue, setContinueValue] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [tipoTrabajadoresTotal, setTipoTrabajadoresTotal] = useState([]);

  const [stepTitle, setStepTitle] = useState("");
  const [stepSubtitle, setStepSubtitle] = useState("");
  const [stepContent, setStepContent] = useState();

  const [mandante, setMandante] = useState();
  const [listaMandantes, setListaMandantes] = useState([]);
  const [tipoTrabajadores, setTipoTrabajadores] = useState([]);
  const [datosUsuario, setDatosUsuario] = useState({});
  const [datosEmpresa, setDatosEmpresa] = useState({
    tipo: "outsourcing",
  });

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (isSuccess && currentData) {
      setListaMandantes([...currentData.data]);
    }
  }, [currentData, isSuccess, isLoading, isFetching]);

  useEffect(() => {
    if (isLoading || isFetching) {
      setStepContent(<LoadingRocket dialog={false} dark tiny />);
    }
    if (stepCount === 0) {
      setStepTitle("Elegir empresa mandante");
      setStepSubtitle(
        "Por favor eligir la empresa mandante al cual vinculará la empresa outsourcing nueva."
      );
      setStepContent(
        <MandantePage
          setMandanteG={setMandante}
          continueValue={continueValue}
          listaMandantes={listaMandantes}
          setContinueValue={setContinueValue}
        />
      );
    }
    if (stepCount === 1) {
      setStepTitle("Datos del usuario");
      setStepSubtitle(
        "Por favor ingresar, los datos del usuario que usará el portal."
      );
      setStepContent(
        <FirstPage
          datos={datosUsuario}
          setDatos={setDatosUsuario}
          continueValue={continueValue}
          setContinueValue={setContinueValue}
        />
      );
    }
    if (stepCount === 2) {
      setStepTitle("Datos de la empresa");
      setStepSubtitle(
        "Para poder crear una nueva empresa, por favor primero ingrese los datos de la empresa y el contacto."
      );
      setStepContent(
        <SecondPage
          datosEmpresa={datosEmpresa}
          setDatosEmpresa={setDatosEmpresa}
          continueValue={continueValue}
          setContinueValue={setContinueValue}
          mandante={mandante}
          setMandante={setMandante}
        />
      );
    }
    if (stepCount === 3) {
      setStepTitle("Indique los tipos de trabajadores");
      setStepSubtitle(
        "Antes de continuar, por favor elija el tipo de trabajadores que al empresa manejará:"
      );
      setStepContent(
        <SeleccionarTiposTrabajadores
          setContinueValue={setContinueValue}
          tipoTrabajadoresTotal={tipoTrabajadoresTotal}
          tipoTrabajadores={tipoTrabajadores}
          setTipoTrabajadores={setTipoTrabajadores}
          refetch={handleGetTipoTrabajadoresData}
        />
      );
    }
    if (stepCount === 4) {
      setStepTitle("Subir plantillas");
      setStepSubtitle(
        "Suba las plantillas de los documentos de los tipos de trabajador indicados"
      );
      setStepContent(
        <SubirPlantillasTiposTrabajadores
          setContinueValue={setContinueValue}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          tipoTrabajadores={tipoTrabajadores}
          selectedEmpresa={datosEmpresa}
          opened={stepCount === 4}
        />
      );
    }
    if (stepCount === 5) {
      handleFinish();
    }
  }, [
    stepCount,
    listaMandantes,
    tipoTrabajadoresTotal,
    uploadedFiles,
    tipoTrabajadores,
  ]);

  const handleGetTipoTrabajadoresData = async () => {
    const { data, isSuccess } = await triggerGetData(false);
    if (isSuccess) {
      setTipoTrabajadoresTotal(data.data);
    }
  };

  const handleUpdateEmpresa = async () => {
    try {
      const empresaD = await updateEmpresa({
        id: datosEmpresa.id,
        updatedValues: { ...datosEmpresa, tipo: "outsourcing" },
      }).unwrap();
      setDatosEmpresa(empresaD);
      dispatch(setSuccessText(`Empresa editada con exito!`));
    } catch (e) {
      console.error(e);
      dispatch(
        setErrorText(`Hubo un error al editar la empresa, intente nuevamante`)
      );
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleCreateEmpresa = async () => {
    try {
      const empresaD = await createEmpresa({
        ...datosEmpresa,
        email_usuario: datosUsuario.email,
        region: datosEmpresa.region.id,
        tipo: "outsourcing",
      }).unwrap();
      setDatosEmpresa(empresaD);
      dispatch(setSuccessText(`Empresa creada con exito!`));
    } catch (e) {
      console.error(e);
      setStepCount(2);
      dispatch(
        setErrorText(`Hubo un error al crear la empresa, intente nuevamante`)
      );
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleCreateUsuario = async () => {
    try {
      const userD = await createUser({ ...datosUsuario, type: "A" }).unwrap();
      if (userD.name) {
        dispatch(
          setSuccessText(
            `¡Usuario creado con exito!\n\nLe enviaremos un email al correo ${datosUsuario.email}`
          )
        );
      } else {
        setStepCount(1);
        dispatch(
          setErrorText(
            `El email ${datosUsuario.email} ya está asociado a una cuenta.\n\nCambielo y vuelva a intentar.`
          )
        );
      }
    } catch (err) {
      dispatch(
        setErrorText(
          `Hubo un problema al momento de crear la empresa y/o usuario\n\nIntente nuevamente más tarde o contactese con nosotros`
        )
      );
      console.error("ERROR");
      console.error(err);
      // navigate("/Empresas");
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleFinish = () => {
    dispatch(
      setSuccessText(
        `¡Las plantillas están siendo analizadas!\n\n¡Se le notificará cuando estén listas!`
      )
    );
    dispatch(setOpenConfirmModal(true));
    navigate("/Empresas");
  };

  const handleContinue = async () => {
    setContinueValue(false);
    setStepCount(stepCount + 1);
    if (stepCount === 1) {
      if (datosUsuario.id) {
      } else {
        handleCreateUsuario();
      }
    }
    if (stepCount === 2) {
      if (datosEmpresa.id) {
        handleUpdateEmpresa();
      } else {
        handleCreateEmpresa();
      }
    }
  };
  const handleBack = () => {
    setContinueValue(true);
    setStepCount(stepCount - 1);
  };

  /* CSS */
  const todo = {
    padding: "0 0 0 0",
    height: "100%",
    minHeight: "58vh",
    height: "fit-content",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    transition: transition,
  };
  const datosButtonBox = {
    transition: transition,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: littleSize ? "center" : "normal",
    justifyContent: "space-between",
    gap: "5vh",
    padding: "0vh 0px 6vh 0px",
  };
  const datosBox = {
    transition: transition,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: littleSize ? "center" : "",
  };
  const tituloDatos = {
    transition: transition,
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "140%",
    color: "#1876D1",
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px",
    padding: "0vh 0px 6vh 0px",
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    position: littleSize ? (stepCount > 0 ? "" : "absolute") : "",
    visibility: littleSize ? (stepCount > 0 ? "visible" : "hidden") : "",
    opacity: stepCount > 0 ? 1 : 0,
    ":hover": {
      border: "2px solid #1876D1",
    },
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1",
  };

  return (
    <Box sx={todo}>
      <Box sx={datosButtonBox}>
        <CustomStepper stepCount={stepCount}>
          {stepsNuevaEmpresaOutsourcing}
        </CustomStepper>
        <Box sx={datosBox}>
          <Typography sx={tituloDatos}>
            Paso {stepCount + 1}: {stepTitle}
          </Typography>
          <Typography>{stepSubtitle}</Typography>
          {stepContent}
        </Box>
      </Box>
      <Box sx={buttonBox}>
        <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
          Volver
        </Button>
        <Button
          sx={button}
          disableElevation
          variant="contained"
          type="submit"
          onClick={handleContinue}
          disabled={!continueValue}
        >
          {stepCount === 1 && datosUsuario.id
            ? "Continuar"
            : stepCount === 1
            ? "Crear Usuario"
            : stepCount === 2 && datosEmpresa.id
            ? "Editar Empresa"
            : stepCount === 2
            ? "Crear Empresa "
            : stepCount < stepsNuevaEmpresaOutsourcing.length - 1
            ? "Continuar"
            : "Subir plantillas"}
        </Button>
      </Box>
    </Box>
  );
}

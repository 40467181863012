import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import {
  ListItemButton,
  Card,
  Box,
  Typography,
  TextField,
  FormHelperText,
  Button,
  Tooltip,
  Paper,
  IconButton,
  ListItem,
} from "@mui/material";

/* ICONS */
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

/* CUSTOM LIBRARIES */
import LoadingRocket from "../common/things/loadingRocket";
import CustomSelect from "../common/components/customSelect";
import CustomStepper from "../common/components/customStepper";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { Fn } from "../../../controller/utils";
import { listaRegiones } from "../../../controller/listas";
import {
  setOpenConfirmModal,
  setSuccessText,
  setErrorText,
} from "../../../redux/confirmModalSlice";
import { useDispatch } from "react-redux";
import { stepsNuevoSubcontratista } from "../../../controller/listas";
import { emptyFileObj, transition } from "../../../controller/utils";
import { useCreateUserMutation } from "../../../api/authApiSlice";
import {
  listaTiposDocumentos,
  listaTiposTrabajador,
} from "../../../controller/testData";
import { useAddFaenaMutation } from "../../../api/faenasApiSlice";
import { useSolicitarSubcontratadoMutation } from "../../../api/empresasApiSlice";

function Datos(props) {
  const [d, setD] = useState({
    nombreContacto: "",
    correo: "",
    telefonoContacto: "",
    rutEmpresa: "",
    razon: "",
    nombreComercial: "",
    direccion: "",
    region: "",
    telefono: "",
  });
  const { datos = d, setDatos = setD, continueValue, setContinueValue } = props;

  const littleSize = littleSizeFunc();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [valueNombreContacto, setValueNombreContacto] = useState("");
  const [valueCorreo, setValueCorreo] = useState("");
  const [valueTelefonoContacto, setValueTelefonoContacto] = useState("");
  const [valueRutEmpresa, setValueRutEmpresa] = useState("");
  const [valueRazon, setValueRazon] = useState("");
  const [valueNombreComercial, setValueNombreComercial] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [valueRegion, setValueRegion] = useState("");
  const [valueTelefono, setValueTelefono] = useState("");

  const [errorNombreContacto, setErrorNombreContacto] = useState("");
  const [errorCorreo, setErrorCorreo] = useState("");
  const [errorTelefonoContacto, setErrorTelefonoContacto] = useState("");
  const [errorRutEmpresa, setErrorRutEmpresa] = useState("");
  const [errorRazon, setErrorRazon] = useState("");
  const [errorNombreComercial, setErrorNombreComercial] = useState("");
  const [errorRegion, setErrorRegion] = useState("");
  const [errorTelefono, setErrorTelefono] = useState("");

  useEffect(() => {
    if (Object.keys(datos).length) {
      setValueNombreContacto(datos.nombreContacto);
      setValueCorreo(datos.correo);
      setValueTelefonoContacto(datos.telefonoContacto);
      setValueRutEmpresa(datos.rutEmpresa);
      setValueRazon(datos.razon);
      setValueNombreComercial(datos.nombreComercial);
      setValueDireccion(datos.direccion);
      setValueRegion(datos.region);
      setValueTelefono(datos.telefono);
    }
  }, []);

  useEffect(() => {
    let dat = {
      nombreContacto: valueNombreContacto,
      correo: valueCorreo,
      telefonoContacto: valueTelefonoContacto,
      rutEmpresa: valueRutEmpresa,
      razon: valueRazon,
      nombreComercial: valueNombreComercial,
      direccion: valueDireccion,
      region: valueRegion,
      telefono: valueTelefono,
    };
    setDatos(dat);
  }, [
    valueNombreContacto,
    valueCorreo,
    valueTelefonoContacto,
    valueRutEmpresa,
    valueRazon,
    valueNombreComercial,
    valueDireccion,
    valueRegion,
    valueTelefono,
  ]);

  useEffect(() => {
    setContinueValue(
      valueNombreContacto &&
        valueCorreo &&
        valueTelefonoContacto &&
        !errorNombreContacto &&
        !errorCorreo &&
        !errorTelefonoContacto &&
        !errorRutEmpresa &&
        !errorRazon &&
        !errorNombreComercial &&
        !errorRegion &&
        !valueTelefono
    );
  }, [
    valueNombreContacto,
    valueCorreo,
    valueTelefonoContacto,
    valueRutEmpresa,
    valueRazon,
    valueNombreComercial,
    valueDireccion,
    valueRegion,
    valueTelefono,
  ]);

  const handleNotEmpty = (value, setValue, setError, error) => {
    setValue(value);
    if (isEmpty(value)) {
      setError(error);
    } else {
      setError("");
    }
  };
  const formatValueNumDocumento = (value) => {
    let rut = value;
    rut = rut.replace(/\.|-/g, "");
    if (rut.length > 2) {
      if (rut.length < 5) {
        rut = rut.slice(0, 1) + "." + rut.slice(1);
      } else if (rut.length < 9) {
        rut = rut.slice(0, 1) + "." + rut.slice(1, 4) + "." + rut.slice(4);
      } else if (rut.length === 9) {
        rut = rut.slice(0, 2) + "." + rut.slice(2, 5) + "." + rut.slice(5);
      } else if (rut.length > 9) {
        rut = rut.slice(0, 2) + "." + rut.slice(2, 5) + "." + rut.slice(5, 9);
      }
      if (rut.replace(".", "").length > 7) {
        rut = rut.slice(0, -1) + "-" + rut.slice(-1);
      }
    }
    return rut;
  };
  const handleRUTChange = (value) => {
    let rut = value.replace(/\.|-/g, "");
    rut = rut.slice(0, -1) + "-" + rut.slice(-1);
    setValueRutEmpresa(rut);
    if (!Fn.validaRut(rut) && isEmpty(rut)) {
      setErrorRutEmpresa("RUT inválido");
    } else {
      setErrorRutEmpresa("");
    }
  };
  const handleTelContactoChange = (value) => {
    let tel = value.includes("+56") ? value : `+56${value}`;
    setValueTelefonoContacto(tel);
    if (isMobilePhone(tel, "es-CL")) {
      setErrorTelefonoContacto("");
    } else {
      setErrorTelefonoContacto("Escriba un número de teléfono válido");
    }
  };
  const handleTelChange = (value) => {
    let tel = value.includes("+56") ? value : `+56${value}`;
    setValueTelefono(tel);
    if (isMobilePhone(tel, "es-CL")) {
      setErrorTelefono("");
    } else {
      setErrorTelefono("Escriba un número de teléfono válido");
    }
  };
  const handleMailChange = (value) => {
    setValueCorreo(value);
    if (isEmail(value)) {
      setErrorCorreo("");
    } else {
      setErrorCorreo("Escriba un correo válido");
    }
  };
  const handleClickVolver = (event) => {
    navigate("./..");
  };
  const handleContinue = () => {
    dispatch(
      setSuccessText("¡Tu solicitud ha sido ingresada de forma exitosa!")
    );
    dispatch(setOpenConfirmModal(true));
    navigate("./..");
  };

  /* CSS */
  const contenido = {
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "44px",
    justifyContent: "flex-start",
    alignItems: littleSize ? "center" : "start",
  };
  const seccionBox = {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "32px",
    alignItems: littleSize ? "center" : "start",
  };
  const titleSeccion = {
    color: "#1876D1",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: " 140%",
  };
  const seccion = {
    display: "flex",
    width: "100%",
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "20px 40px",
  };
  const textFieldBox = {
    maxWidth: "350px",
    width: "20vw",
    minWidth: "210px",
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400,
    },
  };
  const textFieldBoxTel = {
    display: "flex",
    flexDirection: "row",
  };
  const textFieldTelLeft = {
    width: "58px",
    height: "inherit",
    borderRadius: "4.34415px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomRightRadius: "0%",
      borderTopRightRadius: "0%",
      "& .MuiOutlinedInput-input": {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
  };
  const textFieldTelRight = {
    width: "calc(100% - 58px)",
    height: "inherit",
    borderRadius: "4.34415px",
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomLeftRadius: "0%",
      borderTopLeftRadius: "0%",
    },
  };

  return (
    <Box sx={contenido}>
      <Box sx={seccionBox}>
        <Typography sx={titleSeccion}>Información de contacto</Typography>
        <Box sx={seccion}>
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              required
              id="nombre"
              label="Nombre de contacto"
              type="text"
              value={valueNombreContacto}
              onChange={(e) =>
                handleNotEmpty(
                  e.target.value,
                  setValueNombreContacto,
                  setErrorNombreContacto,
                  "Escriba el nombre del contacto"
                )
              }
            />
            <FormHelperText error>{errorNombreContacto}</FormHelperText>
          </Box>
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              required
              id="correo"
              label="Correo electrónico"
              type="email"
              value={valueCorreo}
              onChange={(e) => handleMailChange(e.target.value)}
            />
            <FormHelperText error>{errorCorreo}</FormHelperText>
          </Box>
          <Box sx={textFieldBox}>
            <Box sx={textFieldBoxTel}>
              <TextField
                sx={textFieldTelLeft}
                size={"small"}
                fullWidth
                disabled
                label="+56"
              />
              <TextField
                sx={textFieldTelRight}
                size="small"
                fullWidth
                required
                id="telefono"
                label="Telefono de contacto"
                type="tel"
                value={valueTelefonoContacto.replace("+56", "")}
                onChange={(e) => handleTelContactoChange(e.target.value)}
              />
            </Box>
            <FormHelperText error>{errorTelefonoContacto}</FormHelperText>
          </Box>
        </Box>
      </Box>
      <Box sx={seccionBox}>
        <Typography sx={titleSeccion}>Información de empresa</Typography>
        <Typography>
          Si cuentas con la información legal de la empresa que quieres agregar
          como subcontratista, puedes ingresarla a continuación para acelerar el
          proceso de creación. Si no la tienes, de igual forma se la
          solicitaremos a la persona que ingresaste como contacto.
        </Typography>
        <Box sx={seccion}>
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              id="rut"
              label="RUT de empresa"
              type="text"
              value={formatValueNumDocumento(valueRutEmpresa)}
              onChange={(e) => handleRUTChange(e.target.value)}
            />
            <FormHelperText error>{errorRutEmpresa}</FormHelperText>
          </Box>
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              id="razon"
              label="Razón social"
              type="text"
              value={valueRazon}
              onChange={(e) => setValueRazon(e.target.value)}
            />
          </Box>
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              id="nombre comercial"
              label="Nombre de comercial"
              type="text"
              value={valueNombreComercial}
              onChange={(e) => setValueNombreComercial(e.target.value)}
            />
          </Box>
          <Box sx={textFieldBox}>
            <TextField
              sx={textField}
              size="small"
              fullWidth
              id="direccion"
              label="Dirección legal"
              type="text"
              value={valueDireccion}
              onChange={(e) => setValueDireccion(e.target.value)}
            />
          </Box>
          <Box sx={textFieldBox}>
            <CustomSelect
              label="Región"
              fontWeightValue={400}
              value={valueRegion}
              setValue={setValueRegion}
              errorText={valueRegion ? "" : errorRegion}
            >
              {listaRegiones}
            </CustomSelect>
          </Box>
          <Box sx={textFieldBox}>
            <Box sx={textFieldBoxTel}>
              <TextField
                sx={textFieldTelLeft}
                size={"small"}
                fullWidth
                disabled
                label="+56"
              />
              <TextField
                sx={textFieldTelRight}
                size="small"
                fullWidth
                id="telefono"
                label="Telefono"
                type="tel"
                value={valueTelefono.replace("+56", "")}
                onChange={(e) => handleTelChange(e.target.value)}
              />
            </Box>
            <FormHelperText error>{errorTelefono}</FormHelperText>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function SecondPage(props) {
  const [docs, setDocs] = useState({ comunes: listaTiposDocumentos });
  const {
    continueValue,
    setContinueValue,
    datos = docs,
    setDatos = setDocs,
    selectedDocsComun,
    setSelectedDocsComun,
    selectedDocsPorCargo,
    setSelectedDocsPorCargo,
  } = props;

  const littleSize = littleSizeFunc();
  const [selectedDocsInd, setSelectedDocsInd] = useState([]);
  const [showOtroComun, setShowOtroComun] = useState(false);
  const [showOtroInd, setShowOtroInd] = useState(false);
  const [valueOtroDocumentoComun, setValueOtroDocumentoComun] = useState("");
  const [errorOtroDocumentoComun, setErrorOtroDocumentoComun] = useState("");

  const [newDocs, setNewDocs] = useState([]);
  const [newCargo, setNewCargo] = useState("");
  const [newOtroCargo, setNewOtroCargo] = useState("");
  const [listaCargos, setListaCargos] = useState(
    listaTiposTrabajador.concat([{ id: -1, title: "Otro..." }])
  );
  const [valueOtroDocumentoInd, setValueOtroDocumentoInd] = useState("");
  const [errorOtroDocumentoInd, setErrorOtroDocumentoInd] = useState("");
  const [errorNewOtroCargo, setErrorNewOtroCargo] = useState("");

  useEffect(() => {
    setDatos({ ...docs });
  }, [docs]);

  useEffect(() => {
    setContinueValue(selectedDocsComun.length > 0);
  });

  const handleAnadirDoc = (event) => {
    let d = { ...docs };
    if (
      !d.comunes.some((doc) => {
        let re = new RegExp(`^${doc.title}$`, "gi");
        return re.test(valueOtroDocumentoComun);
      })
    ) {
      let otroDocumento = {
        title: `${valueOtroDocumentoComun}`,
        abreviacion: `${valueOtroDocumentoComun}`,
      };
      d.comunes.push(otroDocumento);
      setDocs(d);
      handleClickDocComun(event, otroDocumento);
      setValueOtroDocumentoComun("");
    } else {
      setErrorOtroDocumentoComun("Documento con ese nombre ya existe.");
    }
  };
  const handleAnadirDocInd = (event) => {
    let d = [...newDocs];
    if (
      !d.some((doc) => {
        let re = new RegExp(`^${doc.title}$`, "gi");
        return re.test(valueOtroDocumentoInd);
      })
    ) {
      let otroDocumento = {
        title: `${valueOtroDocumentoInd}`,
        abreviacion: `${valueOtroDocumentoInd}`,
      };
      d.push(otroDocumento);
      setNewDocs(d);
      handleClickDocInd(event, otroDocumento);
      setValueOtroDocumentoInd("");
    } else {
      setErrorOtroDocumentoInd("Documento con ese nombre ya existe.");
    }
  };
  const handleChangeValueOtroDocComun = (value) => {
    setErrorOtroDocumentoComun("");
    setValueOtroDocumentoComun(value);
  };
  const handleChangeValueOtroDocInd = (value) => {
    setErrorOtroDocumentoInd("");
    setValueOtroDocumentoInd(value);
  };
  const handleChangeValueOtroNewCargo = (value) => {
    setErrorNewOtroCargo("");
    setNewOtroCargo(value);
  };
  const handleShowOtroComun = (event) => {
    event.preventDefault();
    setShowOtroComun(!showOtroComun);
  };
  const handleShowOtroInd = (event) => {
    event.preventDefault();
    setShowOtroInd(!showOtroInd);
  };
  const handleClickDocComun = (event, doc) => {
    let selected = [...selectedDocsComun];
    if (selected.indexOf(doc) === -1) {
      selected.push(doc);
    } else {
      selected.splice(selected.indexOf(doc), 1);
    }
    setSelectedDocsComun(selected);
  };
  const handleClickDocInd = (event, doc) => {
    let selected = [...selectedDocsInd];
    if (selected.indexOf(doc) === -1) {
      selected.push(doc);
    } else {
      selected.splice(selected.indexOf(doc), 1);
    }
    setSelectedDocsInd(selected);
  };
  const handleNotEmpty = (value, setValue, setError, error) => {
    setValue(value);
    if (isEmpty(value)) {
      setError(error);
    } else {
      setError("");
    }
  };
  const handleAnadirCargo = (event) => {
    event.preventDefault();
    let d = { ...docs };
    let lista = [...selectedDocsPorCargo];
    if (newCargo === -1) {
      lista.push({ nombre: newOtroCargo, documentos: selectedDocsInd });
    } else {
      lista.push({
        nombre: listaCargos.find((x) => x.id === newCargo).title,
        documentos: selectedDocsInd,
      });
    }
    setSelectedDocsPorCargo(lista);
  };
  const handleDeleteCargo = (cargo) => {
    let lista = [...selectedDocsPorCargo];
    lista = lista.filter((c) => c.nombre !== cargo.nombre);
    setSelectedDocsPorCargo(lista);
  };

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: littleSize ? "40px" : "0px",
    width: "100%",
    justifyContent: littleSize ? "center" : "",
  };
  const inputBoxComDocs = {
    display: "flex",
    flexDirection: "column",
    width: littleSize ? "100%" : "30%",
    height: "fit-content",
    gap: "22px",
    justifyContent: littleSize ? "center" : "space-between",
    // overflow: "hidden",
    alignItems: littleSize ? "center" : "start",
  };
  const inputBoxIndDocs = {
    display: "flex",
    flexDirection: "column",
    width: littleSize ? "100%" : "70%",
    height: "fit-content",
    gap: "22px",
    justifyContent: littleSize ? "center" : "space-between",
    // overflow: "hidden",
    alignItems: littleSize ? "center" : "start",
  };
  const text = {
    width: "fit-content",
    fontWeight: 600,
    fontSize: "20px",
    color: "#1876D1",
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400,
    },
  };
  const docsListBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    width: "90%",
    minWidth: "210px",
    height: "100%",
    flexWrap: "wrap",
    // overflowY: "auto",
    gap: "5px",
  };
  const docCard = {
    color: "#1876D1",
    background: "#FCFCFC",
    borderRadius: "20px",
    boxShadow: 0,
    border: "2px solid #1876D1",
    "&.MuiListItemButton-dense": {
      width: "fit-content",
      maxWidth: "fit-content",
    },
    "&:hover": {
      boxShadow: 0,
      opacity: 0.9,
    },
    "&.Mui-selected": {
      boxShadow: 0,
      background: "#1876D1",
      color: "#FCFCFC",
      "&:hover": {
        boxShadow: 0,
        background: "#1876D1",
        color: "#FCFCFC",
        opacity: 0.9,
      },
    },
  };
  const docCardText = {
    fontWeight: 500,
  };
  const textOtroDocBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "11px",
    minWidth: "210px",
    width: "100%",
  };
  const textOtroCargoBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "11px",
    minWidth: "210px",
    width: "100%",
    padding: "11px",
  };
  const textFieldCargo = {
    minWidth: "210px",
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400,
    },
  };
  const button = {
    width: "fit-content",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600,
  };
  const indColumn = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    minWidth: "210px",
    height: "100%",
    flexWrap: "wrap",
    gap: "22px",
  };
  const nuevoDocIndBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexWrap: "wrap",
    gap: littleSize ? "5px" : "0px",
  };
  const nuevosDocsInd = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: littleSize ? "100%" : "50%",
    minWidth: "210px",
    height: "100%",
    flexWrap: "wrap",
    gap: "11px",
  };
  const textAddedOtroCargoBox = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "11px",
    minWidth: littleSize ? "fit-content" : "210px",
    width: "100%",
    backgroundColor: "#1876D1",
    boxShadow: 3,
    padding: "10px",
    borderRadius: "20px",
  };
  const docListCargoAdded = {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    width: "fit-content",
    minWidth: "210px",
    height: "100%",
    flexWrap: "wrap",
    gap: "5px",
  };
  const docCardAdded = {
    background: "#1876D1",
    color: "#FCFCFC",
    borderRadius: "20px",
    boxShadow: 3,
    border: "1px solid #FCFCFC",
    "&.MuiListItem-dense": {
      width: "fit-content",
      maxWidth: "fit-content",
    },
  };
  const docCardTextAdded = {
    fontWeight: 500,
    fontSize: "14px",
  };
  const nuevosDocsIndTextField = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: littleSize ? "100%" : "50%",
    minWidth: "210px",
    height: "100%",
    flexWrap: "nowrap",
    gap: "11px",
  };
  const docIndPaperText = {
    fontSize: "18px",
    color: "#FCFCFC",
    fontWeight: 550,
  };
  const deleteButton = {
    color: "#FCFCFC",
    boxShadow: 3,
    background: "#DE5C5C",
    "&:hover": {
      background: "#DE5C5C",
      color: "#FCFCFC",
    },
  };

  return (
    <Box sx={selectDatosBoxBox}>
      <Box sx={inputBoxComDocs}>
        <Typography sx={text}>Documentos comunes:</Typography>
        <Box sx={docsListBox}>
          {docs.comunes.map((doc) => (
            <Tooltip
              title={doc.title}
              placement="top-end"
              arrow
              enterDelay="300"
              followCursor
            >
              <ListItemButton
                sx={docCard}
                dense
                component={Card}
                selected={selectedDocsComun.indexOf(doc) > -1}
                key={doc.abreviacion}
                onClick={(event) => handleClickDocComun(event, doc)}
              >
                <Typography sx={docCardText}>{doc.abreviacion}</Typography>
              </ListItemButton>
            </Tooltip>
          ))}
          <Tooltip
            title={"Agregar otro documento..."}
            placement="top-end"
            arrow
            enterDelay="300"
            followCursor
          >
            <ListItemButton
              sx={docCard}
              dense
              component={Card}
              selected={showOtroComun}
              key={"otro"}
              onClick={(event) => handleShowOtroComun(event)}
            >
              <Typography sx={docCardText}>Otro...</Typography>
            </ListItemButton>
          </Tooltip>
          {showOtroComun ? (
            <Box sx={textOtroDocBox}>
              <Box>
                <TextField
                  sx={textField}
                  variant="standard"
                  autoFocus
                  fullWidth
                  autoComplete="off"
                  id="otro"
                  label="Tipo documento"
                  type="text"
                  value={valueOtroDocumentoComun}
                  onChange={(e) =>
                    handleChangeValueOtroDocComun(e.target.value)
                  }
                />
                <FormHelperText error>{errorOtroDocumentoComun}</FormHelperText>
              </Box>
              <Button
                disabled={valueOtroDocumentoComun.length > 0 ? false : true}
                sx={button}
                variant="contained"
                onClick={handleAnadirDoc}
              >
                Añadir
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box sx={inputBoxIndDocs}>
        <Typography sx={text}>Documentos especificos según cargo:</Typography>
        <Box sx={indColumn}>
          {selectedDocsPorCargo.map((cargo) => (
            <Paper sx={textAddedOtroCargoBox}>
              <Typography sx={docIndPaperText} key={cargo.nombre}>
                {cargo.nombre}
              </Typography>
              <Box sx={docListCargoAdded}>
                {cargo.documentos.map((doc) => (
                  <Tooltip
                    title={doc.title}
                    placement="top-end"
                    arrow
                    enterDelay="300"
                    followCursor
                  >
                    <ListItem
                      sx={docCardAdded}
                      dense
                      component={Card}
                      key={doc.abreviacion}
                      // onClick={(event) => handleClickDocInd(event, doc)}
                    >
                      <Typography sx={docCardTextAdded}>
                        {doc.abreviacion}
                      </Typography>
                    </ListItem>
                  </Tooltip>
                ))}
              </Box>
              <IconButton
                sx={deleteButton}
                onClick={(e) => handleDeleteCargo(cargo)}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Paper>
          ))}
          <Box sx={textOtroCargoBox}>
            <CustomSelect
              label="Seleccionar Cargo"
              value={newCargo}
              setValue={setNewCargo}
              valueName="title"
            >
              {listaCargos}
            </CustomSelect>
            {newCargo === -1 ? (
              <Box>
                <TextField
                  sx={textFieldCargo}
                  variant="standard"
                  autoFocus
                  fullWidth
                  autoComplete="off"
                  id="otro"
                  label="Cargo"
                  type="text"
                  value={newOtroCargo}
                  onChange={(e) =>
                    handleChangeValueOtroNewCargo(e.target.value)
                  }
                />
                <FormHelperText error>{errorNewOtroCargo}</FormHelperText>
              </Box>
            ) : null}
          </Box>
          <Box sx={nuevoDocIndBox}>
            <Box sx={nuevosDocsInd}>
              {newDocs.map((doc) => (
                <Tooltip
                  title={doc.title}
                  placement="top-end"
                  arrow
                  enterDelay="300"
                  followCursor
                >
                  <ListItemButton
                    sx={docCard}
                    dense
                    component={Card}
                    selected={selectedDocsInd.indexOf(doc) > -1}
                    key={doc.abreviacion}
                    onClick={(event) => handleClickDocInd(event, doc)}
                  >
                    <Typography sx={docCardText}>{doc.abreviacion}</Typography>
                  </ListItemButton>
                </Tooltip>
              ))}
              <Tooltip
                title={"Agregar otro documento..."}
                placement="top-end"
                arrow
                enterDelay="300"
                followCursor
              >
                <ListItemButton
                  sx={docCard}
                  dense
                  component={Card}
                  selected={showOtroInd}
                  key={"otro"}
                  onClick={(event) => handleShowOtroInd(event)}
                >
                  <Typography sx={docCardText}>
                    Añadir tipo documento
                  </Typography>
                </ListItemButton>
              </Tooltip>
            </Box>
            {showOtroInd ? (
              <Box sx={nuevosDocsIndTextField}>
                <Box>
                  <TextField
                    sx={textField}
                    variant="standard"
                    autoFocus
                    fullWidth
                    autoComplete="off"
                    id="otro"
                    label="Tipo documento"
                    type="text"
                    value={valueOtroDocumentoInd}
                    onChange={(e) =>
                      handleChangeValueOtroDocInd(e.target.value)
                    }
                  />
                  <FormHelperText error>{errorOtroDocumentoInd}</FormHelperText>
                </Box>
                <Button
                  disabled={valueOtroDocumentoInd.length > 0 ? false : true}
                  sx={button}
                  variant="contained"
                  onClick={handleAnadirDocInd}
                >
                  Añadir
                </Button>
              </Box>
            ) : null}
          </Box>
          <Button
            disabled={selectedDocsInd.length > 0 ? false : true}
            sx={button}
            variant="contained"
            onClick={(e) => handleAnadirCargo(e)}
          >
            Agregar cargo con documentos
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default function NuevoSubcontratista() {
  const { setTitleValue, setSubtitleValue } = useOutletContext();
  const littleSize = littleSizeFunc();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [solicitarSubcontratado, { isLoading }] =
    useSolicitarSubcontratadoMutation();

  const [stepCount, setStepCount] = useState(0);
  const [continueValue, setContinueValue] = useState(false);

  const [stepTitle, setStepTitle] = useState("");
  const [stepSubtitle, setStepSubtitle] = useState("");
  const [stepContent, setStepContent] = useState();

  const [datos, setDatos] = useState({});
  const [datosDocumentos, setDatosDocumentos] = useState({});
  const [selectedDocsComun, setSelectedDocsComun] = useState([]);
  const [selectedDocsPorCargo, setSelectedDocsPorCargo] = useState([]);

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (stepCount === 0) {
      setStepTitle("Datos del usuario");
      setStepSubtitle(
        "Para solicitar la creación de un nuevo subcontratista, por favor completa el siguiente formulario. Nos pondremos en contacto con ellos para avanzar con la solicitud lo antes posible."
      );
      setStepContent(
        <Datos
          datos={datos}
          setDatos={setDatos}
          continueValue={continueValue}
          setContinueValue={setContinueValue}
        />
      );
    }
    if (stepCount === 1) {
      setStepTitle("Elección documentos");
      setStepSubtitle(
        "Por favor indique los tipos de documentos que se solicitará a los trabajadores del subcontratista."
      );
      setStepContent(
        <SecondPage
          datos={datosDocumentos}
          setDatos={setDatosDocumentos}
          continueValue={continueValue}
          setContinueValue={setContinueValue}
          selectedDocsComun={selectedDocsComun}
          setSelectedDocsComun={setSelectedDocsComun}
          selectedDocsPorCargo={selectedDocsPorCargo}
          setSelectedDocsPorCargo={setSelectedDocsPorCargo}
        />
      );
    }
    if (stepCount === 2) {
      handleFinish();
    }
  }, [stepCount, selectedDocsPorCargo, selectedDocsComun]);

  const handleFinish = async () => {
    try {
      const solicitud = await solicitarSubcontratado({
        datos: datos,
        documentos_comunes: selectedDocsComun,
        documentos_especificos: selectedDocsPorCargo,
      }).unwrap();
      dispatch(
        setSuccessText(
          `¡Solicitud de creación de subcontrato creada con exito!\n`
        )
      );
      navigate("../");
    } catch (err) {
      dispatch(
        setErrorText(
          `Hubo un problema al crear la solicitud\n\nIntente nuevamente más tarde o contactese con nosotros`
        )
      );
      console.error("ERROR");
      console.error(err);
    }
    setStepCount(1);
    setContinueValue(false);

    dispatch(setOpenConfirmModal(true));
  };

  const handleContinue = () => {
    setContinueValue(false);
    setStepCount(stepCount + 1);
  };
  const handleBack = () => {
    setContinueValue(true);
    setStepCount(stepCount - 1);
  };

  /* CSS */
  const todo = {
    padding: "0 0 0 0",
    width: "100%",
    height: "100%",
    minHeight: "58vh",
    height: "fit-content",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    transition: transition,
  };
  const datosButtonBox = {
    transition: transition,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: littleSize ? "center" : "normal",
    justifyContent: "space-between",
    gap: "5vh",
    padding: "0vh 0px 2vh 0px",
  };
  const datosBox = {
    transition: transition,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: littleSize ? "center" : "",
  };
  const tituloDatos = {
    transition: transition,
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "140%",
    color: "#1876D1",
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px",
    padding: "0vh 0px 2vh 0px",
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    position: littleSize ? (stepCount > 0 ? "" : "absolute") : "",
    visibility: littleSize ? (stepCount > 0 ? "visible" : "hidden") : "",
    opacity: stepCount > 0 ? 1 : 0,
    ":hover": {
      border: "2px solid #1876D1",
    },
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1",
  };

  return (
    <Box sx={todo}>
      <Box sx={datosButtonBox}>
        <CustomStepper stepCount={stepCount}>
          {stepsNuevoSubcontratista}
        </CustomStepper>
        <Box sx={datosBox}>
          <Typography sx={tituloDatos}>
            Paso {stepCount + 1}: {stepTitle}
          </Typography>
          <Typography>{stepSubtitle}</Typography>
          {stepContent}
        </Box>
      </Box>
      <Box sx={buttonBox}>
        <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
          Volver
        </Button>
        <Button
          sx={button}
          disableElevation
          variant="contained"
          type="submit"
          onClick={handleContinue}
          disabled={!continueValue}
        >
          {stepCount < stepsNuevoSubcontratista.length - 1
            ? "Continuar"
            : "Enviar solicitud"}
        </Button>
      </Box>
      <LoadingRocket isLoading={isLoading} />
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import {
  ListItemButton,
  Card,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  TextField
} from "@mui/material";

/* ICONS */
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

/* CUSTOM LIBRARIES */
import CustomStepper from "../common/components/customStepper";
import CustomSelect from "../common/components/customSelect";
import FinishModal from "../common/modals/finishModal";
import InfoTrabajadorForm from "../common/components/infoTrabajadorForm";
import DragDropFileList from "../common/components/dragAndDropList";
import PDFViewer from "../common/components/pdfViewer";
import LoadingRocket from "../common/things/loadingRocket";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { stepsNuevoTrabajador, cedulaFile } from "../../../controller/listas";
import {
  GenerateIcon,
  MAX_UPLOAD_FILES,
  transition
} from "../../../controller/utils";
import { currentEmpresa } from "../../../redux/entidadesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetFaenasQuery } from "../../../api/faenasApiSlice";
import { useLazyGetTipoTrabajadorQuery } from "../../../api/tipotrabajadoresApiSlice";
import {
  useAddCedulasMutation,
  useAddDocumentosTrabajadorNuevoMutation,
  useCheckContratosMutation,
  useCheckDocumentosTrabajadorNuevoMutation,
  useDeleteDocumentosYTrabajadoresMutation,
  useValidarUploadDocumentosYTrabajadoresMutation
} from "../../../api/docsApiSlice";
import {
  setErrorText,
  setOpenConfirmModal,
  setSuccessText
} from "../../../redux/confirmModalSlice";
import { useUpdatePersonasMutation } from "../../../api/personasApiSlice";
import { useUpdateTrabajadoresMutation } from "../../../api/trabajadoresApiSlice";

function FirstPage(props) {
  const {
    setContinueValue,
    cliente,
    setCliente,
    faena,
    setFaena,
    faenaNueva,
    setFaenaNueva,
    listaEmpresas,
    listaFaenas
  } = props;
  const selectedEmpresa = useSelector(currentEmpresa);
  const littleSize = littleSizeFunc();

  const [listaEmpresasCopy, setListaEmpresasCopy] = useState([]);
  const [listaFaenasCopy, setListaFaenasCopy] = useState([]);

  useEffect(() => {
    if (selectedEmpresa.id) {
      setCliente(selectedEmpresa.id);
    }
  }, []);

  useEffect(() => {
    setListaFaenasCopy([
      ...listaFaenas,
      { id: "otro", value: "Crear faena nueva" }
    ]);
    setListaEmpresasCopy(listaEmpresas);
  }, [listaFaenas, listaEmpresas]);

  useEffect(() => {
    setContinueValue(cliente && faena);
  }, [cliente, faena]);

  const handleSetCliente = (value) => {
    setCliente(value);
    let lista = [...listaFaenas].filter((faena) => faena.empresa === value);
    if (![...lista].includes(faena)) {
      setFaena("");
    }
    setListaFaenasCopy(lista, { id: "otro", value: "Crear faena nueva" });
  };

  const handleSetFaena = (value) => {
    setFaena(value);
    if (value != "otro") {
      let faena = listaFaenas.find((f) => f.id === value);
      let empresa = listaEmpresas.find((e) => faena.empresa === e.id);
      setCliente(empresa.id);
    }
  };

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "40px",
    justifyContent: littleSize ? "center" : ""
  };
  const selectDatosBox = {
    gap: "0px",
    maxWidth: "15.6vw",
    width: "14.58vw",
    minWidth: "210px"
  };
  const textFieldBox = {
    // maxWidth: "300px",
    width: "14.58vw",
    minWidth: "210px"
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    },
    "& .Mui-disabled": {
      opacity: 1
    }
  };

  return (
    <Box sx={selectDatosBoxBox}>
      <Box sx={selectDatosBox}>
        <CustomSelect
          req={true}
          label="Seleccionar Cliente"
          value={cliente}
          setValue={handleSetCliente}
        >
          {listaEmpresasCopy}
        </CustomSelect>
      </Box>
      <Box sx={selectDatosBox}>
        <CustomSelect
          req={true}
          label="Seleccionar Faena"
          value={faena}
          setValue={handleSetFaena}
        >
          {listaFaenasCopy}
        </CustomSelect>
      </Box>
      {faena == "otro" ? (
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="faena"
            label="Nombre de la faena"
            type="text"
            value={faenaNueva}
            onChange={(e) => setFaenaNueva(e.target.value)}
          />
        </Box>
      ) : null}
    </Box>
  );
}

function SecondPage(props) {
  const { setContinueValue, trabajador, setTrabajador, listaTiposTrabajador } =
    props;

  useEffect(() => {
    setContinueValue(trabajador);
  }, [trabajador]);

  const handleClick = (event, value) => {
    setTrabajador(value);
  };

  /* CSS */
  const selectDatosBoxBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "75px",
    justifyContent: "center",
    alignItems: "center"
  };
  const card = {
    transition: transition,
    height: "220px",
    width: "230px",
    maxWidth: "230px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#1876D1",
    padding: "43px 23px",
    gap: "22px",
    borderRadius: "15px",
    background: "#FFFFFF",
    border: "3px solid transparent",
    boxShadow: 3,
    "&:hover": {
      border: "3px solid #1876D1",
      boxShadow: 1
    },
    "&.Mui-selected": {
      border: "3px solid #1876D1",
      boxShadow: 1,
      background: "#1876D1",
      color: "#FFFFFF",
      "&:hover": {
        border: "3px solid #1876D1",
        boxShadow: 1,
        backgroundColor: "#1876D1",
        opacity: 0.8
      }
    },
    "& .MuiSvgIcon-root": {
      width: "65px",
      height: "65px"
    }
  };
  const textCard = {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "150%",
    textAlign: "center"
  };

  return (
    <Box sx={selectDatosBoxBox}>
      {listaTiposTrabajador.map((value) => (
        <ListItemButton
          sx={card}
          component={Card}
          selected={trabajador.id === value.id}
          key={value.id}
          onClick={(event) => handleClick(event, value)}
        >
          {GenerateIcon(value.icono)}
          <Typography sx={textCard}>{value.nombre}</Typography>
        </ListItemButton>
      ))}
    </Box>
  );
}

function UploadDocsPage(props) {
  const {
    type,
    setContinueValue,
    uploadedFiles,
    setUploadedFiles,
    setOpenModal,
    datos,
    setDatos,
    trabajador
  } = props;
  const dispatch = useDispatch();

  const [uploadContratos, resultadoSubirContratos] =
    useCheckContratosMutation();
  const [uploadDocs, resultadoSubirDocs] =
    useCheckDocumentosTrabajadorNuevoMutation();

  const [listaFilesNuevoTrabajador, setListaFilesNuevoTrabajador] = useState(
    []
  );
  const [listaContratos, setListaContratos] = useState([]);
  const [listaDocumentos, setListaDocumentos] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (type == "documentos") {
      let newDocs = JSON.parse(JSON.stringify(trabajador.tipo_documentos));
      newDocs.forEach(function (item, i) {
        if (item.abreviacion === "Contrato") {
          newDocs.splice(i, 1);
          newDocs.unshift(item);
        }
      });
      newDocs.map((tt) => (tt.acceptedFormats = ["application/pdf"]));
      setListaFilesNuevoTrabajador(newDocs);
    }
  }, []);

  useEffect(() => {
    if (type == "documentos") {
      let uploaded_files = [...uploadedFiles];
      let contratos_list = uploaded_files
        .splice(0, datos.length)
        .filter((file) => file.name && "size" in file);
      setListaContratos(contratos_list);
      let documentos_dict_list = [];
      uploaded_files.map((file, index) => {
        if (file.name && "size" in file) {
          var new_file = {};
          new_file["file"] = file;
          new_file["tipo_documento"] =
            listaFilesNuevoTrabajador[Math.floor(index / datos.length) + 1][
              "abreviacion"
            ];
          documentos_dict_list.push(new_file);
        }
      });
      setListaDocumentos(documentos_dict_list);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (type == "documentos") {
      let uploaded_files = [...uploadedFiles].filter(
        (file) => file.name && "tipo_documento" in file
      );
      setContinueValue(
        uploaded_files.length == datos.length * listaFilesNuevoTrabajador.length
      );
    }
  }, [listaFilesNuevoTrabajador, uploadedFiles]);

  useEffect(() => {
    if (listaContratos.length > 0) {
      handleSubirContratos();
    }
  }, [listaContratos]);

  useEffect(() => {
    if (listaDocumentos.length > 0) {
      handleSubirDocumentos();
    }
  }, [listaDocumentos]);

  useEffect(() => {
    setIsLoading(
      resultadoSubirContratos.isLoading || resultadoSubirDocs.isLoading
    );
  }, [resultadoSubirContratos, resultadoSubirDocs]);

  const handleSubirContratos = async () => {
    try {
      const response = await uploadContratos({
        documentos: listaContratos,
        tipo_trabajador: trabajador.id
      }).unwrap();
      let new_upload_files = [...uploadedFiles];
      response.map((file, index) =>
        new_upload_files.map((ufile, uindex) => {
          if (
            file["tipo_documento"] ==
            listaFilesNuevoTrabajador[Math.floor(uindex / datos.length)][
              "abreviacion"
            ]
          ) {
            new_upload_files[uindex] = file;
          }
        })
      );
      setUploadedFiles(new_upload_files);
    } catch (err) {
      console.error(err);
      if (err === 406) {
        dispatch(
          setErrorText(
            "¡Un documento no coincide con la plantilla, revise los documentos e intente nuevamente!"
          )
        );
      } else {
        dispatch(
          setErrorText(
            "¡Hubo un error al subir los documentos, intente nuevamente o conctace con nosotros!"
          )
        );
      }
      dispatch(setOpenConfirmModal(true));
    }
  };

  const handleSubirDocumentos = async () => {
    try {
      const response = await uploadDocs({
        documentos: listaDocumentos,
        tipo_trabajador: trabajador.id
      }).unwrap();
      let new_upload_files = [...uploadedFiles];
      response.map((file, index) =>
        new_upload_files.map((ufile, uindex) => {
          if (
            file["tipo_documento"] ==
            listaFilesNuevoTrabajador[Math.floor(uindex / datos.length)][
              "abreviacion"
            ]
          ) {
            new_upload_files[uindex] = file;
          }
        })
      );
      setUploadedFiles(new_upload_files);
    } catch (err) {
      console.error("ERROR", err);
      if (err === 406) {
        dispatch(
          setErrorText(
            "¡Un documento no coincide con la plantilla, revise los documentos e intente nuevamente!"
          )
        );
      } else {
        dispatch(
          setErrorText(
            "¡Hubo un error al subir los documentos, intente nuevamente o conctace con nosotros!"
          )
        );
      }

      dispatch(setOpenConfirmModal(true));
    }
  };

  return type == "documentos" ? (
    <DragDropFileList
      list={listaFilesNuevoTrabajador}
      setContinueValue={() => {}}
      uploadedFiles={uploadedFiles}
      setUploadedFiles={setUploadedFiles}
      multiple={false}
      multipleSingular
      maxCount={datos.length}
      isLoading={isLoading}
      // memory={false}
    />
  ) : (
    <DragDropFileList
      list={cedulaFile}
      setContinueValue={setContinueValue}
      uploadedFiles={uploadedFiles}
      setUploadedFiles={setUploadedFiles}
      multiple={false}
      multipleSingular
      maxCount={MAX_UPLOAD_FILES}
      // memory={false}
    />
  );
}

function InfoPage(props) {
  const { view, editar, setContinueValue, datos, setDatos } = props;

  const [cDatos, setCDatos] = useState(datos);
  const [forward, setForward] = useState(true);
  const [back, setBack] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setContinueValue(true);
  }, []);

  useEffect(() => {
    if (index === datos.length - 1) {
      setForward(false);
    }
    if (index === 0) {
      setBack(false);
    }
    if (index > 0) {
      setBack(true);
    }
    if (index < datos.length - 1) {
      setForward(true);
    }
  }, [index]);

  const onChangeView = (e, i) => {
    e.preventDefault();
    if (i >= 0 && i < datos.length) {
      setIndex(i);
      setDatos(cDatos);
    }
  };
  const setPersona = (persona) => {
    let copyDatos = [...datos];
    copyDatos[index] = persona;
    setCDatos(copyDatos);
    setDatos(copyDatos);
  };

  const contenido = {
    transition: transition,
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: "1vw"
  };
  const buttonStyle = {
    transition: transition,
    background: "#1876D1",
    color: "#FFFFFF",
    boxShadow: 5,
    ":hover": {
      background: "#1876D1",
      opacity: "0.95"
    },
    "&.Mui-disabled": {
      ":hover": {
        background: "#FFFFFF"
      }
    }
  };
  const imgBox = {
    transition: transition,
    width: "fit-content",
    height: "fit-content",
    display: "flex"
  };

  return datos.length > 0 ? (
    <Box sx={contenido}>
      <Tooltip
        title="Trabajador anterior"
        placement="top-end"
        enterDelay={300}
        arrow
      >
        <IconButton
          disabled={!back}
          sx={buttonStyle}
          onClick={(e) => onChangeView(e, index - 1)}
        >
          <NavigateBeforeIcon />
        </IconButton>
      </Tooltip>
      <InfoTrabajadorForm
        view={view}
        editar={editar}
        setContinueValue={setContinueValue}
        persona={datos[index]}
        setPersona={setPersona}
        index={index}
      />
      <Tooltip
        title="Trabajador siguiente "
        placement="top-start"
        enterDelay={300}
        arrow
      >
        <IconButton
          disabled={!forward}
          sx={buttonStyle}
          onClick={(e) => onChangeView(e, index + 1)}
        >
          <NavigateNextIcon />
        </IconButton>
      </Tooltip>
      <Box sx={imgBox}>
        <PDFViewer
          id={datos[index]["id"]}
          modal={false}
          download={false}
          type={view}
          title=""
        />
      </Box>
    </Box>
  ) : null;
}

export default function NuevoTrabajador() {
  const { setTitleValue, setSubtitleValue } = useOutletContext();
  const littleSize = littleSizeFunc();
  const dispatch = useDispatch();

  const faenasState = useGetFaenasQuery();
  const [triggerGetTipos, resultadoTipos] = useLazyGetTipoTrabajadorQuery();
  const [uploadCedulas, resultadoSubirCedulas] = useAddCedulasMutation();
  const [uploadDocumentos, resultadoSubirDocumentos] =
    useAddDocumentosTrabajadorNuevoMutation();
  const [updatePersona, resultadoUpdatePersona] = useUpdatePersonasMutation();
  const [updateTrabajador, resultadoUpdateTrabajador] =
    useUpdateTrabajadoresMutation();
  const [triggerDeleteUploaded, resultadoDelete] =
    useDeleteDocumentosYTrabajadoresMutation();
  const [triggerValidar, resultadoValidar] =
    useValidarUploadDocumentosYTrabajadoresMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [stepCount, setStepCount] = useState(0);
  const [continueValue, setContinueValue] = useState(false);
  const [openFinishModal, setOpenFinishModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedCedulas, setUploadedCedulas] = useState([]);
  const [datos, setDatos] = useState([]);
  const [datosFinales, setDatosFinales] = useState([]);

  const [cliente, setCliente] = useState("");
  const [faena, setFaena] = useState("");
  const [faenaNueva, setFaenaNueva] = useState("");
  const [trabajador, setTrabajador] = useState("");
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaFaenas, setListaFaenas] = useState([]);

  const [listaTiposTrabajador, setListaTiposTrabajador] = useState([]);

  useEffect(() => {
    setIsLoading(
      resultadoUpdatePersona.isLoading ||
        resultadoSubirDocumentos.isLoading ||
        resultadoSubirCedulas.isLoading
    );
  }, [
    resultadoUpdatePersona.isLoading,
    resultadoSubirDocumentos.isLoading,
    resultadoSubirCedulas.isLoading
  ]);

  useEffect(() => {
    setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (faenasState.isSuccess && faenasState.currentData) {
      let lista_e = [];
      let lista_f = [];
      faenasState.currentData?.data.map((t) => {
        if (!lista_e.find((e) => e.value === cliente)) {
          lista_e.push({
            id: t.empresa_mandante,
            value: t.nombre_empresa_mandante
          });
        }
        lista_f.push({
          id: t.id,
          value: t.nombre,
          empresa: t.empresa_mandante
        });
      });
      let jsonObject = lista_e.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      lista_e = Array.from(uniqueSet).map(JSON.parse);
      setListaEmpresas(lista_e);
      setListaFaenas(lista_f);
    }
  }, [faenasState.currentData, faenasState.isSuccess, faenasState.isLoading]);

  useEffect(() => {
    if (stepCount === 1) {
      handleGetTiposTrabajadoresData();
    }
    if (stepCount === 6) {
      setOpenFinishModal(true);
    }
  }, [stepCount]);

  const handleGetTiposTrabajadoresData = async () => {
    const { data, isSuccess } = await triggerGetTipos(null, false);
    if (isSuccess) {
      setListaTiposTrabajador([...data.data]);
    }
  };

  const handleSubirCedulas = async () => {
    try {
      const response = await uploadCedulas({
        documentos: uploadedCedulas.filter((dic) => "blob" in dic)
      }).unwrap();
      setDatos(response);
      setStepCount(stepCount + 1);
    } catch (err) {
      console.error(err);
      if (err == 424) {
        dispatch(
          setErrorText(
            "¡Hay un trabajador activo dentro de las cédulas activas, marquelo como dado de baja e intente nuevamente!"
          )
        );
      } else {
        dispatch(
          setErrorText(
            "¡Hubo un error al subir las cédulas, intente nuevamente o conctace con nosotros!"
          )
        );
      }
      setStepCount(stepCount);
      dispatch(setOpenConfirmModal(true));
    }
  };

  const handleSubirDocumentos = async () => {
    try {
      const response = await uploadDocumentos({
        documentos: uploadedFiles.filter((dic) => "blob" in dic),
        personas: datos,
        tipo_trabajador: trabajador.id
      }).unwrap();
      setDatosFinales(response);
      setStepCount(stepCount + 1);
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Hubo un error al subir las documentos, intente nuevamente o conctace con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
      setStepCount(stepCount);
    }
  };

  const handleUpdatePersona = async () => {
    try {
      const response = await updatePersona(datos).unwrap();
      setStepCount(stepCount + 1);
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Hubo un error al subir los datos del personal, intente nuevamente o conctace con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
      setStepCount(stepCount);
    }
  };

  const handleUpdatePersonaFinal = async () => {
    try {
      const response = await updateTrabajador(datosFinales).unwrap();
      setStepCount(stepCount + 1);
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Hubo un error al actualizar los datos del personal, intente nuevamente o conctace con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
      setStepCount(stepCount);
    }
  };

  const handleDeleteUpdatePersonaFinal = async () => {
    try {
      const response = await triggerDeleteUploaded(datosFinales).unwrap();
      setStepCount(stepCount - 1);
      setContinueValue(true);
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Ocurrio un error, intente nuevamente o conctace con nosotros!"
        )
      );
      dispatch(setOpenConfirmModal(true));
      setStepCount(stepCount);
    }
  };

  const handleValidarCreacionPersonal = async () => {
    try {
      const response = await triggerValidar({
        faena: faena,
        trabajadores: datosFinales
      }).unwrap();
      dispatch(setSuccessText("¡Trabajadores creados exitosamente!"));
    } catch (err) {
      console.error(err);
      dispatch(
        setErrorText(
          "¡Ocurrió un error con la creación de los trabajadores, intente nuevamente o conctace con nosotros!"
        )
      );
      setStepCount(stepCount);
    }
    dispatch(setOpenConfirmModal(true));
  };

  const handleContinue = () => {
    if (stepCount === 2) {
      handleSubirCedulas();
    }
    if (stepCount === 3) {
      handleUpdatePersona();
    }
    if (stepCount === 4) {
      handleSubirDocumentos();
    }
    if (stepCount === 5) {
      handleUpdatePersonaFinal();
    }
    if (stepCount === 6) {
      handleValidarCreacionPersonal();
    } else {
      setStepCount(stepCount + 1);
    }
  };

  const handleBack = () => {
    if (stepCount === 5) {
      handleDeleteUpdatePersonaFinal();
    } else {
      setStepCount(stepCount - 1);
    }
    setContinueValue(true);
  };

  /* CSS */
  const todo = {
    padding: "0 0 0 0",
    height: "100%",
    minHeight: "58vh",
    height: "fit-content",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    transition: transition
  };
  const datosButtonBox = {
    transition: transition,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: littleSize ? "center" : "normal",
    justifyContent: "space-between",
    gap: "5vh",
    padding: "0vh 0px 6vh 0px"
  };
  const datosBox = {
    transition: transition,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "3.882vh",
    alignItems: littleSize ? "center" : ""
  };
  const datosStyle = {
    transition: transition,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    alignItems: "center"
  };
  const tituloDatos = {
    transition: transition,
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "140%",
    color: "#1876D1"
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px",
    padding: "0vh 0px 6vh 0px"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    position: littleSize ? (stepCount > 0 ? "" : "absolute") : "",
    visibility: littleSize ? (stepCount > 0 ? "visible" : "hidden") : "",
    opacity: stepCount > 0 ? 1 : 0,
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1"
  };
  const titleFinishModal = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%",
    textAlign: "center"
  };
  const textBoxFinishModal = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  };
  const textStyleFinishModal = {
    textAlign: "center"
  };

  return (
    <Box sx={todo}>
      <Box sx={datosButtonBox}>
        <CustomStepper stepCount={stepCount}>
          {stepsNuevoTrabajador}
        </CustomStepper>
        {isLoading ? (
          <LoadingRocket isLoading={isLoading} />
        ) : stepCount === 0 ? (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>
              Paso 1: Seleccione el cliente y faena
            </Typography>
            <Typography>
              Para poder crear nuevos trabajadores, por favor primero seleccione
              el cliente y la faena al cual quiere asociarlos:
            </Typography>
            <FirstPage
              setContinueValue={setContinueValue}
              cliente={cliente}
              setCliente={setCliente}
              faena={faena}
              setFaena={setFaena}
              faenaNueva={faenaNueva}
              setFaenaNueva={setFaenaNueva}
              listaEmpresas={listaEmpresas}
              listaFaenas={listaFaenas}
            />
          </Box>
        ) : stepCount === 1 ? (
          resultadoTipos.isLoading ? (
            <LoadingRocket dialog={false} dark tiny />
          ) : (
            <Box sx={datosBox}>
              <Typography sx={tituloDatos}>
                Paso 2: Indique el tipo de los trabajadores
              </Typography>
              <Typography>
                Antes de continuar, por favor elija el tipo de los trabajadores
                que está creando:
              </Typography>
              <SecondPage
                setContinueValue={setContinueValue}
                trabajador={trabajador}
                setTrabajador={setTrabajador}
                listaTiposTrabajador={listaTiposTrabajador}
              />
            </Box>
          )
        ) : stepCount === 2 ? (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>Paso 3: Suba las cédulas</Typography>
            <Typography>
              Por favor, cargue la documentación listada a continuación:
            </Typography>
            <UploadDocsPage
              type="cedula"
              setContinueValue={setContinueValue}
              uploadedFiles={uploadedCedulas}
              setUploadedFiles={setUploadedCedulas}
              datos={datos}
              setDatos={setDatos}
            />
          </Box>
        ) : stepCount === 3 ? (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>
              Paso 4: Revise la información personal
            </Typography>
            <Typography>
              Antes de terminar, por favor revise que la información personal
              cargada esté correcta:
            </Typography>
            <Box sx={datosStyle}>
              <InfoPage
                view="cedula"
                editar={false}
                setContinueValue={setContinueValue}
                datos={datos}
                setDatos={setDatos}
              />
            </Box>
          </Box>
        ) : stepCount === 4 ? (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>
              Paso 5: Suba los documentos
            </Typography>
            <Typography>
              Por favor, cargue la documentación listada a continuación:
            </Typography>
            <UploadDocsPage
              type="documentos"
              setContinueValue={setContinueValue}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              datos={datos}
              setDatos={setDatos}
              trabajador={trabajador}
            />
          </Box>
        ) : (
          <Box sx={datosBox}>
            <Typography sx={tituloDatos}>
              Paso 6: Revise la información
            </Typography>
            <Typography>
              Antes de terminar, por favor revise que la información cargada
              esté correcta:
            </Typography>
            <Box sx={datosStyle}>
              <InfoPage
                view="contrato"
                editar={false}
                setContinueValue={setContinueValue}
                datos={datosFinales}
                setDatos={setDatosFinales}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={buttonBox}>
        <Button sx={buttonVolver} variant="outlined" onClick={handleBack}>
          Volver
        </Button>
        <Button
          sx={button}
          disableElevation
          variant="contained"
          type="submit"
          onClick={handleContinue}
          disabled={!continueValue}
        >
          Continuar
        </Button>
      </Box>
      <FinishModal
        openModal={openFinishModal}
        setOpenModal={setOpenFinishModal}
        handleContinue={handleContinue}
        handleBack={handleBack}
        buttonText="Crear Trabajadores"
        navigatePath="/Trabajadores"
      >
        <Typography sx={titleFinishModal}>
          ¿Está seguro/a que quiere continuar?
        </Typography>
        <Box sx={textBoxFinishModal}>
          <Typography sx={textStyleFinishModal}>
            Una vez creado los nuevos trabajadores, la siguiente información{" "}
            <b>sólo podrá ser editada creando un ticket de ayuda:</b>
          </Typography>
          <Typography>
            <li>Fecha de inicio de contrato </li>
            <li>Fecha fin de contrato</li>
            <li>Fecha de inicio de faena</li>
          </Typography>
        </Box>
      </FinishModal>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  FormHelperText,
  Button
} from "@mui/material";

/* CUSTOM LIBRARIES */
import CustomSelect from "../common/components/customSelect";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { listaRegiones } from "../../../controller/listas";
import { POLLING_INTERVAL, transition } from "../../../controller/utils";
import { useSelector } from "react-redux";
import { currentEmpresa } from "../../../redux/entidadesSlice";
import { useGetEmpresaQuery } from "../../../api/empresasApiSlice";

export default function DatosEmpresa() {
  const navigate = useNavigate();
  const littleSize = littleSizeFunc();
  const selectedEmpresa = useSelector(currentEmpresa);
  const { setSubtitleValue } = useOutletContext();

  const { currentData, isSuccess, isLoading, isFetching } = useGetEmpresaQuery(
    selectedEmpresa.id_empresa_outsourcing
      ? selectedEmpresa.id_empresa_outsourcing
      : selectedEmpresa.id,
    {
      pollingInterval: POLLING_INTERVAL
    }
  );

  const [openFinishModal, setOpenFinishModal] = useState(false);

  // informacion empresa
  const [valueRut, setValueRut] = useState("");
  const [valueRazon, setValueRazon] = useState("");
  const [valueNombreComercial, setValueNombreComercial] = useState("");
  const [valueRegion, setValueRegion] = useState("");
  const [valueTelefono, setValueTelefono] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");

  const [errorRut, setErrorRut] = useState("");
  const [errorValueRazon, setErrorRazon] = useState("");
  const [errorNombreComercial, setErrorNombreComercial] = useState("");
  const [errorRegion, setErrorRegion] = useState("");
  const [errorTelefono, setErrorTelefono] = useState("");
  const [errorDireccion, setErrorDireccion] = useState("");

  // informacion contacto
  const [valueNombreContacto, setValueNombreContacto] = useState("");
  const [valueCorreoContacto, setValueCorreoContacto] = useState("");
  const [valueTelefonoContacto, setValueTelefonoContacto] = useState("");

  const [errorNombreContacto, setErrorNombreContacto] = useState("");
  const [errorCorreoContacto, setErrorCorreoContacto] = useState("");
  const [errorTelefonoContacto, setErrorTelefonoContacto] = useState("");

  useEffect(() => {
    selectedEmpresa
      ? setSubtitleValue(
          `Subcontratista: ${
            selectedEmpresa.nombre_empresa_outsourcing
              ? selectedEmpresa.nombre_empresa_outsourcing
              : selectedEmpresa.nombre
          }`
        )
      : setSubtitleValue("");
  }, []);

  useEffect(() => {
    if (isSuccess && currentData) {
      let data = currentData.data;
      setValueRut(data.rut);
      setValueRazon(data.razon_social);
      setValueNombreComercial(data.nombre);
      setValueRegion(data.region);
      setValueTelefono(data.telefono);
      setValueDireccion(data.direccion_legal);
      setValueNombreContacto(data.nombre_contacto);
      setValueCorreoContacto(data.correo_contacto);
      setValueTelefonoContacto(data.telefono_contacto);
    }
  }, [currentData, isSuccess, isLoading, isFetching]);

  const handleClickVolver = (event) => {
    navigate("./..");
  };

  const todo = {
    padding: "0 0 0 0",
    minHeight: "58vh",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: "4vh 0"
  };
  const contenido = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "3.47vh 40px",
    justifyContent: littleSize ? "center" : "flex-start",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",
    minHeight: "fit-content",
    display: "flex",
    overflowY: "hidden",
    padding: "11px 0",
    width: "100%"
  };
  const tituloDatos = {
    width: "100%",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "140%",
    color: "#1876D1",
    textAlign: littleSize ? "center" : "left"
  };
  const text = {
    width: "inherit",
    fontWeight: 600
  };
  const textFieldBox = {
    width: "14.58vw",
    minWidth: "210px"
  };
  const textField = {
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: 400
    },
    ".Mui-disabled": {
      opacity: 1
    }
  };
  const textFieldTelLeft = {
    width: "58px",
    height: "inherit",
    borderRadius: "4.34415px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomRightRadius: "0%",
      borderTopRightRadius: "0%",
      "& .MuiOutlinedInput-input": {
        paddingLeft: "0px",
        paddingRight: "0px"
      }
    },
    ".Mui-disabled": {
      opacity: 0.8
    }
  };
  const textFieldTelRight = {
    width: "calc(100% - 58px)",
    height: "inherit",
    borderRadius: "4.34415px",
    background: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderBottomLeftRadius: "0%",
      borderTopLeftRadius: "0%"
    },
    ".Mui-disabled": {
      opacity: 1
    }
  };
  const buttonBox = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: littleSize ? "center" : "flex-end",
    alignItems: "center",
    gap: "10px",
    padding: "0 0 34px 0"
  };
  const buttonVolver = {
    transition: littleSize ? 0 : transition,
    textTransform: "none",
    borderRadius: "10px",
    border: "2px solid #1876D1",
    fontWeight: 600,
    ":hover": {
      border: "2px solid #1876D1"
    }
  };
  const button = {
    fontWeight: 600,
    textTransform: "none",
    borderRadius: "10px",
    background: "#1876D1"
  };
  const titleFinishModal = {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "140%",
    textAlign: "center"
  };

  return (
    <Box sx={todo}>
      <Typography sx={tituloDatos}>Información de empresa</Typography>
      <Box sx={contenido}>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="rutEmpresa"
            label="Rut de empresa"
            type="text"
            value={valueRut}
            disabled
          />
          <FormHelperText error>{errorRut}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="razonSocial"
            label="Razón social"
            type="text"
            value={valueRazon}
            disabled
          />
          <FormHelperText error>{errorValueRazon}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="nombreComercial"
            label="Nombre comercial"
            type="text"
            value={valueNombreComercial}
            disabled
          />
          <FormHelperText error>{errorNombreComercial}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="direccion"
            label="Dirección legal"
            type="text"
            value={valueDireccion}
            onChange={(e) => setValueDireccion(e.target.value)}
            disabled
          />
          <FormHelperText error>{errorDireccion}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <CustomSelect
            label="Región"
            fontWeightValue={400}
            value={valueRegion}
            setValue={setValueRegion}
            errorText={valueRegion ? "" : errorRegion}
            disabled
          >
            {listaRegiones}
          </CustomSelect>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textFieldTelLeft}
            size={"small"}
            fullWidth
            disabled
            label="+56"
          />
          <TextField
            sx={textFieldTelRight}
            size="small"
            fullWidth
            required
            id="telefono"
            label="Telefono"
            type="tel"
            value={valueTelefono.replace("+56", "")}
            onChange={(e) => setValueTelefono(e.target.value)}
            disabled
          />
          <FormHelperText error>{errorTelefono}</FormHelperText>
        </Box>
      </Box>

      <Typography sx={tituloDatos}>Información de contacto</Typography>
      <Box sx={contenido}>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="nombreContacto"
            label="Nombre de contacto"
            type="text"
            value={valueNombreContacto}
            onChange={(e) => setValueNombreContacto(e.target.value)}
            disabled
          />
          <FormHelperText error>{errorNombreContacto}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textField}
            size="small"
            fullWidth
            required
            id="correoContacto"
            label="Correo de contacto"
            type="text"
            value={valueCorreoContacto}
            onChange={(e) => setErrorCorreoContacto(e.target.value)}
            disabled
          />
          <FormHelperText error>{errorCorreoContacto}</FormHelperText>
        </Box>
        <Box sx={textFieldBox}>
          <TextField
            sx={textFieldTelLeft}
            size={"small"}
            fullWidth
            disabled
            label="+56"
          />
          <TextField
            sx={textFieldTelRight}
            size="small"
            fullWidth
            required
            id="telefono contacto"
            label="Telefono de contacto"
            type="tel"
            value={valueTelefonoContacto.replace("+56", "")}
            onChange={(e) => setValueTelefonoContacto(e.target.value)}
            disabled
          />
          <FormHelperText error>{errorTelefonoContacto}</FormHelperText>
        </Box>
      </Box>
      <Box sx={buttonBox}>
        <Button
          sx={buttonVolver}
          variant="outlined"
          onClick={handleClickVolver}
        >
          Volver
        </Button>
      </Box>
    </Box>
  );
}

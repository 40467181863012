import { regiones } from "./regionesChile";

/* ICONS */
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import { romanTable } from "./utils";

const drawerIcon = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  height: "3vh"
};

/* Skeleton */
export const drawerListValues_EmpresaA = [
  {
    title: "Dashboard",
    icon: <LeaderboardOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Trabajadores",
    icon: <SupervisorAccountOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Clientes",
    icon: <BusinessCenterOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Faenas",
    icon: <Diversity3OutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Mi Perfil",
    icon: <PersonOutlinedIcon sx={drawerIcon} />
  }
];

export const drawerListValues_EmpresaB = [
  {
    title: "Dashboard",
    icon: <LeaderboardOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Subcontratistas",
    icon: <BusinessCenterOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Estado subcontratistas",
    icon: <DescriptionOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Mi Perfil",
    icon: <PersonOutlinedIcon sx={drawerIcon} />
  }
];

export const drawerListValues_EmpresaC = [
  {
    title: "Dashboard",
    icon: <LeaderboardOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Tickets de ayuda",
    icon: <SupportAgentOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Verificaciones pendientes",
    icon: <BackHandOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Empresas",
    icon: <DomainOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Faenas",
    icon: <Diversity3OutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Trabajadores",
    icon: <SupervisorAccountOutlinedIcon sx={drawerIcon} />
  },
  {
    title: "Documentos",
    icon: <InsertDriveFileOutlinedIcon sx={drawerIcon} />
  }
];

/* Crear empresa */
export const stepsNuevaEmpresaOutsourcing = [
  "Elegir empresa cliente",
  "Ingresar datos usuario",
  "Ingresar datos empresa",
  "Tipos de trabajador",
  "Subir plantillas"
];
export const stepsNuevaEmpresaCliente = [
  "Ingresar datos usuario",
  "Ingresar datos empresa"
];

/* Editar empresa */
export const stepsEditarEmpresa = [
  "Tipos de trabajador",
  "Subir/Editar plantillas"
];

/* Plantillas empresa */
export const listaFilesPlantillasEmpresa = [
  {
    nombre: "Plantilla Liquidación de Sueldo",
    acceptedFormats: ["application/pdf"]
  },
  {
    nombre: "Plantilla Permiso Sin Goce de Sueldo",
    acceptedFormats: ["application/pdf"]
  }
];

/* Clientes */
export const headCellsClientes = [
  {
    id: "nombre",
    label: "Cliente"
  },
  {
    id: "inicio",
    label: "Inicio contrato"
  },
  {
    id: "fin",
    label: "Fin contrato"
  },
  {
    id: "subcontratados",
    label: "Trabajadores"
  },
  {
    id: "acciones",
    label: "Acción"
  }
];

/* Dashboard */
export const headCellsDashboard_A = [
  {
    id: "nombre",
    label: "Cliente"
  },
  {
    id: "faenas_count",
    label: "Áreas/Faenas"
  },
  {
    id: "docs_sin_subir",
    label: "Sin subir"
  },
  {
    id: "docs_analizando",
    label: "Por revisar"
  },
  {
    id: "documentos_aprobados",
    label: "Aprobados"
  },
  {
    id: "documentos_rechazados",
    label: "Rechazados"
  }
];
export const headCellsDashboard_B = [
  {
    id: "nombre",
    label: "Subcontratista"
  },
  {
    id: "facturas_rechazadas",
    label: "Rechazados"
  },
  {
    id: "facturas_pendientes",
    label: "Sin subir"
  },
  {
    id: "facturas_por_facturar",
    label: "Por facturar"
  },
  {
    id: "facturas_facturadas",
    label: "Facturados"
  }
];

export const headCellsDashboard_Admin = [
  {
    id: "nombre",
    label: "Empresa"
  },
  {
    id: "tipo_empresa",
    label: "Categoría"
  },
  {
    id: "trabajadores_count",
    label: "Cantidad de Trabajadores"
  },
  {
    id: "empresas_mandantes",
    label: "Empresas Mandantes"
  },
  {
    id: "empresas_outsourcing",
    label: "Empresas Outsourcing"
  },
  {
    id: "documento_mas_rechazado",
    label: "Tipo de documento más rechazado"
  },
  {
    id: "tasa_aprobacion",
    label: "Tasa aprobación de documentos"
  }
];

/* Empresa */

export const headCellsListarEmpresas = [
  {
    id: "rut",
    label: "RUT"
  },
  {
    id: "nombre",
    label: "Nombre"
  },
  {
    id: "tipo_empresa",
    label: "Tipo empresa"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

export const estadosEmpresas = [
  { id: "Validado", value: "Validado" },
  { id: "Procesando", value: "Procesando" },
  { id: "Pendiente", value: "Pendiente" },
  { id: "Rechazado", value: "Rechazado" }
];
export const stepsCargaMasivaEmpresa = ["Subir documentos", "Revisión final"];
export const headCellsEmpresas = [
  {
    id: "rut",
    label: "RUT"
  },
  {
    id: "nombre",
    label: "Nombre"
  },
  {
    id: "titulo",
    label: "Documento"
  },
  {
    id: "fecha_emitido",
    label: "Fecha Emitido"
  },
  {
    id: "mes",
    label: "Mes"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];
export const headCellsEmpresa = [
  {
    id: "titulo",
    label: "Documento"
  },
  {
    id: "fecha_emitido",
    label: "Fecha Emitido"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

/* Faenas */

export const headCellsListarFaenas = [
  {
    id: "nombre",
    label: "Nombre"
  },
  {
    id: "nombre_empresa_outsourcing",
    label: "Empresa Outsourcing"
  },
  {
    id: "nombre_empresa_mandante",
    label: "Empresa Mandante"
  },
  {
    id: "fecha_creacion",
    label: "Fecha de creación"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

export const headCellsListarFaenasOutsourcing = [
  {
    id: "nombre",
    label: "Nombre"
  },
  {
    id: "nombre_empresa_mandante",
    label: "Cliente"
  },
  {
    id: "fecha_creacion",
    label: "Fecha de creación"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

export const estadosFaenas = [
  { id: "Validado", value: "Validado" },
  { id: "Procesando", value: "Procesando" },
  { id: "Pendiente", value: "Pendiente" },
  { id: "Rechazado", value: "Rechazado" }
];

/* Verificaciones */
export const estadosVerificaciones = [
  { id: "Abierto", value: "Abierto" },
  { id: "Resuelto", value: "Resuelto" },
  { id: "Ticket de ayuda", value: "Ticket de ayuda" }
];

export const headCellsVerificaciones = [
  {
    id: "tipo_documento",
    label: "Documento a verificar"
  },
  {
    id: "nombre_empresa",
    label: "Empresa"
  },
  {
    id: "nombre_trabajador",
    label: "Trabajador"
  },
  {
    id: "fecha_creacion",
    label: "Fecha solicitud"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

/* Tickets */
export const headCellsTickets = [
  {
    id: "id",
    label: "N° de ticket"
  },
  {
    id: "fecha_creacion",
    label: "Fecha creación"
  },
  {
    id: "motivo",
    label: "Motivo del ticket"
  },
  {
    id: "tipo_contacto",
    label: "Tipo de contacto"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Ver"
  }
];
export const listaFilesTicket = [
  {
    nombre: "Documento de apoyo",
    acceptedFormats: ["application/pdf", "image/png", "image/jpg", "image/jpeg"]
  }
];

/* Subcontratistas */
export const headCellsDocumentosSubcontratista = [
  {
    id: "titulo",
    label: "Documento"
  },
  {
    id: "fecha_emitido",
    label: "Fecha"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

export const headCellsSubcontratistas = [
  {
    id: "nombre",
    label: "Subcontratista"
  },
  {
    id: "tipos_trabajadores",
    label: "Tipos Trabajadores"
  },
  {
    id: "subcontratados",
    label: "Trabajadores activos"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

/* Estado Subcontratista */

export const headCellsEstadoSubcontratista = [
  {
    id: "nombre_faena",
    label: "Área/Faena"
  },
  {
    id: "nombre_empresa_outsourcing",
    label: "Subcontratista"
  },
  {
    id: "año-mes",
    label: "Mes servicio"
  },
  {
    id: "sin_subir",
    label: "Sin subir"
  },
  {
    id: "aprobados",
    label: "Aprobados"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

export const headCellsProblemasSubontratista = [
  {
    id: "problema",
    label: "Problema"
  },
  {
    id: "nombre",
    label: "Nombre"
  },
  {
    id: "rut",
    label: "RUT"
  },
  {
    id: "nombre_tipo_documento",
    label: "Documento"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

/* Nuevo Subcontratista */
export const stepsNuevoSubcontratista = [
  "Ingresar datos usuario",
  "Ingresar datos empresa"
];

/* Trabajadores */
export const estadosTrabajadores = [
  { id: "Validado", value: "Validado" },
  { id: "Procesando", value: "Procesando" },
  { id: "Pendiente", value: "Pendiente" },
  { id: "Rechazado", value: "Rechazado" }
];
export const headCellsTrabajadores = [
  {
    id: "rut",
    label: "Rut"
  },
  {
    id: "nombre",
    label: "Nombre"
  },
  {
    id: "fecha_inicio_contrato",
    label: "Inicio contrato"
  },
  {
    id: "fecha_fin_contrato",
    label: "Fin contrato"
  },
  {
    id: "fecha_ingreso_faena",
    label: "Ingreso faena"
  },
  {
    id: "fecha_salida_faena",
    label: "Salida faena"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

/* Carga Masiva Trabajadores */
export const stepsCargaMasivaTrabajadores = [
  "Seleccionar el cliente",
  "Subir documentos",
  "Revisión final"
];
export const headCellsCargaMasivaTrabajadores = [
  {
    id: "cliente",
    label: "Cliente"
  },
  {
    id: "rut",
    label: "RUT"
  },
  {
    id: "nombre",
    label: "Nombre"
  },
  {
    id: "documento",
    label: "Documento"
  },
  {
    id: "mes",
    label: "Mes"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

/* Vencimientos */
export const headCellsVencimientos = [
  {
    id: "nombre_empresa_mandante",
    label: "Cliente"
  },
  {
    id: "faena",
    label: "Faena"
  },
  {
    id: "nombre",
    label: "Trabajador"
  },
  {
    id: "rut",
    label: "RUT"
  },
  {
    id: "nombre_tipo_documento",
    label: "Documento"
  },

  {
    id: "fecha_fin",
    label: "Vencimiento"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acción"
  }
];

export const headCellsTipoDocumentos = [
  {
    id: "nombre",
    label: "Documento"
  },
  {
    id: "estado",
    label: "Estado"
  }
];

/* Listar Documentos Trabajador */
export const headCellsDocumentosTrabajador = [
  {
    id: "titulo",
    label: "Documento"
  },
  {
    id: "fecha_emitido",
    label: "Fecha"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];
export const headCellsDocumentos = [
  {
    id: "rut",
    label: "RUT"
  },
  {
    id: "nombre",
    label: "Trabajador"
  },
  {
    id: "nombre_empresa_outsourcing",
    label: "Empresa"
  },
  {
    id: "titulo",
    label: "Documento"
  },
  {
    id: "mes_prestacion",
    label: "Mes Prestación"
  },
  {
    id: "fecha_fin",
    label: "Fecha Vencimiento"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

export const headCellsDocumentosOutsourcing = [
  {
    id: "rut_empresa_outsourcing",
    label: "RUT"
  },
  {
    id: "nombre_empresa_mandante",
    label: "Cliente"
  },
  {
    id: "titulo",
    label: "Documento"
  },
  {
    id: "mes_prestacion",
    label: "Mes Prestación"
  },
  {
    id: "fecha_fin",
    label: "Fecha Vencimiento"
  },
  {
    id: "estado",
    label: "Estado"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

/* Documentos bajados */

export const headCellsDocumentosBajados = [
  {
    id: "titulo",
    label: "Documento"
  },
  {
    id: "fecha_emitido",
    label: "Fecha emitido"
  },
  {
    id: "fecha_fin",
    label: "Fecha fin"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

/* Carga Masiva Vencimientos */
export const stepsCargaMasivaVencimientos = [
  "Subir documentos",
  "Revisión final"
];

/* Dar de baja Modal */
export const stepsDarDeBajaModal = [
  "Subir documentos",
  "Revisión de solicitud"
];
export const listaFilesDarDeBaja = [
  {
    nombre: "Carta de aviso o renuncia",
    tipo_documento: "Carta de aviso o renuncia",
    acceptedFormats: ["application/pdf"],
    hide: false
  },
  {
    nombre: "Colilla Carta Certificada",
    tipo_documento: "Colilla Carta Certificada",
    acceptedFormats: ["application/pdf"],
    hide: true
  },
  {
    nombre: "Finiquito del contrato laboral",
    tipo_documento: "Finiquito del contrato laboral",
    acceptedFormats: ["application/pdf"]
  }
];
export const headCellsDadosDeBaja = [
  {
    id: "rut",
    label: "Rut"
  },
  {
    id: "nombre",
    label: "Nombre"
  },
  {
    id: "fecha_inicio_contrato",
    label: "Inicio contrato"
  },
  {
    id: "fecha_fin_contrato",
    label: "Fin contrato"
  },
  {
    id: "fecha_ingreso_faena",
    label: "Ingreso faena"
  },
  {
    id: "fecha_salida_faena",
    label: "Salida faena"
  },
  {
    id: "acciones",
    label: "Acciones"
  }
];

/* Extender contrato Modal */
export const listaFilesExtenderContrato = [
  {
    nombre: "Anexo de contrato",
    acceptedFormats: ["application/pdf"]
  }
];

/* Plantilla liquidacion */
export const listaPlantillaLiquidacion = [
  {
    nombre: "Plantilla Liquidación",
    acceptedFormats: ["application/pdf"]
  }
];

/* Nuevo Trabajador */
export const stepsNuevoTrabajador = [
  "Seleccionar cliente",
  "Tipo de trabajador",
  "Subir cédulas",
  "Revisión de información personal",
  "Subir documentos",
  "Revisión de información documentos"
];

export const cedulaFile = [
  {
    title: "Cédula/s de identidad",
    acceptedFormats: ["application/pdf", "image/png", "image/jpg", "image/jpeg"]
  }
];
export const oneFileNuevoTrabajador = [
  {
    title: "Contrato laboral y todos sus anexos",
    acceptedFormats: ["application/pdf"]
  }
];
export const listaFilesNuevoTrabajador = [
  { title: "Contrato laboral", acceptedFormats: ["application/pdf"] },
  {
    title: "Obligación de informar (ODI)",
    acceptedFormats: ["application/pdf"]
  },
  { title: "Declaración simple EPP", acceptedFormats: ["application/pdf"] },
  { title: "Reglamento interno (RIOCH)", acceptedFormats: ["application/pdf"] },
  { title: "Anexo teletrabajo", acceptedFormats: ["application/pdf"] }
];

/* Support Center */
export const stepsSupportCenter = [
  "Información de contacto",
  "Detalles",
  "Archivos adicionales"
];

export const listaMotivos = [
  { id: "Manejo de usuarios", value: "Manejo de usuarios" },
  { id: "Manejo de fechas", value: "Manejo de fechas" },
  { id: "Manejo de clientes", value: "Manejo de clientes" },
  { id: "Manejo de documentos", value: "Manejo de documentos" },
  { id: "Utilización del portal", value: "Utilización del portal" },
  { id: "Casos especiales", value: "Casos especiales" },
  { id: "Documento rechazado", value: "Documento rechazado" }
];

export const listaTiposContacto = [
  {
    id: "Llamada",
    value: "Llamada"
  },
  { id: "Mensaje", value: "Mensaje" }
];

/* Informacion Trabajador */
export const listaRegiones = regiones
  .map((region) => {
    return {
      id: region.region_number,
      value: `${region.region}`
    };
  })
  .sort((a, b) =>
    romanTable[a.id] > romanTable[b.id]
      ? 1
      : romanTable[b.id] > romanTable[a.id]
      ? -1
      : 0
  );

export const listaComunas = [
  { region: "", comunas: [{ id: "", value: "" }] }
].concat(
  regiones.map((region) => {
    var comunas = [];
    region.provincias.map((provincia) => {
      comunas = comunas.concat(
        provincia.comunas.map((comuna) => {
          return {
            id: comuna.name,
            value: comuna.name
          };
        })
      );
    });
    return {
      region: region.region_number,
      comunas: comunas.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      )
    };
  })
);

export const listaTipoContrato = [
  { id: "Plazo fijo", value: "Plazo fijo" },
  { id: "Indefinido", value: "Indefinido" },
  { id: "Honorarios", value: "Honorarios" }
];

export const listaJornada = [
  { id: "Completa", value: "Completa" },
  { id: "Media", value: "Media" },
  { id: "Parcial", value: "Parcial" }
];

export const listaSexo = [
  { id: "F", value: "Femenino" },
  { id: "M", value: "Masculino" },
  { id: "O", value: "Otro" }
];

export const listaNacionalidad = [
  { id: "AFG", value: "Afgana" },
  { id: "ALB", value: "Albanesa" },
  { id: "DEU", value: "Alemana" },
  { id: "AND", value: "Andorrana" },
  { id: "AGO", value: "Angolesa" },
  { id: "SAU", value: "Arabe" },
  { id: "DZA", value: "Argeliana" },
  { id: "ARG", value: "Argentina" },
  { id: "AUS", value: "Australiana" },
  { id: "AUT", value: "Austriaca" },
  { id: "BHS", value: "Bahameña" },
  { id: "BEL", value: "Belga" },
  { id: "BLZ", value: "Beliceña" },
  { id: "BTN", value: "Bhutanesa" },
  { id: "BUR", value: "Birmana" },
  { id: "BOL", value: "Boliviana" },
  { id: "BWA", value: "Botswanesa" },
  { id: "BRA", value: "Brasileña" },
  { id: "VGB", value: "Britanica" },
  { id: "IOT", value: "Britanica" },
  { id: "BGR", value: "Bulgara" },
  { id: "BFA", value: "Burkina faso" },
  { id: "BDI", value: "Burundesa" },
  { id: "KHM", value: "Camboyana" },
  { id: "CMR", value: "Camerunesa" },
  { id: "CAN", value: "Canadiense" },
  { id: "CAF", value: "Centro africana" },
  { id: "TCD", value: "Chadiana" },
  { id: "CSK", value: "Checoslovaca" },
  { id: "CHL", value: "Chilena" },
  { id: "CHN", value: "China" },
  { id: "CYP", value: "Chipriota" },
  { id: "COL", value: "Colombiana" },
  { id: "COM", value: "Comorense" },
  { id: "COD", value: "Congolesa" },
  { id: "CRI", value: "Costarricense" },
  { id: "CUB", value: "Cubana" },
  { id: "DNK", value: "Danesa" },
  { id: "BHR", value: "De bahrein" },
  { id: "BRB", value: "De barbados" },
  { id: "BEN", value: "De bennin" },
  { id: "CPV", value: "De cabo verde" },
  { id: "DJI", value: "De djibouti" },
  { id: "STP", value: "De santo tome" },
  { id: "SYC", value: "De seychelles" },
  { id: "ZWE", value: "De zimbawi" },
  { id: "QAT", value: "Del qatar" },
  { id: "ESH", value: "Del sahara" },
  { id: "DMA", value: "Dominica" },
  { id: "DOM", value: "Dominicana" },
  { id: "EGY", value: "Egipcia" },
  { id: "SVK", value: "Eslovaca" },
  { id: "XES", value: "Eslovaquia" },
  { id: "SVN", value: "Eslovenia" },
  { id: "ESP", value: "Española" },
  { id: "USA", value: "Estadounidense" },
  { id: "EST", value: "Estonia" },
  { id: "ETH", value: "Etiope" },
  { id: "FJI", value: "Fijiana" },
  { id: "PHL", value: "Filipina" },
  { id: "FIN", value: "Finlandesa" },
  { id: "FRA", value: "Francesa" },
  { id: "GAB", value: "Gabonesa" },
  { id: "GMB", value: "Gambiana" },
  { id: "GHA", value: "Ghanata" },
  { id: "VCT", value: "Granadina" },
  { id: "GRC", value: "Griega" },
  { id: "GTM", value: "Guatemalteca" },
  { id: "GNB", value: "Guinea" },
  { id: "GIN", value: "Guinea" },
  { id: "GNQ", value: "Guinea ecuatoriana" },
  { id: "GUY", value: "Guyanesa" },
  { id: "HTI", value: "Haitiana" },
  { id: "IND", value: "Hindu" },
  { id: "NLD", value: "Holandesa" },
  { id: "HND", value: "Hondureña" },
  { id: "HUN", value: "Hungara" },
  { id: "IDN", value: "Indonesa" },
  { id: "IRN", value: "Irani" },
  { id: "IRQ", value: "Iraqui" },
  { id: "IRL", value: "Irlandesa" },
  { id: "ISL", value: "Islandesa" },
  { id: "ISR", value: "Israeli" },
  { id: "ITA", value: "Italiana" },
  { id: "JAM", value: "Jamaiquina" },
  { id: "JPN", value: "Japonesa" },
  { id: "JOR", value: "Jordana" },
  { id: "KEN", value: "Keniana" },
  { id: "KGZ", value: "Kirguistan" },
  { id: "KWT", value: "Kuwaiti" },
  { id: "LAO", value: "Laosiana" },
  { id: "LSO", value: "Lesothense" },
  { id: "LBN", value: "Libanesa" },
  { id: "LBR", value: "Liberiana" },
  { id: "LBY", value: "Libia" },
  { id: "LIE", value: "Liechtenstense" },
  { id: "LTU", value: "Lituania " },
  { id: "LUX", value: "Luxemburguesa" },
  { id: "MKD", value: "Macedonia" },
  { id: "MYS", value: "Malasia" },
  { id: "MWI", value: "Malawiana" },
  { id: "MDV", value: "Maldiva" },
  { id: "MLI", value: "Maliense" },
  { id: "MLT", value: "Maltesa" },
  { id: "COG", value: "Marfileña" },
  { id: "MAR", value: "Marroqui" },
  { id: "MUS", value: "Mauriciana" },
  { id: "MRT", value: "Mauritana" },
  { id: "MEX", value: "Mexicana" },
  { id: "FSM", value: "Micronecia" },
  { id: "MDA", value: "República de moldovia" },
  { id: "MCO", value: "Monegasca" },
  { id: "MNG", value: "Mongolesa" },
  { id: "MOZ", value: "Mozambiqueña" },
  { id: "NAM", value: "Namibiana" },
  { id: "NRU", value: "Nauruana" },
  { id: "NPL", value: "Nepalesa" },
  { id: "NIC", value: "Nicaraguense" },
  { id: "NGA", value: "Nigeriana" },
  { id: "NER", value: "Nigerina" },
  { id: "PRK", value: "Norcoreana" },
  { id: "NOR", value: "Noruega" },
  { id: "VNM", value: "Norvietnamita" },
  { id: "OMN", value: "Omanesa" },
  { id: "PAK", value: "Pakistani" },
  { id: "PAN", value: "Panameña" },
  { id: "PNG", value: "Papuense" },
  { id: "PRY", value: "Paraguaya" },
  { id: "PER", value: "Peruana" },
  { id: "PRT", value: "Portuguesa" },
  { id: "PRI", value: "Puertorriqueña" },
  { id: "GBR", value: "Reino unido" },
  { id: "GBN", value: "Reino unido(brit. del ext.)" },
  { id: "GBO", value: "Reino unido(c. brit. del ext.)" },
  { id: "GBD", value: "Reino unido(depen. tet. brit.)" },
  { id: "ROM", value: "Rumana" },
  { id: "RWA", value: "Rwandesa" },
  { id: "SLB", value: "Salomonesa" },
  { id: "SLV", value: "Salvadoreña" },
  { id: "WSM", value: "Samoana" },
  { id: "SMR", value: "San marinense" },
  { id: "LCA", value: "Santa luciense" },
  { id: "SEN", value: "Senegalesa" },
  { id: "SLE", value: "Sierra leonesa" },
  { id: "SGP", value: "Singaporense" },
  { id: "SYR", value: "Siria" },
  { id: "SOM", value: "Somali" },
  { id: "BLR", value: "Sovietica bielorrusa" },
  { id: "UKR", value: "Sovietica ucraniana" },
  { id: "ZAF", value: "Sudafricana" },
  { id: "SDN", value: "Sudanesa" },
  { id: "KOR", value: "Sudcoreana" },
  { id: "SWE", value: "Sueca" },
  { id: "CHE", value: "Suiza" },
  { id: "SUR", value: "Surinamense" },
  { id: "SWZ", value: "Swazi" },
  { id: "THA", value: "Tailandesa" },
  { id: "TWN", value: "Taiwanesa" },
  { id: "TZA", value: "Tanzaniana" },
  { id: "TGO", value: "Togolesa" },
  { id: "TTO", value: "Trinitaria" },
  { id: "TUN", value: "Tunecina" },
  { id: "TUR", value: "Turca" },
  { id: "UGA", value: "Ugandesa" },
  { id: "URY", value: "Uruguaya" },
  { id: "VAT", value: "Vaticana" },
  { id: "VEN", value: "Venezolana" },
  { id: "YEM", value: "Yemeni" },
  { id: "YUG", value: "Yugoslava" },
  { id: "ZAR", value: "Zairana" },
  { id: "ZMB", value: "Zambiana" }
];

export const listaTipoDocumento = [
  { id: "DNI", value: "DNI" },
  { id: "CPF", value: "CPF" },
  { id: "RUC", value: "RUC" }
];

export const listaDesvinculacion = [
  { id: "Finiquito", value: "Finiquito (contrato fijo)" },
  { id: "Renuncia", value: "Renuncia" },
  { id: "Despido", value: "Despido" }
];

export const listaMotivoDesvinculacion = [
  {
    id: "159-1",
    value: "Articulo 159 N°1 Mutuo acuerdo de las partes"
  },
  { id: "159-2", value: "Articulo 159 N°2 Renuncia del trabajador" },
  { id: "159-3", value: "Articulo 159 N°3 Muerte del trabajador" },
  {
    id: "159-4",
    value: "Articulo 159 N°4 Vencimiento del plazo convenido en el contrato"
  },
  {
    id: "159-5",
    value:
      "Articulo 159 N°5 Conclusión del trabajo o servicio que dio origen al contrato"
  },
  {
    id: "159-6",
    value: "Articulo 159 N°6 Caso fortuito o fuerza mayor"
  },
  { id: "160-1", value: "Articulo 160 N°1 Conductas indebidas" },
  {
    id: "160-2",
    value:
      "Articulo 160 N°2 Negociaciones del trabajador prohibidas en el contrato"
  },
  {
    id: "160-3",
    value:
      "Articulo 160 N°3 No concurrencia del trabajador a sus labores sin causa justificada"
  },
  {
    id: "160-4",
    value: "Articulo 160 N°4 Abandono del trabajo por parte del trabajador"
  },
  {
    id: "160-5",
    value:
      "Articulo 160 N°5 Imprudencias que afecten la seguridad o funcionamiento del establecimiento"
  },
  {
    id: "160-6",
    value: "Articulo 160 N°6 Perjuicio material causado intencionalmente"
  },
  {
    id: "160-7",
    value:
      "Articulo 160 N°7 Incumplimiento grave de las obligaciones que impone el contrato"
  }
];

export const listaTrabajos = [
  { id: "A cargo de la producción", value: "A cargo de la producción" },
  { id: "A cargo de las operaciones", value: "A cargo de las operaciones" },
  {
    id: "A cargo de los servicios generales",
    value: "A cargo de los servicios generales"
  },
  { id: "A cargo del transporte", value: "A cargo del transporte" },
  { id: "Abogado administrativo", value: "Abogado administrativo" },
  { id: "Abogado ambiental", value: "Abogado ambiental" },
  { id: "Abogado civil", value: "Abogado civil" },
  { id: "Abogado corporativo", value: "Abogado corporativo" },
  { id: "Abogado de bienes raíces", value: "Abogado de bienes raíces" },
  { id: "Abogado de contrato", value: "Abogado de contrato" },
  { id: "Abogado de negocios", value: "Abogado de negocios" },
  {
    id: "Abogado de propiedad intelectual",
    value: "Abogado de propiedad intelectual"
  },
  { id: "Abogado de seguridad", value: "Abogado de seguridad" },
  { id: "Abogado del consumidor", value: "Abogado del consumidor" },
  { id: "Abogado electoral", value: "Abogado electoral" },
  { id: "Abogado fiscal", value: "Abogado fiscal" },
  { id: "Abogado laboral", value: "Abogado laboral" },
  { id: "Abogado penalista", value: "Abogado penalista" },
  { id: "Abogado", value: "Abogado" },
  { id: "Acrílico", value: "Acrílico" },
  { id: "Actor", value: "Actor" },
  { id: "Actuario", value: "Actuario" },
  { id: "Acuarista", value: "Acuarista" },
  { id: "Acupunturista", value: "Acupunturista" },
  { id: "Adhesivo", value: "Adhesivo" },
  { id: "Administración de obras", value: "Administración de obras" },
  { id: "Administrador de beneficios", value: "Administrador de beneficios" },
  {
    id: "Administrador de comercio electrónico",
    value: "Administrador de comercio electrónico"
  },
  { id: "Administrador de condominio", value: "Administrador de condominio" },
  { id: "Administrador de contratos", value: "Administrador de contratos" },
  { id: "Administrador de costos", value: "Administrador de costos" },
  { id: "Administrador de crm", value: "Administrador de crm" },
  { id: "Administrador de cuentas", value: "Administrador de cuentas" },
  { id: "Administrador de datos", value: "Administrador de datos" },
  { id: "Administrador de empresas", value: "Administrador de empresas" },
  { id: "Administrador de facturas", value: "Administrador de facturas" },
  { id: "Administrador de hospital", value: "Administrador de hospital" },
  {
    id: "Administrador de infraestructura",
    value: "Administrador de infraestructura"
  },
  {
    id: "Administrador de la propiedad",
    value: "Administrador de la propiedad"
  },
  { id: "Administrador de patrimonio", value: "Administrador de patrimonio" },
  { id: "Administrador de precios", value: "Administrador de precios" },
  { id: "Administrador de página web", value: "Administrador de página web" },
  {
    id: "Administrador de redes sociales",
    value: "Administrador de redes sociales"
  },
  { id: "Administrador de sistema", value: "Administrador de sistema" },
  { id: "Administrador del contrato", value: "Administrador del contrato" },
  { id: "Administrador del puerto", value: "Administrador del puerto" },
  { id: "Administrador financiero", value: "Administrador financiero" },
  { id: "Administrador", value: "Administrador" },
  { id: "Adoquín", value: "Adoquín" },
  { id: "Afilador de herramientas", value: "Afilador de herramientas" },
  { id: "Agente de bienes raíces", value: "Agente de bienes raíces" },
  {
    id: "Agente de comercio electrónico",
    value: "Agente de comercio electrónico"
  },
  { id: "Agente de seguridad", value: "Agente de seguridad" },
  { id: "Agente de servicio", value: "Agente de servicio" },
  { id: "Agente de viajes", value: "Agente de viajes" },
  { id: "Agente del aeropuerto", value: "Agente del aeropuerto" },
  { id: "Agente funerario", value: "Agente funerario" },
  { id: "Agente transitario", value: "Agente transitario" },
  { id: "Aglutinante", value: "Aglutinante" },
  { id: "Agrimensor de la propiedad", value: "Agrimensor de la propiedad" },
  { id: "Ajustador de reclamos", value: "Ajustador de reclamos" },
  { id: "Ajustador mecánico", value: "Ajustador mecánico" },
  { id: "Alineador", value: "Alineador" },
  { id: "Almacén auxiliar", value: "Almacén auxiliar" },
  { id: "Almacén de perecederos", value: "Almacén de perecederos" },
  { id: "Almoxarife", value: "Almoxarife" },
  { id: "Ama de casa", value: "Ama de casa" },
  { id: "Amoladora de metales", value: "Amoladora de metales" },
  { id: "Analista ambiental", value: "Analista ambiental" },
  { id: "Analista comercial", value: "Analista comercial" },
  { id: "Analista de abastecimiento", value: "Analista de abastecimiento" },
  { id: "Analista de acreditación", value: "Analista de acreditación" },
  { id: "Analista de activos fijos", value: "Analista de activos fijos" },
  { id: "Analista de activos", value: "Analista de activos" },
  { id: "Analista de almacén", value: "Analista de almacén" },
  { id: "Analista de analítica web", value: "Analista de analítica web" },
  {
    id: "Analista de análisis de resultados",
    value: "Analista de análisis de resultados"
  },
  { id: "Analista de arquitectura", value: "Analista de arquitectura" },
  {
    id: "Analista de asuntos regulatorios",
    value: "Analista de asuntos regulatorios"
  },
  { id: "Analista de auditoría", value: "Analista de auditoría" },
  { id: "Analista de automatización", value: "Analista de automatización" },
  { id: "Analista de back-office", value: "Analista de back-office" },
  { id: "Analista de base de datos", value: "Analista de base de datos" },
  { id: "Analista de beneficios", value: "Analista de beneficios" },
  { id: "Analista de calidad", value: "Analista de calidad" },
  { id: "Analista de campo", value: "Analista de campo" },
  {
    id: "Analista de comercialización visual",
    value: "Analista de comercialización visual"
  },
  { id: "Analista de comercialización", value: "Analista de comercialización" },
  {
    id: "Analista de comercio electrónico",
    value: "Analista de comercio electrónico"
  },
  {
    id: "Analista de comercio exterior",
    value: "Analista de comercio exterior"
  },
  { id: "Analista de compensación", value: "Analista de compensación" },
  { id: "Analista de compras", value: "Analista de compras" },
  {
    id: "Analista de comunicación interna",
    value: "Analista de comunicación interna"
  },
  {
    id: "Analista de comunicación y marketing",
    value: "Analista de comunicación y marketing"
  },
  { id: "Analista de comunicación", value: "Analista de comunicación" },
  { id: "Analista de contabilidad", value: "Analista de contabilidad" },
  { id: "Analista de contenido", value: "Analista de contenido" },
  { id: "Analista de contratos", value: "Analista de contratos" },
  {
    id: "Analista de control de calidad",
    value: "Analista de control de calidad"
  },
  {
    id: "Analista de controles internos",
    value: "Analista de controles internos"
  },
  { id: "Analista de costos", value: "Analista de costos" },
  { id: "Analista de creación", value: "Analista de creación" },
  { id: "Analista de crm", value: "Analista de crm" },
  {
    id: "Analista de crédito inmobiliario",
    value: "Analista de crédito inmobiliario"
  },
  { id: "Analista de crédito", value: "Analista de crédito" },
  {
    id: "Analista de créditos y cobranzas",
    value: "Analista de créditos y cobranzas"
  },
  {
    id: "Analista de cuentas por cobrar",
    value: "Analista de cuentas por cobrar"
  },
  {
    id: "Analista de cuentas por pagar y por cobrar",
    value: "Analista de cuentas por pagar y por cobrar"
  },
  {
    id: "Analista de cuentas por pagar",
    value: "Analista de cuentas por pagar"
  },
  { id: "Analista de cuentas", value: "Analista de cuentas" },
  { id: "Analista de cumplimiento", value: "Analista de cumplimiento" },
  { id: "Analista de dbm", value: "Analista de dbm" },
  {
    id: "Analista de desarrolladores móviles",
    value: "Analista de desarrolladores móviles"
  },
  {
    id: "Analista de desarrollo analítico",
    value: "Analista de desarrollo analítico"
  },
  {
    id: "Analista de despacho de aduanas",
    value: "Analista de despacho de aduanas"
  },
  { id: "Analista de despacho", value: "Analista de despacho" },
  { id: "Analista de despliegue", value: "Analista de despliegue" },
  { id: "Analista de diseño web", value: "Analista de diseño web" },
  { id: "Analista de divisas", value: "Analista de divisas" },
  { id: "Analista de documentación", value: "Analista de documentación" },
  { id: "Analista de endomarketing", value: "Analista de endomarketing" },
  {
    id: "Analista de estrategia de mercado",
    value: "Analista de estrategia de mercado"
  },
  { id: "Analista de eventos", value: "Analista de eventos" },
  { id: "Analista de externalización", value: "Analista de externalización" },
  { id: "Analista de facturación", value: "Analista de facturación" },
  { id: "Analista de facturas médicas", value: "Analista de facturas médicas" },
  {
    id: "Analista de farmacovigilancia",
    value: "Analista de farmacovigilancia"
  },
  { id: "Analista de finanzas", value: "Analista de finanzas" },
  { id: "Analista de flota", value: "Analista de flota" },
  {
    id: "Analista de formación y desarrollo",
    value: "Analista de formación y desarrollo"
  },
  { id: "Analista de franquicias", value: "Analista de franquicias" },
  {
    id: "Analista de fusiones y adquisiciones",
    value: "Analista de fusiones y adquisiciones"
  },
  { id: "Analista de garantía", value: "Analista de garantía" },
  { id: "Analista de geoprocesamiento", value: "Analista de geoprocesamiento" },
  {
    id: "Analista de gestión de incidentes",
    value: "Analista de gestión de incidentes"
  },
  { id: "Analista de gestión", value: "Analista de gestión" },
  { id: "Analista de gobernanza de tI", value: "Analista de gobernanza de tI" },
  { id: "Analista de incorporación", value: "Analista de incorporación" },
  { id: "Analista de indicadores", value: "Analista de indicadores" },
  {
    id: "Analista de información de gestión",
    value: "Analista de información de gestión"
  },
  { id: "Analista de información", value: "Analista de información" },
  { id: "Analista de infraestructura", value: "Analista de infraestructura" },
  {
    id: "Analista de ingeniería de producto",
    value: "Analista de ingeniería de producto"
  },
  { id: "Analista de ingeniería", value: "Analista de ingeniería" },
  { id: "Analista de instalaciones", value: "Analista de instalaciones" },
  {
    id: "Analista de inteligencia de mercado",
    value: "Analista de inteligencia de mercado"
  },
  {
    id: "Analista de inteligencia de negocios",
    value: "Analista de inteligencia de negocios"
  },
  { id: "Analista de inventario", value: "Analista de inventario" },
  { id: "Analista de inversión", value: "Analista de inversión" },
  {
    id: "Analista de investigación de mercado",
    value: "Analista de investigación de mercado"
  },
  { id: "Analista de la mesa de ayuda", value: "Analista de la mesa de ayuda" },
  { id: "Analista de laboratorio", value: "Analista de laboratorio" },
  { id: "Analista de legalización", value: "Analista de legalización" },
  { id: "Analista de logística", value: "Analista de logística" },
  { id: "Analista de mantenimiento", value: "Analista de mantenimiento" },
  {
    id: "Analista de marcas y patentes",
    value: "Analista de marcas y patentes"
  },
  {
    id: "Analista de marketing comercial",
    value: "Analista de marketing comercial"
  },
  {
    id: "Analista de marketing digital",
    value: "Analista de marketing digital"
  },
  {
    id: "Analista de marketing en línea",
    value: "Analista de marketing en línea"
  },
  { id: "Analista de marketing", value: "Analista de marketing" },
  { id: "Analista de medios en línea", value: "Analista de medios en línea" },
  { id: "Analista de medios", value: "Analista de medios" },
  { id: "Analista de mejora continua", value: "Analista de mejora continua" },
  { id: "Analista de mercado", value: "Analista de mercado" },
  { id: "Analista de mesa de control", value: "Analista de mesa de control" },
  { id: "Analista de mesa de servicio", value: "Analista de mesa de servicio" },
  { id: "Analista de métricas", value: "Analista de métricas" },
  { id: "Analista de negocios de tI", value: "Analista de negocios de tI" },
  { id: "Analista de negocios", value: "Analista de negocios" },
  { id: "Analista de nómina", value: "Analista de nómina" },
  {
    id: "Analista de obras administrativas",
    value: "Analista de obras administrativas"
  },
  { id: "Analista de operaciones", value: "Analista de operaciones" },
  { id: "Analista de planeamento", value: "Analista de planeamento" },
  {
    id: "Analista de planificación estratégica",
    value: "Analista de planificación estratégica"
  },
  {
    id: "Analista de planificación financiera",
    value: "Analista de planificación financiera"
  },
  {
    id: "Analista de planificación y presupuesto",
    value: "Analista de planificación y presupuesto"
  },
  { id: "Analista de postventa", value: "Analista de postventa" },
  { id: "Analista de precios", value: "Analista de precios" },
  {
    id: "Analista de prevención de pérdidas",
    value: "Analista de prevención de pérdidas"
  },
  {
    id: "Analista de procesos de gestión",
    value: "Analista de procesos de gestión"
  },
  {
    id: "Analista de procesos industriales",
    value: "Analista de procesos industriales"
  },
  {
    id: "Analista de procesos y calidad",
    value: "Analista de procesos y calidad"
  },
  { id: "Analista de procesos", value: "Analista de procesos" },
  { id: "Analista de producción", value: "Analista de producción" },
  { id: "Analista de productos", value: "Analista de productos" },
  { id: "Analista de proyectos", value: "Analista de proyectos" },
  { id: "Analista de pruebas", value: "Analista de pruebas" },
  {
    id: "Analista de publicidad y propaganda",
    value: "Analista de publicidad y propaganda"
  },
  {
    id: "Analista de puestos y salarios",
    value: "Analista de puestos y salarios"
  },
  { id: "Analista de pérdidas", value: "Analista de pérdidas" },
  {
    id: "Analista de recaudación de fondos",
    value: "Analista de recaudación de fondos"
  },
  {
    id: "Analista de reclutamiento y selección",
    value: "Analista de reclutamiento y selección"
  },
  { id: "Analista de recursos humanos", value: "Analista de recursos humanos" },
  { id: "Analista de redes sociales", value: "Analista de redes sociales" },
  { id: "Analista de redes", value: "Analista de redes" },
  { id: "Analista de registros", value: "Analista de registros" },
  {
    id: "Analista de relaciones institucionales",
    value: "Analista de relaciones institucionales"
  },
  {
    id: "Analista de relaciones internacionales",
    value: "Analista de relaciones internacionales"
  },
  {
    id: "Analista de relaciones laborales",
    value: "Analista de relaciones laborales"
  },
  { id: "Analista de relaciones", value: "Analista de relaciones" },
  { id: "Analista de requisitos", value: "Analista de requisitos" },
  {
    id: "Analista de responsabilidad social",
    value: "Analista de responsabilidad social"
  },
  { id: "Analista de retención", value: "Analista de retención" },
  { id: "Analista de riesgos", value: "Analista de riesgos" },
  { id: "Analista de sacos", value: "Analista de sacos" },
  { id: "Analista de savia", value: "Analista de savia" },
  { id: "Analista de seguimiento", value: "Analista de seguimiento" },
  {
    id: "Analista de seguridad de activos",
    value: "Analista de seguridad de activos"
  },
  {
    id: "Analista de seguridad de la información",
    value: "Analista de seguridad de la información"
  },
  {
    id: "Analista de seguridad en el trabajo",
    value: "Analista de seguridad en el trabajo"
  },
  { id: "Analista de seguros", value: "Analista de seguros" },
  { id: "Analista de seo", value: "Analista de seo" },
  {
    id: "Analista de servicio al cliente",
    value: "Analista de servicio al cliente"
  },
  { id: "Analista de servicios", value: "Analista de servicios" },
  { id: "Analista de sistemas .net", value: "Analista de sistemas .net" },
  { id: "Analista de sistemas cobol", value: "Analista de sistemas cobol" },
  {
    id: "Analista de sistemas de gestión integrados",
    value: "Analista de sistemas de gestión integrados"
  },
  {
    id: "Analista de sistemas de mainframe",
    value: "Analista de sistemas de mainframe"
  },
  {
    id: "Analista de sistemas de sharepoint",
    value: "Analista de sistemas de sharepoint"
  },
  { id: "Analista de sistemas delphi", value: "Analista de sistemas delphi" },
  { id: "Analista de sistemas erp", value: "Analista de sistemas erp" },
  { id: "Analista de sistemas java", value: "Analista de sistemas java" },
  { id: "Analista de sistemas linux", value: "Analista de sistemas linux" },
  {
    id: "Analista de sistemas microsiga",
    value: "Analista de sistemas microsiga"
  },
  { id: "Analista de sistemas oráculo", value: "Analista de sistemas oráculo" },
  { id: "Analista de sistemas php", value: "Analista de sistemas php" },
  { id: "Analista de sistemas pl sql", value: "Analista de sistemas pl sql" },
  { id: "Analista de sistemas sql", value: "Analista de sistemas sql" },
  { id: "Analista de sistemas web", value: "Analista de sistemas web" },
  { id: "Analista de sistemas", value: "Analista de sistemas" },
  {
    id: "Analista de soporte de mainframe",
    value: "Analista de soporte de mainframe"
  },
  {
    id: "Analista de soporte de software intermedio",
    value: "Analista de soporte de software intermedio"
  },
  { id: "Analista de soporte técnico", value: "Analista de soporte técnico" },
  {
    id: "Analista de soporte websphere",
    value: "Analista de soporte websphere"
  },
  { id: "Analista de sostenibilidad", value: "Analista de sostenibilidad" },
  { id: "Analista de suscripción", value: "Analista de suscripción" },
  {
    id: "Analista de tecnologías de la información",
    value: "Analista de tecnologías de la información"
  },
  {
    id: "Analista de telecomunicaciones",
    value: "Analista de telecomunicaciones"
  },
  { id: "Analista de tesorería", value: "Analista de tesorería" },
  { id: "Analista de transporte", value: "Analista de transporte" },
  { id: "Analista de usabilidad", value: "Analista de usabilidad" },
  { id: "Analista de validación", value: "Analista de validación" },
  { id: "Analista de ventas", value: "Analista de ventas" },
  { id: "Analista de viajes", value: "Analista de viajes" },
  { id: "Analista defensor del pueblo", value: "Analista defensor del pueblo" },
  {
    id: "Analista del departamento de personal",
    value: "Analista del departamento de personal"
  },
  {
    id: "Analista económico financiero",
    value: "Analista económico financiero"
  },
  { id: "Analista expatriado", value: "Analista expatriado" },
  { id: "Analista fiscal", value: "Analista fiscal" },
  { id: "Analista funcional", value: "Analista funcional" },
  { id: "Analista industrial", value: "Analista industrial" },
  { id: "Analista jurídico", value: "Analista jurídico" },
  { id: "Analista pcm", value: "Analista pcm" },
  { id: "Analista pcp", value: "Analista pcp" },
  { id: "Analista pedagógico", value: "Analista pedagógico" },
  { id: "Analista programador .net", value: "Analista programador .net" },
  { id: "Analista programador advpl", value: "Analista programador advpl" },
  { id: "Analista programador android", value: "Analista programador android" },
  { id: "Analista programador asp.net", value: "Analista programador asp.net" },
  { id: "Analista programador c#", value: "Analista programador c#" },
  { id: "Analista programador c++", value: "Analista programador c++" },
  { id: "Analista programador cobol", value: "Analista programador cobol" },
  {
    id: "Analista programador de delphi",
    value: "Analista programador de delphi"
  },
  {
    id: "Analista programador de mainframe",
    value: "Analista programador de mainframe"
  },
  {
    id: "Analista programador de progreso",
    value: "Analista programador de progreso"
  },
  {
    id: "Analista programador de sharepoint",
    value: "Analista programador de sharepoint"
  },
  {
    id: "Analista programador front-end",
    value: "Analista programador front-end"
  },
  { id: "Analista programador java", value: "Analista programador java" },
  { id: "Analista programador oracle", value: "Analista programador oracle" },
  { id: "Analista programador php", value: "Analista programador php" },
  { id: "Analista programador pl sql", value: "Analista programador pl sql" },
  { id: "Analista programador proteo", value: "Analista programador proteo" },
  { id: "Analista programador sql", value: "Analista programador sql" },
  { id: "Analista programador web", value: "Analista programador web" },
  {
    id: "Analista programador websphere",
    value: "Analista programador websphere"
  },
  { id: "Analista qsms", value: "Analista qsms" },
  { id: "Analista químico", value: "Analista químico" },
  { id: "Analista sem", value: "Analista sem" },
  { id: "Analista tierno", value: "Analista tierno" },
  { id: "Analista técnico", value: "Analista técnico" },
  { id: "Animador de fiestas", value: "Animador de fiestas" },
  { id: "Animador digital", value: "Animador digital" },
  { id: "Antropólogo", value: "Antropólogo" },
  { id: "Aparejador", value: "Aparejador" },
  { id: "Aprendiz de derecho", value: "Aprendiz de derecho" },
  { id: "Aprendiz de estadístico", value: "Aprendiz de estadístico" },
  { id: "Aprendiz de soporte técnico", value: "Aprendiz de soporte técnico" },
  { id: "Aprendiz de ventas", value: "Aprendiz de ventas" },
  {
    id: "Aprendiz en administración de empresas",
    value: "Aprendiz en administración de empresas"
  },
  { id: "Aprendiz en agronomía", value: "Aprendiz en agronomía" },
  {
    id: "Aprendiz en arquitectura y urbanismo",
    value: "Aprendiz en arquitectura y urbanismo"
  },
  {
    id: "Aprendiz en ciencias contables",
    value: "Aprendiz en ciencias contables"
  },
  { id: "Aprendiz en economía", value: "Aprendiz en economía" },
  {
    id: "Aprendiz en el campo de la construcción civil",
    value: "Aprendiz en el campo de la construcción civil"
  },
  {
    id: "Aprendiz en el campo del marketing",
    value: "Aprendiz en el campo del marketing"
  },
  {
    id: "Aprendiz en el área administrativa",
    value: "Aprendiz en el área administrativa"
  },
  {
    id: "Aprendiz en el área comercial",
    value: "Aprendiz en el área comercial"
  },
  {
    id: "Aprendiz en el área de contabilidad",
    value: "Aprendiz en el área de contabilidad"
  },
  {
    id: "Aprendiz en el área de facturación",
    value: "Aprendiz en el área de facturación"
  },
  {
    id: "Aprendiz en el área de la cocina",
    value: "Aprendiz en el área de la cocina"
  },
  {
    id: "Aprendiz en el área de la informática",
    value: "Aprendiz en el área de la informática"
  },
  {
    id: "Aprendiz en el área de logística",
    value: "Aprendiz en el área de logística"
  },
  {
    id: "Aprendiz en el área de producción",
    value: "Aprendiz en el área de producción"
  },
  {
    id: "Aprendiz en el área de recepción",
    value: "Aprendiz en el área de recepción"
  },
  {
    id: "Aprendiz en el área de recursos humanos",
    value: "Aprendiz en el área de recursos humanos"
  },
  {
    id: "Aprendiz en el área de servicio",
    value: "Aprendiz en el área de servicio"
  },
  {
    id: "Aprendiz en el área de tecnología de la información",
    value: "Aprendiz en el área de tecnología de la información"
  },
  {
    id: "Aprendiz en el área del departamento de personal",
    value: "Aprendiz en el área del departamento de personal"
  },
  {
    id: "Aprendiz en el área eléctrica",
    value: "Aprendiz en el área eléctrica"
  },
  {
    id: "Aprendiz en el área financiera",
    value: "Aprendiz en el área financiera"
  },
  { id: "Aprendiz en el área fiscal", value: "Aprendiz en el área fiscal" },
  {
    id: "Aprendiz en el área industrial",
    value: "Aprendiz en el área industrial"
  },
  { id: "Aprendiz en el área jurídica", value: "Aprendiz en el área jurídica" },
  { id: "Aprendiz en el área mecánica", value: "Aprendiz en el área mecánica" },
  {
    id: "Aprendiz en el área operativa",
    value: "Aprendiz en el área operativa"
  },
  { id: "Aprendiz en ingeniería civil", value: "Aprendiz en ingeniería civil" },
  {
    id: "Aprendiz en ingeniería de producción",
    value: "Aprendiz en ingeniería de producción"
  },
  {
    id: "Aprendiz en ingeniería eléctrica",
    value: "Aprendiz en ingeniería eléctrica"
  },
  {
    id: "Aprendiz en ingeniería mecánica",
    value: "Aprendiz en ingeniería mecánica"
  },
  {
    id: "Aprendiz en ingeniería química",
    value: "Aprendiz en ingeniería química"
  },
  { id: "Aprendiz en ingeniería", value: "Aprendiz en ingeniería" },
  { id: "Aprendiz en marketing", value: "Aprendiz en marketing" },
  { id: "Aprendiz en nutrición", value: "Aprendiz en nutrición" },
  { id: "Aprendiz en recursos humanos", value: "Aprendiz en recursos humanos" },
  { id: "Aprendiz", value: "Aprendiz" },
  { id: "Archivista", value: "Archivista" },
  { id: "Arqueólogo", value: "Arqueólogo" },
  { id: "Arquitecto .net", value: "Arquitecto .net" },
  { id: "Arquitecto coordinador", value: "Arquitecto coordinador" },
  { id: "Arquitecto de interiores", value: "Arquitecto de interiores" },
  {
    id: "Arquitecto de la construcción",
    value: "Arquitecto de la construcción"
  },
  { id: "Arquitecto de la información", value: "Arquitecto de la información" },
  { id: "Arquitecto de sistemas", value: "Arquitecto de sistemas" },
  { id: "Arquitecto de soluciones", value: "Arquitecto de soluciones" },
  { id: "Arquitecto especificador", value: "Arquitecto especificador" },
  { id: "Arquitecto java", value: "Arquitecto java" },
  { id: "Arquitecto paisajista", value: "Arquitecto paisajista" },
  { id: "Arquitecto urbano", value: "Arquitecto urbano" },
  { id: "Arquitecto", value: "Arquitecto" },
  { id: "Artesano", value: "Artesano" },
  { id: "Articulador social", value: "Articulador social" },
  { id: "Artista", value: "Artista" },
  { id: "Ascensor", value: "Ascensor" },
  { id: "Asesor científico", value: "Asesor científico" },
  { id: "Asesor de comunicación", value: "Asesor de comunicación" },
  {
    id: "Asesor de credito inmobiliario",
    value: "Asesor de credito inmobiliario"
  },
  { id: "Asesor de inversiones", value: "Asesor de inversiones" },
  { id: "Asesor de mercadeo", value: "Asesor de mercadeo" },
  { id: "Asesor de posventa", value: "Asesor de posventa" },
  { id: "Asesor de prensa", value: "Asesor de prensa" },
  { id: "Asesor de tienda", value: "Asesor de tienda" },
  { id: "Asesor educativo", value: "Asesor educativo" },
  { id: "Asesor jurídico", value: "Asesor jurídico" },
  { id: "Asesor legal", value: "Asesor legal" },
  { id: "Asesor socioeducativo", value: "Asesor socioeducativo" },
  { id: "Asesor tecnico industrial", value: "Asesor tecnico industrial" },
  { id: "Asesor tributario", value: "Asesor tributario" },
  { id: "Asesor técnico de vehículos", value: "Asesor técnico de vehículos" },
  {
    id: "Asistente administrativo de mantenimiento",
    value: "Asistente administrativo de mantenimiento"
  },
  { id: "Asistente administrativo", value: "Asistente administrativo" },
  { id: "Asistente comercial", value: "Asistente comercial" },
  { id: "Asistente contable", value: "Asistente contable" },
  { id: "Asistente corporativo", value: "Asistente corporativo" },
  { id: "Asistente de abastecimiento", value: "Asistente de abastecimiento" },
  { id: "Asistente de acabado gráfico", value: "Asistente de acabado gráfico" },
  { id: "Asistente de acreditación", value: "Asistente de acreditación" },
  { id: "Asistente de activos fijos", value: "Asistente de activos fijos" },
  { id: "Asistente de activos", value: "Asistente de activos" },
  {
    id: "Asistente de alimentos y bebidas",
    value: "Asistente de alimentos y bebidas"
  },
  { id: "Asistente de almacén", value: "Asistente de almacén" },
  { id: "Asistente de alquiler", value: "Asistente de alquiler" },
  { id: "Asistente de animales", value: "Asistente de animales" },
  { id: "Asistente de archivo", value: "Asistente de archivo" },
  { id: "Asistente de archivos", value: "Asistente de archivos" },
  { id: "Asistente de arquitectura", value: "Asistente de arquitectura" },
  { id: "Asistente de arrendamiento", value: "Asistente de arrendamiento" },
  { id: "Asistente de arte", value: "Asistente de arte" },
  {
    id: "Asistente de asistencia técnica",
    value: "Asistente de asistencia técnica"
  },
  {
    id: "Asistente de asuntos regulatorios",
    value: "Asistente de asuntos regulatorios"
  },
  {
    id: "Asistente de auditoria contable",
    value: "Asistente de auditoria contable"
  },
  { id: "Asistente de auditoría", value: "Asistente de auditoría" },
  { id: "Asistente de banco de sangre", value: "Asistente de banco de sangre" },
  { id: "Asistente de beneficios", value: "Asistente de beneficios" },
  { id: "Asistente de biblioteca", value: "Asistente de biblioteca" },
  { id: "Asistente de bordado", value: "Asistente de bordado" },
  { id: "Asistente de búsqueda", value: "Asistente de búsqueda" },
  { id: "Asistente de calidad", value: "Asistente de calidad" },
  { id: "Asistente de cambio", value: "Asistente de cambio" },
  { id: "Asistente de campo", value: "Asistente de campo" },
  {
    id: "Asistente de carga y descarga",
    value: "Asistente de carga y descarga"
  },
  { id: "Asistente de clase", value: "Asistente de clase" },
  { id: "Asistente de cobranza", value: "Asistente de cobranza" },
  {
    id: "Asistente de comercialización",
    value: "Asistente de comercialización"
  },
  {
    id: "Asistente de comercio electrónico",
    value: "Asistente de comercio electrónico"
  },
  {
    id: "Asistente de comercio exterior",
    value: "Asistente de comercio exterior"
  },
  { id: "Asistente de compras", value: "Asistente de compras" },
  { id: "Asistente de computadora", value: "Asistente de computadora" },
  {
    id: "Asistente de comunicación y marketing",
    value: "Asistente de comunicación y marketing"
  },
  { id: "Asistente de comunicación", value: "Asistente de comunicación" },
  { id: "Asistente de condominio", value: "Asistente de condominio" },
  { id: "Asistente de contabilidad", value: "Asistente de contabilidad" },
  { id: "Asistente de contenido web", value: "Asistente de contenido web" },
  { id: "Asistente de contrato", value: "Asistente de contrato" },
  {
    id: "Asistente de control de calidad",
    value: "Asistente de control de calidad"
  },
  {
    id: "Asistente de controles internos",
    value: "Asistente de controles internos"
  },
  { id: "Asistente de corte", value: "Asistente de corte" },
  { id: "Asistente de costos", value: "Asistente de costos" },
  { id: "Asistente de creación", value: "Asistente de creación" },
  { id: "Asistente de crm", value: "Asistente de crm" },
  {
    id: "Asistente de crédito inmobiliario",
    value: "Asistente de crédito inmobiliario"
  },
  {
    id: "Asistente de crédito y cobranza",
    value: "Asistente de crédito y cobranza"
  },
  { id: "Asistente de crédito", value: "Asistente de crédito" },
  {
    id: "Asistente de créditos y cobranzas",
    value: "Asistente de créditos y cobranzas"
  },
  {
    id: "Asistente de cuentas por cobrar",
    value: "Asistente de cuentas por cobrar"
  },
  {
    id: "Asistente de cuentas por pagar y por cobrar",
    value: "Asistente de cuentas por pagar y por cobrar"
  },
  {
    id: "Asistente de cuentas por pagar",
    value: "Asistente de cuentas por pagar"
  },
  { id: "Asistente de cumplimiento", value: "Asistente de cumplimiento" },
  {
    id: "Asistente de desarrollo de productos",
    value: "Asistente de desarrollo de productos"
  },
  {
    id: "Asistente de despacho de aduanas",
    value: "Asistente de despacho de aduanas"
  },
  { id: "Asistente de despacho", value: "Asistente de despacho" },
  { id: "Asistente de dibujante", value: "Asistente de dibujante" },
  {
    id: "Asistente de diseñador gráfico",
    value: "Asistente de diseñador gráfico"
  },
  { id: "Asistente de diseñador", value: "Asistente de diseñador" },
  { id: "Asistente de diseño gráfico", value: "Asistente de diseño gráfico" },
  { id: "Asistente de diseño web", value: "Asistente de diseño web" },
  { id: "Asistente de diseño", value: "Asistente de diseño" },
  { id: "Asistente de documentación", value: "Asistente de documentación" },
  { id: "Asistente de enseñanza", value: "Asistente de enseñanza" },
  { id: "Asistente de entrega", value: "Asistente de entrega" },
  { id: "Asistente de estilo", value: "Asistente de estilo" },
  { id: "Asistente de estética", value: "Asistente de estética" },
  { id: "Asistente de eventos", value: "Asistente de eventos" },
  {
    id: "Asistente de facturación del hospital",
    value: "Asistente de facturación del hospital"
  },
  { id: "Asistente de facturación", value: "Asistente de facturación" },
  {
    id: "Asistente de facturas médicas",
    value: "Asistente de facturas médicas"
  },
  { id: "Asistente de farmacia", value: "Asistente de farmacia" },
  { id: "Asistente de flota", value: "Asistente de flota" },
  {
    id: "Asistente de formación y desarrollo",
    value: "Asistente de formación y desarrollo"
  },
  { id: "Asistente de fotografía", value: "Asistente de fotografía" },
  { id: "Asistente de fotógrafo", value: "Asistente de fotógrafo" },
  { id: "Asistente de franquicia", value: "Asistente de franquicia" },
  { id: "Asistente de garantía", value: "Asistente de garantía" },
  { id: "Asistente de gobierno", value: "Asistente de gobierno" },
  { id: "Asistente de implementación", value: "Asistente de implementación" },
  { id: "Asistente de impuestos", value: "Asistente de impuestos" },
  { id: "Asistente de infraestructura", value: "Asistente de infraestructura" },
  {
    id: "Asistente de ingeniería civil",
    value: "Asistente de ingeniería civil"
  },
  { id: "Asistente de ingeniería", value: "Asistente de ingeniería" },
  { id: "Asistente de instalaciones", value: "Asistente de instalaciones" },
  {
    id: "Asistente de inteligencia de mercado",
    value: "Asistente de inteligencia de mercado"
  },
  { id: "Asistente de inventario", value: "Asistente de inventario" },
  {
    id: "Asistente de investigación de mercado",
    value: "Asistente de investigación de mercado"
  },
  {
    id: "Asistente de investigación y desarrollo",
    value: "Asistente de investigación y desarrollo"
  },
  {
    id: "Asistente de la mesa de ayuda",
    value: "Asistente de la mesa de ayuda"
  },
  { id: "Asistente de laboratorio", value: "Asistente de laboratorio" },
  { id: "Asistente de lavandería", value: "Asistente de lavandería" },
  { id: "Asistente de legalización", value: "Asistente de legalización" },
  { id: "Asistente de limpieza", value: "Asistente de limpieza" },
  { id: "Asistente de logistica", value: "Asistente de logistica" },
  { id: "Asistente de logística", value: "Asistente de logística" },
  { id: "Asistente de manipulación", value: "Asistente de manipulación" },
  {
    id: "Asistente de mantenimiento de edificios",
    value: "Asistente de mantenimiento de edificios"
  },
  {
    id: "Asistente de mantenimiento eléctrico",
    value: "Asistente de mantenimiento eléctrico"
  },
  {
    id: "Asistente de mantenimiento hidraulico",
    value: "Asistente de mantenimiento hidraulico"
  },
  {
    id: "Asistente de mantenimiento industrial",
    value: "Asistente de mantenimiento industrial"
  },
  {
    id: "Asistente de mantenimiento mecánico",
    value: "Asistente de mantenimiento mecánico"
  },
  { id: "Asistente de mantenimiento", value: "Asistente de mantenimiento" },
  {
    id: "Asistente de marketing comercial",
    value: "Asistente de marketing comercial"
  },
  {
    id: "Asistente de marketing digital",
    value: "Asistente de marketing digital"
  },
  { id: "Asistente de marketing", value: "Asistente de marketing" },
  { id: "Asistente de mecanizado", value: "Asistente de mecanizado" },
  { id: "Asistente de medio ambiente", value: "Asistente de medio ambiente" },
  { id: "Asistente de medios", value: "Asistente de medios" },
  {
    id: "Asistente de merchandising visual",
    value: "Asistente de merchandising visual"
  },
  { id: "Asistente de mesa de control", value: "Asistente de mesa de control" },
  {
    id: "Asistente de mesa de servicio",
    value: "Asistente de mesa de servicio"
  },
  { id: "Asistente de modelado", value: "Asistente de modelado" },
  { id: "Asistente de modelista", value: "Asistente de modelista" },
  { id: "Asistente de montaje", value: "Asistente de montaje" },
  {
    id: "Asistente de mostrador de servicio",
    value: "Asistente de mostrador de servicio"
  },
  { id: "Asistente de negocios", value: "Asistente de negocios" },
  { id: "Asistente de nutrición", value: "Asistente de nutrición" },
  { id: "Asistente de nómina", value: "Asistente de nómina" },
  { id: "Asistente de oficina pública", value: "Asistente de oficina pública" },
  { id: "Asistente de orfebre", value: "Asistente de orfebre" },
  { id: "Asistente de peluquería", value: "Asistente de peluquería" },
  { id: "Asistente de perecederos", value: "Asistente de perecederos" },
  { id: "Asistente de pesaje", value: "Asistente de pesaje" },
  { id: "Asistente de pintura", value: "Asistente de pintura" },
  { id: "Asistente de pista", value: "Asistente de pista" },
  { id: "Asistente de planificación", value: "Asistente de planificación" },
  { id: "Asistente de posventa", value: "Asistente de posventa" },
  { id: "Asistente de precios", value: "Asistente de precios" },
  { id: "Asistente de presupuesto", value: "Asistente de presupuesto" },
  {
    id: "Asistente de prevención de pérdidas",
    value: "Asistente de prevención de pérdidas"
  },
  {
    id: "Asistente de procesamiento de datos",
    value: "Asistente de procesamiento de datos"
  },
  { id: "Asistente de proceso", value: "Asistente de proceso" },
  {
    id: "Asistente de producción de edición",
    value: "Asistente de producción de edición"
  },
  { id: "Asistente de producción", value: "Asistente de producción" },
  { id: "Asistente de producto", value: "Asistente de producto" },
  { id: "Asistente de programación", value: "Asistente de programación" },
  { id: "Asistente de proyecto", value: "Asistente de proyecto" },
  { id: "Asistente de prueba", value: "Asistente de prueba" },
  {
    id: "Asistente de publicidad y publicidad",
    value: "Asistente de publicidad y publicidad"
  },
  { id: "Asistente de publicidad", value: "Asistente de publicidad" },
  { id: "Asistente de recepción", value: "Asistente de recepción" },
  {
    id: "Asistente de recibo de impuestos",
    value: "Asistente de recibo de impuestos"
  },
  { id: "Asistente de reclamaciones", value: "Asistente de reclamaciones" },
  {
    id: "Asistente de reclutamiento y selección",
    value: "Asistente de reclutamiento y selección"
  },
  { id: "Asistente de recreación", value: "Asistente de recreación" },
  {
    id: "Asistente de recursos humanos",
    value: "Asistente de recursos humanos"
  },
  { id: "Asistente de redacción", value: "Asistente de redacción" },
  { id: "Asistente de redes sociales", value: "Asistente de redes sociales" },
  { id: "Asistente de registro", value: "Asistente de registro" },
  {
    id: "Asistente de relaciones públicas",
    value: "Asistente de relaciones públicas"
  },
  { id: "Asistente de relaciones", value: "Asistente de relaciones" },
  { id: "Asistente de reservas", value: "Asistente de reservas" },
  { id: "Asistente de restaurante", value: "Asistente de restaurante" },
  { id: "Asistente de riesgos", value: "Asistente de riesgos" },
  { id: "Asistente de riqueza", value: "Asistente de riqueza" },
  { id: "Asistente de saco", value: "Asistente de saco" },
  { id: "Asistente de sacos", value: "Asistente de sacos" },
  { id: "Asistente de salud bucal", value: "Asistente de salud bucal" },
  { id: "Asistente de secretaria", value: "Asistente de secretaria" },
  { id: "Asistente de seguimiento", value: "Asistente de seguimiento" },
  {
    id: "Asistente de seguridad de activos",
    value: "Asistente de seguridad de activos"
  },
  {
    id: "Asistente de seguridad de la información",
    value: "Asistente de seguridad de la información"
  },
  {
    id: "Asistente de seguridad en el trabajo",
    value: "Asistente de seguridad en el trabajo"
  },
  { id: "Asistente de seguros", value: "Asistente de seguros" },
  {
    id: "Asistente de servicio al cliente",
    value: "Asistente de servicio al cliente"
  },
  { id: "Asistente de servicio", value: "Asistente de servicio" },
  { id: "Asistente de sistemas", value: "Asistente de sistemas" },
  { id: "Asistente de sondeo", value: "Asistente de sondeo" },
  { id: "Asistente de soporte técnico", value: "Asistente de soporte técnico" },
  { id: "Asistente de supervisión", value: "Asistente de supervisión" },
  { id: "Asistente de sushiman", value: "Asistente de sushiman" },
  {
    id: "Asistente de tecnico de construccion",
    value: "Asistente de tecnico de construccion"
  },
  {
    id: "Asistente de tecnología de la información",
    value: "Asistente de tecnología de la información"
  },
  {
    id: "Asistente de telecomunicaciones",
    value: "Asistente de telecomunicaciones"
  },
  { id: "Asistente de telemercadeo", value: "Asistente de telemercadeo" },
  { id: "Asistente de televentas", value: "Asistente de televentas" },
  { id: "Asistente de tesorería", value: "Asistente de tesorería" },
  {
    id: "Asistente de tienda de caucho",
    value: "Asistente de tienda de caucho"
  },
  { id: "Asistente de topografía", value: "Asistente de topografía" },
  {
    id: "Asistente de trabajos y salarios",
    value: "Asistente de trabajos y salarios"
  },
  { id: "Asistente de transporte", value: "Asistente de transporte" },
  { id: "Asistente de triaje", value: "Asistente de triaje" },
  { id: "Asistente de turismo", value: "Asistente de turismo" },
  { id: "Asistente de ventas", value: "Asistente de ventas" },
  { id: "Asistente del controlador", value: "Asistente del controlador" },
  {
    id: "Asistente del secretario de la escuela",
    value: "Asistente del secretario de la escuela"
  },
  { id: "Asistente ejecutiva", value: "Asistente ejecutiva" },
  { id: "Asistente electrico", value: "Asistente electrico" },
  { id: "Asistente financiero", value: "Asistente financiero" },
  { id: "Asistente fiscal", value: "Asistente fiscal" },
  { id: "Asistente industrial", value: "Asistente industrial" },
  { id: "Asistente juridico", value: "Asistente juridico" },
  { id: "Asistente operacional", value: "Asistente operacional" },
  { id: "Asistente operativo", value: "Asistente operativo" },
  { id: "Asistente paralegal", value: "Asistente paralegal" },
  { id: "Asistente pcp", value: "Asistente pcp" },
  { id: "Asistente pedagógico", value: "Asistente pedagógico" },
  {
    id: "Asistente personal del departamento",
    value: "Asistente personal del departamento"
  },
  { id: "Asistente personal", value: "Asistente personal" },
  { id: "Asistente químico", value: "Asistente químico" },
  { id: "Asistente social", value: "Asistente social" },
  {
    id: "Asistente tecnico en edificaciones",
    value: "Asistente tecnico en edificaciones"
  },
  {
    id: "Asistente tecnico en electronica",
    value: "Asistente tecnico en electronica"
  },
  { id: "Asistente tierno", value: "Asistente tierno" },
  { id: "Asistente técnico de campo", value: "Asistente técnico de campo" },
  { id: "Asistente técnico de ventas", value: "Asistente técnico de ventas" },
  {
    id: "Asistente técnico electrónico",
    value: "Asistente técnico electrónico"
  },
  {
    id: "Asistente técnico en mecatrónica",
    value: "Asistente técnico en mecatrónica"
  },
  {
    id: "Asistente técnico en seguridad laboral",
    value: "Asistente técnico en seguridad laboral"
  },
  { id: "Asistente técnico mecánico", value: "Asistente técnico mecánico" },
  { id: "Asistente técnico", value: "Asistente técnico" },
  { id: "Asistente veterinario", value: "Asistente veterinario" },
  { id: "Asistente", value: "Asistente" },
  { id: "Atar", value: "Atar" },
  { id: "Auditor de cuentas", value: "Auditor de cuentas" },
  { id: "Auditor de facturas médicas", value: "Auditor de facturas médicas" },
  { id: "Auditor de inventario", value: "Auditor de inventario" },
  { id: "Auditor de procesos", value: "Auditor de procesos" },
  { id: "Auditor de sistemas", value: "Auditor de sistemas" },
  { id: "Auditor de tienda", value: "Auditor de tienda" },
  { id: "Auditor en prácticas", value: "Auditor en prácticas" },
  { id: "Auditor externo", value: "Auditor externo" },
  { id: "Auditor financiero", value: "Auditor financiero" },
  { id: "Auditor fiscal", value: "Auditor fiscal" },
  { id: "Auditor interno", value: "Auditor interno" },
  { id: "Auditor nocturno", value: "Auditor nocturno" },
  { id: "Auditora de calidad", value: "Auditora de calidad" },
  { id: "Audiólogo", value: "Audiólogo" },
  { id: "Auxiliar administrativo", value: "Auxiliar administrativo" },
  { id: "Auxiliar de cocina", value: "Auxiliar de cocina" },
  {
    id: "Auxiliar de enfermería laboral",
    value: "Auxiliar de enfermería laboral"
  },
  { id: "Auxiliar de enfermería", value: "Auxiliar de enfermería" },
  { id: "Auxiliar de producción", value: "Auxiliar de producción" },
  {
    id: "Auxiliar de servicios generales",
    value: "Auxiliar de servicios generales"
  },
  { id: "Auxiliar de tI", value: "Auxiliar de tI" },
  { id: "Auxiliar de vuelo", value: "Auxiliar de vuelo" },
  {
    id: "Auxiliar del defensor del pueblo",
    value: "Auxiliar del defensor del pueblo"
  },
  { id: "Auxiliar técnico", value: "Auxiliar técnico" },
  { id: "Ayuda de enfriamiento", value: "Ayuda de enfriamiento" },
  { id: "Ayuda financiera", value: "Ayuda financiera" },
  { id: "Ayudante de acciones", value: "Ayudante de acciones" },
  {
    id: "Ayudante de agente de aduanas",
    value: "Ayudante de agente de aduanas"
  },
  { id: "Ayudante de base de datos", value: "Ayudante de base de datos" },
  { id: "Ayudante de calderería", value: "Ayudante de calderería" },
  { id: "Ayudante de camarero", value: "Ayudante de camarero" },
  { id: "Ayudante de carga y descarga", value: "Ayudante de carga y descarga" },
  { id: "Ayudante de carnicero", value: "Ayudante de carnicero" },
  { id: "Ayudante de carpintero", value: "Ayudante de carpintero" },
  { id: "Ayudante de carrocería", value: "Ayudante de carrocería" },
  { id: "Ayudante de cerrajero", value: "Ayudante de cerrajero" },
  { id: "Ayudante de compensación", value: "Ayudante de compensación" },
  { id: "Ayudante de costura", value: "Ayudante de costura" },
  { id: "Ayudante de dentista", value: "Ayudante de dentista" },
  { id: "Ayudante de electricista", value: "Ayudante de electricista" },
  { id: "Ayudante de estilo", value: "Ayudante de estilo" },
  { id: "Ayudante de extrusión", value: "Ayudante de extrusión" },
  { id: "Ayudante de fundición", value: "Ayudante de fundición" },
  { id: "Ayudante de impresión", value: "Ayudante de impresión" },
  { id: "Ayudante de instalación", value: "Ayudante de instalación" },
  { id: "Ayudante de jardín", value: "Ayudante de jardín" },
  { id: "Ayudante de lubricación", value: "Ayudante de lubricación" },
  { id: "Ayudante de metalúrgico", value: "Ayudante de metalúrgico" },
  { id: "Ayudante de obras", value: "Ayudante de obras" },
  { id: "Ayudante de oftalmología", value: "Ayudante de oftalmología" },
  { id: "Ayudante de panadero", value: "Ayudante de panadero" },
  { id: "Ayudante de parrilla", value: "Ayudante de parrilla" },
  { id: "Ayudante de pastelería", value: "Ayudante de pastelería" },
  { id: "Ayudante de pizzero", value: "Ayudante de pizzero" },
  { id: "Ayudante de plomero", value: "Ayudante de plomero" },
  { id: "Ayudante de reemplazo", value: "Ayudante de reemplazo" },
  { id: "Ayudante de soldador", value: "Ayudante de soldador" },
  { id: "Ayudante de tapicero", value: "Ayudante de tapicero" },
  { id: "Ayudante de vidriero", value: "Ayudante de vidriero" },
  { id: "Ayudante del conductor", value: "Ayudante del conductor" },
  { id: "Ayudante del tejedor", value: "Ayudante del tejedor" },
  { id: "Ayudante general", value: "Ayudante general" },
  { id: "Ayudante pcp", value: "Ayudante pcp" },
  { id: "Bailarín", value: "Bailarín" },
  { id: "Balancín", value: "Balancín" },
  { id: "Bamburista", value: "Bamburista" },
  { id: "Barista", value: "Barista" },
  { id: "Barman", value: "Barman" },
  { id: "Base de datos sql", value: "Base de datos sql" },
  {
    id: "Becario en el área de compras",
    value: "Becario en el área de compras"
  },
  {
    id: "Becario en el área del departamento de personal",
    value: "Becario en el área del departamento de personal"
  },
  { id: "Biblioteca intérprete", value: "Biblioteca intérprete" },
  { id: "Bibliotecario", value: "Bibliotecario" },
  { id: "Biomédico", value: "Biomédico" },
  { id: "Bioquímico", value: "Bioquímico" },
  { id: "Biotecnólogo", value: "Biotecnólogo" },
  { id: "Biólogo", value: "Biólogo" },
  { id: "Bloguero", value: "Bloguero" },
  { id: "Bloqueador", value: "Bloqueador" },
  { id: "Bláster", value: "Bláster" },
  { id: "Bocadillo", value: "Bocadillo" },
  { id: "Bomba de ensaladas", value: "Bomba de ensaladas" },
  { id: "Bombero civil", value: "Bombero civil" },
  { id: "Bombero industrial", value: "Bombero industrial" },
  { id: "Bordar", value: "Bordar" },
  { id: "Brasero", value: "Brasero" },
  { id: "CEO", value: "CEO" },
  { id: "Cabista", value: "Cabista" },
  { id: "Caja de banco", value: "Caja de banco" },
  { id: "Cajón", value: "Cajón" },
  { id: "Calculadora", value: "Calculadora" },
  { id: "Camarógrafo", value: "Camarógrafo" },
  { id: "Cambiador de moldes", value: "Cambiador de moldes" },
  { id: "Capataz de calderas", value: "Capataz de calderas" },
  { id: "Capataz de carpintería", value: "Capataz de carpintería" },
  { id: "Capataz de flota", value: "Capataz de flota" },
  { id: "Capataz de maquinado", value: "Capataz de maquinado" },
  { id: "Capataz de soldadura", value: "Capataz de soldadura" },
  { id: "Caracterizador", value: "Caracterizador" },
  { id: "Carnicero", value: "Carnicero" },
  { id: "Carnicería", value: "Carnicería" },
  { id: "Carpintero semioficial", value: "Carpintero semioficial" },
  { id: "Carpintero", value: "Carpintero" },
  { id: "Cartero", value: "Cartero" },
  { id: "Catador", value: "Catador" },
  { id: "Cenotécnico", value: "Cenotécnico" },
  { id: "Cera para muebles", value: "Cera para muebles" },
  { id: "Cerrajero semioficial", value: "Cerrajero semioficial" },
  { id: "Cerrajero", value: "Cerrajero" },
  { id: "Chamarman", value: "Chamarman" },
  { id: "Chapeiro", value: "Chapeiro" },
  { id: "Científico de datos", value: "Científico de datos" },
  { id: "Cilindro", value: "Cilindro" },
  { id: "Clasificador de granos", value: "Clasificador de granos" },
  { id: "Clasificador de mercancías", value: "Clasificador de mercancías" },
  { id: "Cm enfermera", value: "Cm enfermera" },
  { id: "Cobrador de bus", value: "Cobrador de bus" },
  { id: "Cocinero", value: "Cocinero" },
  { id: "Codificador de datos", value: "Codificador de datos" },
  { id: "Colchón", value: "Colchón" },
  { id: "Colorista de papel", value: "Colorista de papel" },
  { id: "Colorista textil", value: "Colorista textil" },
  { id: "Colorista", value: "Colorista" },
  { id: "Comedor", value: "Comedor" },
  { id: "Comerciante visual", value: "Comerciante visual" },
  { id: "Comerciante", value: "Comerciante" },
  { id: "Comisionado de daños", value: "Comisionado de daños" },
  { id: "Comprador de drogas", value: "Comprador de drogas" },
  { id: "Comprador internacional", value: "Comprador internacional" },
  { id: "Comprador oculto", value: "Comprador oculto" },
  { id: "Comprador técnico", value: "Comprador técnico" },
  { id: "Comprador", value: "Comprador" },
  { id: "Comunicador social", value: "Comunicador social" },
  { id: "Conductor de tractor", value: "Conductor de tractor" },
  { id: "Conductor de tren", value: "Conductor de tren" },
  { id: "Conferente", value: "Conferente" },
  { id: "Confitero", value: "Confitero" },
  { id: "Consejero supervisor", value: "Consejero supervisor" },
  { id: "Conserje", value: "Conserje" },
  { id: "Consultor abap", value: "Consultor abap" },
  { id: "Consultor ambiental", value: "Consultor ambiental" },
  { id: "Consultor contable", value: "Consultor contable" },
  { id: "Consultor crm", value: "Consultor crm" },
  { id: "Consultor de beneficios", value: "Consultor de beneficios" },
  { id: "Consultor de calidad", value: "Consultor de calidad" },
  {
    id: "Consultor de comercialización",
    value: "Consultor de comercialización"
  },
  {
    id: "Consultor de comercio exterior",
    value: "Consultor de comercio exterior"
  },
  { id: "Consultor de comunicación", value: "Consultor de comunicación" },
  { id: "Consultor de control", value: "Consultor de control" },
  { id: "Consultor de esteticista", value: "Consultor de esteticista" },
  { id: "Consultor de eventos", value: "Consultor de eventos" },
  {
    id: "Consultor de formación y desarrollo",
    value: "Consultor de formación y desarrollo"
  },
  { id: "Consultor de franquicias", value: "Consultor de franquicias" },
  { id: "Consultor de implementación", value: "Consultor de implementación" },
  { id: "Consultor de impuestos", value: "Consultor de impuestos" },
  { id: "Consultor de infraestructura", value: "Consultor de infraestructura" },
  { id: "Consultor de inmigración", value: "Consultor de inmigración" },
  {
    id: "Consultor de inteligencia de mercado",
    value: "Consultor de inteligencia de mercado"
  },
  { id: "Consultor de intercambio", value: "Consultor de intercambio" },
  { id: "Consultor de inversiones", value: "Consultor de inversiones" },
  {
    id: "Consultor de marketing comercial",
    value: "Consultor de marketing comercial"
  },
  { id: "Consultor de marketing", value: "Consultor de marketing" },
  { id: "Consultor de moda", value: "Consultor de moda" },
  { id: "Consultor de negocios", value: "Consultor de negocios" },
  { id: "Consultor de operaciones", value: "Consultor de operaciones" },
  { id: "Consultor de oráculo", value: "Consultor de oráculo" },
  {
    id: "Consultor de planificación estratégica",
    value: "Consultor de planificación estratégica"
  },
  { id: "Consultor de procesos", value: "Consultor de procesos" },
  { id: "Consultor de proyectos", value: "Consultor de proyectos" },
  {
    id: "Consultor de recursos humanos",
    value: "Consultor de recursos humanos"
  },
  { id: "Consultor de redes", value: "Consultor de redes" },
  { id: "Consultor de relaciones", value: "Consultor de relaciones" },
  {
    id: "Consultor de seguridad de la información",
    value: "Consultor de seguridad de la información"
  },
  { id: "Consultor de seguros", value: "Consultor de seguros" },
  { id: "Consultor de servicios", value: "Consultor de servicios" },
  { id: "Consultor de sistemas", value: "Consultor de sistemas" },
  {
    id: "Consultor de tecnología de la información",
    value: "Consultor de tecnología de la información"
  },
  { id: "Consultor de ventas", value: "Consultor de ventas" },
  { id: "Consultor educativo", value: "Consultor educativo" },
  { id: "Consultor erp", value: "Consultor erp" },
  { id: "Consultor financiero", value: "Consultor financiero" },
  { id: "Consultor funcional", value: "Consultor funcional" },
  { id: "Consultor gastronómico", value: "Consultor gastronómico" },
  { id: "Consultor logístico", value: "Consultor logístico" },
  { id: "Consultor mastersaf", value: "Consultor mastersaf" },
  { id: "Consultor pedagógico", value: "Consultor pedagógico" },
  { id: "Consultor savia", value: "Consultor savia" },
  { id: "Consultor siebel", value: "Consultor siebel" },
  { id: "Consultor técnico", value: "Consultor técnico" },
  { id: "Consultora de belleza", value: "Consultora de belleza" },
  {
    id: "Consultora de reclutamiento y selección",
    value: "Consultora de reclutamiento y selección"
  },
  {
    id: "Consultora en responsabilidad social",
    value: "Consultora en responsabilidad social"
  },
  { id: "Contable", value: "Contable" },
  { id: "Contacto publicitario", value: "Contacto publicitario" },
  { id: "Contador", value: "Contador" },
  { id: "Contentista", value: "Contentista" },
  { id: "Continuo", value: "Continuo" },
  { id: "Contra la regla", value: "Contra la regla" },
  { id: "Controlador analista", value: "Controlador analista" },
  { id: "Controlador de calidad", value: "Controlador de calidad" },
  { id: "Controlador de costes", value: "Controlador de costes" },
  { id: "Controlador de patio", value: "Controlador de patio" },
  { id: "Controlador de producción", value: "Controlador de producción" },
  { id: "Controlador de tráfico aéreo", value: "Controlador de tráfico aéreo" },
  { id: "Controlador de tráfico", value: "Controlador de tráfico" },
  { id: "Controlador", value: "Controlador" },
  {
    id: "Coordinador administrativo de obras",
    value: "Coordinador administrativo de obras"
  },
  { id: "Coordinador administrativo", value: "Coordinador administrativo" },
  { id: "Coordinador comercial", value: "Coordinador comercial" },
  { id: "Coordinador crm", value: "Coordinador crm" },
  {
    id: "Coordinador de abastecimiento",
    value: "Coordinador de abastecimiento"
  },
  {
    id: "Coordinador de acreditaciones",
    value: "Coordinador de acreditaciones"
  },
  {
    id: "Coordinador de alimentos y bebidas",
    value: "Coordinador de alimentos y bebidas"
  },
  { id: "Coordinador de almacén", value: "Coordinador de almacén" },
  {
    id: "Coordinador de asistencia técnica",
    value: "Coordinador de asistencia técnica"
  },
  {
    id: "Coordinador de asuntos regulatorios",
    value: "Coordinador de asuntos regulatorios"
  },
  { id: "Coordinador de auditoría", value: "Coordinador de auditoría" },
  { id: "Coordinador de beneficios", value: "Coordinador de beneficios" },
  { id: "Coordinador de biblioteca", value: "Coordinador de biblioteca" },
  { id: "Coordinador de calidad", value: "Coordinador de calidad" },
  { id: "Coordinador de campo", value: "Coordinador de campo" },
  {
    id: "Coordinador de comercialización",
    value: "Coordinador de comercialización"
  },
  {
    id: "Coordinador de comercio electrónico",
    value: "Coordinador de comercio electrónico"
  },
  {
    id: "Coordinador de comercio exterior",
    value: "Coordinador de comercio exterior"
  },
  { id: "Coordinador de compras", value: "Coordinador de compras" },
  { id: "Coordinador de comunicación", value: "Coordinador de comunicación" },
  { id: "Coordinador de contabilidad", value: "Coordinador de contabilidad" },
  { id: "Coordinador de contraloría", value: "Coordinador de contraloría" },
  { id: "Coordinador de contratos", value: "Coordinador de contratos" },
  {
    id: "Coordinador de control de calidad",
    value: "Coordinador de control de calidad"
  },
  {
    id: "Coordinador de controles internos",
    value: "Coordinador de controles internos"
  },
  { id: "Coordinador de costos", value: "Coordinador de costos" },
  {
    id: "Coordinador de credito inmobiliario",
    value: "Coordinador de credito inmobiliario"
  },
  { id: "Coordinador de credito", value: "Coordinador de credito" },
  {
    id: "Coordinador de crédito y cobranza",
    value: "Coordinador de crédito y cobranza"
  },
  { id: "Coordinador de cuenta", value: "Coordinador de cuenta" },
  {
    id: "Coordinador de cuentas por cobrar",
    value: "Coordinador de cuentas por cobrar"
  },
  {
    id: "Coordinador de cuentas por pagar",
    value: "Coordinador de cuentas por pagar"
  },
  {
    id: "Coordinador de desarrollo de sistemas",
    value: "Coordinador de desarrollo de sistemas"
  },
  { id: "Coordinador de despacho", value: "Coordinador de despacho" },
  { id: "Coordinador de despliegue", value: "Coordinador de despliegue" },
  { id: "Coordinador de enfermería", value: "Coordinador de enfermería" },
  { id: "Coordinador de equidad", value: "Coordinador de equidad" },
  { id: "Coordinador de estilo", value: "Coordinador de estilo" },
  {
    id: "Coordinador de estudios de mercado",
    value: "Coordinador de estudios de mercado"
  },
  { id: "Coordinador de facturación", value: "Coordinador de facturación" },
  {
    id: "Coordinador de facturas médicas",
    value: "Coordinador de facturas médicas"
  },
  {
    id: "Coordinador de formación y desarrollo",
    value: "Coordinador de formación y desarrollo"
  },
  { id: "Coordinador de hospitalidad", value: "Coordinador de hospitalidad" },
  { id: "Coordinador de impuestos", value: "Coordinador de impuestos" },
  { id: "Coordinador de incorporación", value: "Coordinador de incorporación" },
  {
    id: "Coordinador de infraestructura",
    value: "Coordinador de infraestructura"
  },
  {
    id: "Coordinador de inteligencia de mercado",
    value: "Coordinador de inteligencia de mercado"
  },
  {
    id: "Coordinador de inteligencia de negocios",
    value: "Coordinador de inteligencia de negocios"
  },
  { id: "Coordinador de inventario", value: "Coordinador de inventario" },
  { id: "Coordinador de inversiones", value: "Coordinador de inversiones" },
  {
    id: "Coordinador de investigación y desarrollo",
    value: "Coordinador de investigación y desarrollo"
  },
  {
    id: "Coordinador de la mesa de ayuda",
    value: "Coordinador de la mesa de ayuda"
  },
  { id: "Coordinador de laboratorio", value: "Coordinador de laboratorio" },
  {
    id: "Coordinador de legalizaciones",
    value: "Coordinador de legalizaciones"
  },
  { id: "Coordinador de licitaciones", value: "Coordinador de licitaciones" },
  { id: "Coordinador de limpieza", value: "Coordinador de limpieza" },
  {
    id: "Coordinador de mantenimiento automotriz",
    value: "Coordinador de mantenimiento automotriz"
  },
  {
    id: "Coordinador de mantenimiento de edificios",
    value: "Coordinador de mantenimiento de edificios"
  },
  {
    id: "Coordinador de mantenimiento industrial",
    value: "Coordinador de mantenimiento industrial"
  },
  { id: "Coordinador de mantenimiento", value: "Coordinador de mantenimiento" },
  {
    id: "Coordinador de marketing comercial",
    value: "Coordinador de marketing comercial"
  },
  {
    id: "Coordinador de marketing digital",
    value: "Coordinador de marketing digital"
  },
  { id: "Coordinador de marketing", value: "Coordinador de marketing" },
  { id: "Coordinador de mecanizado", value: "Coordinador de mecanizado" },
  {
    id: "Coordinador de medio ambiente",
    value: "Coordinador de medio ambiente"
  },
  { id: "Coordinador de medios", value: "Coordinador de medios" },
  {
    id: "Coordinador de mejora continua",
    value: "Coordinador de mejora continua"
  },
  {
    id: "Coordinador de merchandising visual",
    value: "Coordinador de merchandising visual"
  },
  {
    id: "Coordinador de mesa de servicio",
    value: "Coordinador de mesa de servicio"
  },
  { id: "Coordinador de negocios", value: "Coordinador de negocios" },
  { id: "Coordinador de nómina", value: "Coordinador de nómina" },
  { id: "Coordinador de obras", value: "Coordinador de obras" },
  { id: "Coordinador de oficina", value: "Coordinador de oficina" },
  { id: "Coordinador de planificación", value: "Coordinador de planificación" },
  { id: "Coordinador de precios", value: "Coordinador de precios" },
  { id: "Coordinador de presupuesto", value: "Coordinador de presupuesto" },
  {
    id: "Coordinador de prevención de pérdidas",
    value: "Coordinador de prevención de pérdidas"
  },
  { id: "Coordinador de procesos", value: "Coordinador de procesos" },
  { id: "Coordinador de producción", value: "Coordinador de producción" },
  { id: "Coordinador de producto", value: "Coordinador de producto" },
  {
    id: "Coordinador de proyectos de construcción",
    value: "Coordinador de proyectos de construcción"
  },
  {
    id: "Coordinador de proyectos sociales",
    value: "Coordinador de proyectos sociales"
  },
  { id: "Coordinador de proyectos", value: "Coordinador de proyectos" },
  { id: "Coordinador de recepción", value: "Coordinador de recepción" },
  {
    id: "Coordinador de reclutamiento y selección",
    value: "Coordinador de reclutamiento y selección"
  },
  {
    id: "Coordinador de recursos humanos",
    value: "Coordinador de recursos humanos"
  },
  { id: "Coordinador de red", value: "Coordinador de red" },
  {
    id: "Coordinador de redes sociales",
    value: "Coordinador de redes sociales"
  },
  { id: "Coordinador de reservas", value: "Coordinador de reservas" },
  {
    id: "Coordinador de responsabilidad social",
    value: "Coordinador de responsabilidad social"
  },
  { id: "Coordinador de restaurante", value: "Coordinador de restaurante" },
  {
    id: "Coordinador de salud, seguridad y medio ambiente",
    value: "Coordinador de salud, seguridad y medio ambiente"
  },
  {
    id: "Coordinador de seguridad de activos",
    value: "Coordinador de seguridad de activos"
  },
  {
    id: "Coordinador de seguridad de la información",
    value: "Coordinador de seguridad de la información"
  },
  {
    id: "Coordinador de seguridad en el trabajo",
    value: "Coordinador de seguridad en el trabajo"
  },
  { id: "Coordinador de seguros", value: "Coordinador de seguros" },
  {
    id: "Coordinador de servicio al cliente",
    value: "Coordinador de servicio al cliente"
  },
  {
    id: "Coordinador de servicios generales",
    value: "Coordinador de servicios generales"
  },
  { id: "Coordinador de siniestros", value: "Coordinador de siniestros" },
  {
    id: "Coordinador de soporte técnico",
    value: "Coordinador de soporte técnico"
  },
  {
    id: "Coordinador de sostenibilidad",
    value: "Coordinador de sostenibilidad"
  },
  { id: "Coordinador de sucursal", value: "Coordinador de sucursal" },
  {
    id: "Coordinador de taller de herramientas",
    value: "Coordinador de taller de herramientas"
  },
  {
    id: "Coordinador de tecnología de la información",
    value: "Coordinador de tecnología de la información"
  },
  {
    id: "Coordinador de telecomunicaciones",
    value: "Coordinador de telecomunicaciones"
  },
  { id: "Coordinador de tesorería", value: "Coordinador de tesorería" },
  { id: "Coordinador de tienda", value: "Coordinador de tienda" },
  { id: "Coordinador de topografía", value: "Coordinador de topografía" },
  {
    id: "Coordinador de trabajo y salario",
    value: "Coordinador de trabajo y salario"
  },
  { id: "Coordinador de transporte", value: "Coordinador de transporte" },
  { id: "Coordinador de ventas", value: "Coordinador de ventas" },
  {
    id: "Coordinador del departamento de personal",
    value: "Coordinador del departamento de personal"
  },
  { id: "Coordinador editorial", value: "Coordinador editorial" },
  { id: "Coordinador electronico", value: "Coordinador electronico" },
  { id: "Coordinador financiero", value: "Coordinador financiero" },
  { id: "Coordinador fiscal", value: "Coordinador fiscal" },
  { id: "Coordinador industrial", value: "Coordinador industrial" },
  { id: "Coordinador inmobiliario", value: "Coordinador inmobiliario" },
  { id: "Coordinador jurídico", value: "Coordinador jurídico" },
  { id: "Coordinador logístico", value: "Coordinador logístico" },
  { id: "Coordinador médico", value: "Coordinador médico" },
  { id: "Coordinador operativo", value: "Coordinador operativo" },
  { id: "Coordinador pcp", value: "Coordinador pcp" },
  { id: "Coordinador pedagógico", value: "Coordinador pedagógico" },
  { id: "Coordinador postventa", value: "Coordinador postventa" },
  { id: "Coordinadora de eventos", value: "Coordinadora de eventos" },
  { id: "Copero", value: "Copero" },
  { id: "Copiadora de placas", value: "Copiadora de placas" },
  { id: "Coreógrafo", value: "Coreógrafo" },
  { id: "Corredor de bienes raíces", value: "Corredor de bienes raíces" },
  { id: "Corredor de seguros", value: "Corredor de seguros" },
  { id: "Cortador de gemas", value: "Cortador de gemas" },
  { id: "Cortador de telas", value: "Cortador de telas" },
  { id: "Cortador", value: "Cortador" },
  { id: "Cortavidrios", value: "Cortavidrios" },
  { id: "Cosmetólogo", value: "Cosmetólogo" },
  { id: "Cronoanalista", value: "Cronoanalista" },
  { id: "Cuidador de ancianos", value: "Cuidador de ancianos" },
  { id: "Cuidador de niños", value: "Cuidador de niños" },
  { id: "Cumim", value: "Cumim" },
  { id: "Curador de arte", value: "Curador de arte" },
  { id: "DJ", value: "DJ" },
  { id: "Dba", value: "Dba" },
  { id: "Decorador", value: "Decorador" },
  { id: "Delineante de presupuesto", value: "Delineante de presupuesto" },
  { id: "Dentista de implantes", value: "Dentista de implantes" },
  { id: "Dentista endodoncista", value: "Dentista endodoncista" },
  { id: "Dentista estomatólogo", value: "Dentista estomatólogo" },
  { id: "Dentista ortodoncista", value: "Dentista ortodoncista" },
  { id: "Dentista periodoncista", value: "Dentista periodoncista" },
  { id: "Dentista protésico", value: "Dentista protésico" },
  { id: "Dentista traumatólogo", value: "Dentista traumatólogo" },
  { id: "Dentista", value: "Dentista" },
  { id: "Departamento personal", value: "Departamento personal" },
  { id: "Depilador", value: "Depilador" },
  { id: "Depurador", value: "Depurador" },
  {
    id: "Derma fisioterapeuta funcional",
    value: "Derma fisioterapeuta funcional"
  },
  { id: "Dermoconsultor", value: "Dermoconsultor" },
  { id: "Desarrollador web", value: "Desarrollador web" },
  {
    id: "Despachador operativo de vuelo",
    value: "Despachador operativo de vuelo"
  },
  { id: "Despachante de aduanas", value: "Despachante de aduanas" },
  { id: "Despensa", value: "Despensa" },
  { id: "Diagramador", value: "Diagramador" },
  { id: "Dibujante copista", value: "Dibujante copista" },
  { id: "Dibujante de diseño civil", value: "Dibujante de diseño civil" },
  { id: "Dibujante diseñador mecánico", value: "Dibujante diseñador mecánico" },
  { id: "Dibujante electricista", value: "Dibujante electricista" },
  { id: "Difusor", value: "Difusor" },
  { id: "Digitalizador", value: "Digitalizador" },
  { id: "Diligenciador", value: "Diligenciador" },
  { id: "Director administrativo", value: "Director administrativo" },
  { id: "Director comercial", value: "Director comercial" },
  { id: "Director de arte", value: "Director de arte" },
  {
    id: "Director de asuntos regulatorios",
    value: "Director de asuntos regulatorios"
  },
  { id: "Director de cine", value: "Director de cine" },
  { id: "Director de compras", value: "Director de compras" },
  { id: "Director de creación", value: "Director de creación" },
  { id: "Director de hospital", value: "Director de hospital" },
  { id: "Director de limpieza", value: "Director de limpieza" },
  { id: "Director de logística", value: "Director de logística" },
  { id: "Director de marketing", value: "Director de marketing" },
  { id: "Director de negocios", value: "Director de negocios" },
  { id: "Director de obra", value: "Director de obra" },
  { id: "Director de operaciones", value: "Director de operaciones" },
  { id: "Director de recursos humanos", value: "Director de recursos humanos" },
  { id: "Director de teatro", value: "Director de teatro" },
  {
    id: "Director de tecnología de la información",
    value: "Director de tecnología de la información"
  },
  { id: "Director de ventas", value: "Director de ventas" },
  { id: "Director financiero", value: "Director financiero" },
  { id: "Director general", value: "Director general" },
  { id: "Director industrial", value: "Director industrial" },
  { id: "Director legal", value: "Director legal" },
  { id: "Director pedagógico", value: "Director pedagógico" },
  { id: "Diseñador 3d", value: "Diseñador 3d" },
  { id: "Diseñador cadista", value: "Diseñador cadista" },
  { id: "Diseñador civil", value: "Diseñador civil" },
  { id: "Diseñador de accesorios", value: "Diseñador de accesorios" },
  {
    id: "Diseñador de aire acondicionado",
    value: "Diseñador de aire acondicionado"
  },
  { id: "Diseñador de automatización", value: "Diseñador de automatización" },
  { id: "Diseñador de cejas", value: "Diseñador de cejas" },
  { id: "Diseñador de envases", value: "Diseñador de envases" },
  {
    id: "Diseñador de estructuras metálicas",
    value: "Diseñador de estructuras metálicas"
  },
  { id: "Diseñador de herramientas", value: "Diseñador de herramientas" },
  { id: "Diseñador de iluminación", value: "Diseñador de iluminación" },
  { id: "Diseñador de instalaciones", value: "Diseñador de instalaciones" },
  { id: "Diseñador de interacción", value: "Diseñador de interacción" },
  { id: "Diseñador de interfaz", value: "Diseñador de interfaz" },
  { id: "Diseñador de interiores", value: "Diseñador de interiores" },
  { id: "Diseñador de joyas", value: "Diseñador de joyas" },
  { id: "Diseñador de juegos", value: "Diseñador de juegos" },
  { id: "Diseñador de moda", value: "Diseñador de moda" },
  { id: "Diseñador de moldes", value: "Diseñador de moldes" },
  { id: "Diseñador de muebles", value: "Diseñador de muebles" },
  { id: "Diseñador de máquinas", value: "Diseñador de máquinas" },
  { id: "Diseñador de productos", value: "Diseñador de productos" },
  { id: "Diseñador de proyectos", value: "Diseñador de proyectos" },
  {
    id: "Diseñador de telecomunicaciones",
    value: "Diseñador de telecomunicaciones"
  },
  { id: "Diseñador de vestuario", value: "Diseñador de vestuario" },
  { id: "Diseñador de zapatos", value: "Diseñador de zapatos" },
  { id: "Diseñador digital", value: "Diseñador digital" },
  {
    id: "Diseñador diseñador hidráulico",
    value: "Diseñador diseñador hidráulico"
  },
  { id: "Diseñador electrónico", value: "Diseñador electrónico" },
  { id: "Diseñador eléctrico", value: "Diseñador eléctrico" },
  { id: "Diseñador gráfico", value: "Diseñador gráfico" },
  { id: "Diseñador hidráulico", value: "Diseñador hidráulico" },
  { id: "Diseñador industrial", value: "Diseñador industrial" },
  { id: "Diseñador instruccional", value: "Diseñador instruccional" },
  { id: "Diseñador mecanico", value: "Diseñador mecanico" },
  { id: "Diseñador mecánico", value: "Diseñador mecánico" },
  { id: "Diseñador multimedia", value: "Diseñador multimedia" },
  { id: "Diseñador textil", value: "Diseñador textil" },
  { id: "Diseñador técnico", value: "Diseñador técnico" },
  { id: "Diseñador web en prácticas", value: "Diseñador web en prácticas" },
  { id: "Diseñador web", value: "Diseñador web" },
  { id: "Diseñador", value: "Diseñador" },
  { id: "Diseño web", value: "Diseño web" },
  { id: "Diseño", value: "Diseño" },
  { id: "Disidente", value: "Disidente" },
  { id: "Distribuidor", value: "Distribuidor" },
  { id: "Documentador", value: "Documentador" },
  { id: "Doméstico", value: "Doméstico" },
  { id: "Dueño", value: "Dueño" },
  { id: "Duteiro", value: "Duteiro" },
  { id: "Ecologista", value: "Ecologista" },
  { id: "Economista", value: "Economista" },
  { id: "Editor de imagen", value: "Editor de imagen" },
  { id: "Editor de moda", value: "Editor de moda" },
  { id: "Editor de texto", value: "Editor de texto" },
  { id: "Editor de vídeo", value: "Editor de vídeo" },
  { id: "Editor web", value: "Editor web" },
  { id: "Editor", value: "Editor" },
  { id: "Educador ambiental", value: "Educador ambiental" },
  { id: "Educador de negocios", value: "Educador de negocios" },
  { id: "Educador social", value: "Educador social" },
  { id: "Ejecutivo de cuentas", value: "Ejecutivo de cuentas" },
  { id: "Ejecutivo de ventas", value: "Ejecutivo de ventas" },
  {
    id: "El señor que estaciona los autos",
    value: "El señor que estaciona los autos"
  },
  {
    id: "Electricista de aire acondicionado",
    value: "Electricista de aire acondicionado"
  },
  { id: "Electricista de edificios", value: "Electricista de edificios" },
  {
    id: "Electricista de fuerza y ​​control",
    value: "Electricista de fuerza y ​​control"
  },
  { id: "Electricista de montacargas", value: "Electricista de montacargas" },
  { id: "Electricista de montaje", value: "Electricista de montaje" },
  { id: "Electricista de vehículos", value: "Electricista de vehículos" },
  { id: "Electricista electronico", value: "Electricista electronico" },
  { id: "Electricista industrial", value: "Electricista industrial" },
  {
    id: "Electricista instalador de paneles",
    value: "Electricista instalador de paneles"
  },
  { id: "Electricista instalador", value: "Electricista instalador" },
  { id: "Electricista", value: "Electricista" },
  { id: "Electromecánico", value: "Electromecánico" },
  { id: "Electrotécnico", value: "Electrotécnico" },
  { id: "Embriólogo", value: "Embriólogo" },
  { id: "Emisor de ctrc", value: "Emisor de ctrc" },
  { id: "Emisor de pases", value: "Emisor de pases" },
  { id: "Empleado de cambio de moneda", value: "Empleado de cambio de moneda" },
  { id: "Empleado de hospital", value: "Empleado de hospital" },
  { id: "Empleado de marketing", value: "Empleado de marketing" },
  { id: "Empleado de oficina", value: "Empleado de oficina" },
  { id: "Empleado de tesorería", value: "Empleado de tesorería" },
  { id: "Encapsulador", value: "Encapsulador" },
  { id: "Encargado de eventos", value: "Encargado de eventos" },
  {
    id: "Encargado de inyección de plástico",
    value: "Encargado de inyección de plástico"
  },
  { id: "Encargado de perecederos", value: "Encargado de perecederos" },
  { id: "Encargado de sonar", value: "Encargado de sonar" },
  { id: "Encargado de tienda", value: "Encargado de tienda" },
  { id: "Encimera", value: "Encimera" },
  { id: "Encuadernador", value: "Encuadernador" },
  { id: "Enfermera auditora", value: "Enfermera auditora" },
  { id: "Enfermera científica", value: "Enfermera científica" },
  { id: "Enfermera cíclica", value: "Enfermera cíclica" },
  {
    id: "Enfermera de educación continua",
    value: "Enfermera de educación continua"
  },
  { id: "Enfermera de la uCI", value: "Enfermera de la uCI" },
  { id: "Enfermera de quirófano", value: "Enfermera de quirófano" },
  { id: "Enfermera del trabajo", value: "Enfermera del trabajo" },
  { id: "Enfermera estomaterapeuta", value: "Enfermera estomaterapeuta" },
  { id: "Enfermera nefróloga", value: "Enfermera nefróloga" },
  { id: "Enfermera partera", value: "Enfermera partera" },
  { id: "Enfermera visitante", value: "Enfermera visitante" },
  { id: "Enfermero", value: "Enfermero" },
  { id: "Enfestador", value: "Enfestador" },
  { id: "Enredadera", value: "Enredadera" },
  { id: "Ensamblador mecánico", value: "Ensamblador mecánico" },
  { id: "Ensamblador semioficial", value: "Ensamblador semioficial" },
  { id: "Ensamblador", value: "Ensamblador" },
  { id: "Entrenador de calidad", value: "Entrenador de calidad" },
  { id: "Entrenador de excel", value: "Entrenador de excel" },
  { id: "Entrenador personal", value: "Entrenador personal" },
  { id: "Entrenador", value: "Entrenador" },
  { id: "Envasador", value: "Envasador" },
  { id: "Ergonomista", value: "Ergonomista" },
  { id: "Escalador industrial", value: "Escalador industrial" },
  { id: "Escaparate", value: "Escaparate" },
  {
    id: "Escenario en las artes escénicas",
    value: "Escenario en las artes escénicas"
  },
  { id: "Escenógrafo", value: "Escenógrafo" },
  { id: "Escriba", value: "Escriba" },
  { id: "Especialista contable", value: "Especialista contable" },
  { id: "Especialista de compras", value: "Especialista de compras" },
  {
    id: "Especialista en comercio electrónico",
    value: "Especialista en comercio electrónico"
  },
  {
    id: "Especialista en formación y desarrollo",
    value: "Especialista en formación y desarrollo"
  },
  { id: "Especialista en impuestos", value: "Especialista en impuestos" },
  {
    id: "Especialista en marketing digital",
    value: "Especialista en marketing digital"
  },
  { id: "Especialista en marketing", value: "Especialista en marketing" },
  { id: "Especialista en medios", value: "Especialista en medios" },
  { id: "Especialista en productos", value: "Especialista en productos" },
  {
    id: "Especialista en reclutamiento y selección",
    value: "Especialista en reclutamiento y selección"
  },
  {
    id: "Especialista en recursos humanos",
    value: "Especialista en recursos humanos"
  },
  {
    id: "Especialista en seguridad de la información",
    value: "Especialista en seguridad de la información"
  },
  { id: "Especialista en suministros", value: "Especialista en suministros" },
  {
    id: "Especialista en telecomunicaciones",
    value: "Especialista en telecomunicaciones"
  },
  { id: "Especialista en tesorería", value: "Especialista en tesorería" },
  { id: "Especialista financiero", value: "Especialista financiero" },
  { id: "Estadístico", value: "Estadístico" },
  { id: "Estilista personal", value: "Estilista personal" },
  { id: "Estilista", value: "Estilista" },
  { id: "Etiquetador", value: "Etiquetador" },
  { id: "Experto en impuestos", value: "Experto en impuestos" },
  { id: "Experto legal", value: "Experto legal" },
  { id: "Explorador", value: "Explorador" },
  { id: "Extrusora", value: "Extrusora" },
  { id: "Fabricante de crepes", value: "Fabricante de crepes" },
  { id: "Fabricante de helados", value: "Fabricante de helados" },
  {
    id: "Fabricante de herramientas de moldes de plástico",
    value: "Fabricante de herramientas de moldes de plástico"
  },
  { id: "Fabricante de herramientas", value: "Fabricante de herramientas" },
  { id: "Fabricante de modelos", value: "Fabricante de modelos" },
  { id: "Fabricante de máquinas", value: "Fabricante de máquinas" },
  { id: "Fabricante de papel tapiz", value: "Fabricante de papel tapiz" },
  { id: "Fabricante de pinturas", value: "Fabricante de pinturas" },
  { id: "Factura hospitalaria", value: "Factura hospitalaria" },
  { id: "Factura", value: "Factura" },
  { id: "Farmacéutico bioquímico", value: "Farmacéutico bioquímico" },
  { id: "Farmacéutico en prácticas", value: "Farmacéutico en prácticas" },
  { id: "Farmacéutico homeopático", value: "Farmacéutico homeopático" },
  { id: "Farmacéutico hospitalario", value: "Farmacéutico hospitalario" },
  { id: "Farmacéutico industrial", value: "Farmacéutico industrial" },
  { id: "Farmacéutico", value: "Farmacéutico" },
  { id: "Fideicomisario", value: "Fideicomisario" },
  { id: "Fisioterapeuta acupunturista", value: "Fisioterapeuta acupunturista" },
  {
    id: "Fisioterapeuta de atención domiciliaria",
    value: "Fisioterapeuta de atención domiciliaria"
  },
  { id: "Fisioterapeuta de ventas", value: "Fisioterapeuta de ventas" },
  { id: "Fisioterapeuta del trabajo", value: "Fisioterapeuta del trabajo" },
  { id: "Fisioterapeuta deportivo", value: "Fisioterapeuta deportivo" },
  { id: "Fisioterapeuta hospitalario", value: "Fisioterapeuta hospitalario" },
  {
    id: "Fisioterapeuta neurofuncional",
    value: "Fisioterapeuta neurofuncional"
  },
  { id: "Fisioterapeuta oncofuncional", value: "Fisioterapeuta oncofuncional" },
  { id: "Fisioterapeuta respiratorio", value: "Fisioterapeuta respiratorio" },
  { id: "Fisioterapeuta rpg", value: "Fisioterapeuta rpg" },
  { id: "Fisioterapeuta", value: "Fisioterapeuta" },
  { id: "Florista", value: "Florista" },
  { id: "Fotograbadora", value: "Fotograbadora" },
  { id: "Fotógrafo", value: "Fotógrafo" },
  { id: "Fresado medio oficial", value: "Fresado medio oficial" },
  { id: "Fresadora cnc oficial", value: "Fresadora cnc oficial" },
  { id: "Fresadora cnc", value: "Fresadora cnc" },
  { id: "Fundidor de metales", value: "Fundidor de metales" },
  { id: "Físico", value: "Físico" },
  { id: "Galvanizador", value: "Galvanizador" },
  { id: "Garante del vehículo", value: "Garante del vehículo" },
  { id: "Garante", value: "Garante" },
  { id: "Gastrónomo", value: "Gastrónomo" },
  { id: "Gemólogo", value: "Gemólogo" },
  { id: "Geofísico", value: "Geofísico" },
  { id: "Gerente administrativo", value: "Gerente administrativo" },
  { id: "Gerente agrícola", value: "Gerente agrícola" },
  { id: "Gerente comercial", value: "Gerente comercial" },
  { id: "Gerente de arrendamiento", value: "Gerente de arrendamiento" },
  {
    id: "Gerente de asistencia técnica",
    value: "Gerente de asistencia técnica"
  },
  { id: "Gerente de auditoría", value: "Gerente de auditoría" },
  { id: "Gerente de banco", value: "Gerente de banco" },
  { id: "Gerente de bienes raíces", value: "Gerente de bienes raíces" },
  { id: "Gerente de calidad", value: "Gerente de calidad" },
  { id: "Gerente de canal", value: "Gerente de canal" },
  { id: "Gerente de comercialización", value: "Gerente de comercialización" },
  { id: "Gerente de comercio exterior", value: "Gerente de comercio exterior" },
  { id: "Gerente de comida y bebida", value: "Gerente de comida y bebida" },
  { id: "Gerente de compras", value: "Gerente de compras" },
  { id: "Gerente de comunicación", value: "Gerente de comunicación" },
  { id: "Gerente de construcción", value: "Gerente de construcción" },
  { id: "Gerente de contabilidad", value: "Gerente de contabilidad" },
  { id: "Gerente de contraloría", value: "Gerente de contraloría" },
  {
    id: "Gerente de control de calidad",
    value: "Gerente de control de calidad"
  },
  {
    id: "Gerente de crédito y cobranza",
    value: "Gerente de crédito y cobranza"
  },
  { id: "Gerente de cuentas", value: "Gerente de cuentas" },
  { id: "Gerente de cumplimiento", value: "Gerente de cumplimiento" },
  { id: "Gerente de despacho", value: "Gerente de despacho" },
  { id: "Gerente de enfermería", value: "Gerente de enfermería" },
  { id: "Gerente de fabricación", value: "Gerente de fabricación" },
  { id: "Gerente de factoraje", value: "Gerente de factoraje" },
  { id: "Gerente de facturación", value: "Gerente de facturación" },
  { id: "Gerente de farmacia", value: "Gerente de farmacia" },
  { id: "Gerente de finanzas", value: "Gerente de finanzas" },
  {
    id: "Gerente de formación y desarrollo",
    value: "Gerente de formación y desarrollo"
  },
  {
    id: "Gerente de fusiones y adquisiciones",
    value: "Gerente de fusiones y adquisiciones"
  },
  { id: "Gerente de impresión", value: "Gerente de impresión" },
  { id: "Gerente de impuestos", value: "Gerente de impuestos" },
  { id: "Gerente de ingeniería", value: "Gerente de ingeniería" },
  {
    id: "Gerente de inteligencia de mercado",
    value: "Gerente de inteligencia de mercado"
  },
  { id: "Gerente de inventario", value: "Gerente de inventario" },
  {
    id: "Gerente de investigación y desarrollo",
    value: "Gerente de investigación y desarrollo"
  },
  { id: "Gerente de la flota", value: "Gerente de la flota" },
  { id: "Gerente de la instalación", value: "Gerente de la instalación" },
  { id: "Gerente de la tienda", value: "Gerente de la tienda" },
  { id: "Gerente de las instalaciones", value: "Gerente de las instalaciones" },
  { id: "Gerente de lavandería", value: "Gerente de lavandería" },
  { id: "Gerente de licitación", value: "Gerente de licitación" },
  { id: "Gerente de logistica", value: "Gerente de logistica" },
  {
    id: "Gerente de mantenimiento de edificios",
    value: "Gerente de mantenimiento de edificios"
  },
  {
    id: "Gerente de mantenimiento industrial",
    value: "Gerente de mantenimiento industrial"
  },
  {
    id: "Gerente de mantenimiento mecánico",
    value: "Gerente de mantenimiento mecánico"
  },
  { id: "Gerente de mantenimiento", value: "Gerente de mantenimiento" },
  { id: "Gerente de marketing digital", value: "Gerente de marketing digital" },
  { id: "Gerente de marketing", value: "Gerente de marketing" },
  { id: "Gerente de medio ambiente", value: "Gerente de medio ambiente" },
  { id: "Gerente de moda", value: "Gerente de moda" },
  { id: "Gerente de negocios", value: "Gerente de negocios" },
  { id: "Gerente de nutrición", value: "Gerente de nutrición" },
  { id: "Gerente de operaciones", value: "Gerente de operaciones" },
  { id: "Gerente de panadería", value: "Gerente de panadería" },
  { id: "Gerente de patio", value: "Gerente de patio" },
  { id: "Gerente de pescadería", value: "Gerente de pescadería" },
  { id: "Gerente de piezas", value: "Gerente de piezas" },
  { id: "Gerente de planificación", value: "Gerente de planificación" },
  { id: "Gerente de posventa", value: "Gerente de posventa" },
  { id: "Gerente de presupuesto", value: "Gerente de presupuesto" },
  {
    id: "Gerente de prevención de pérdidas",
    value: "Gerente de prevención de pérdidas"
  },
  { id: "Gerente de producción", value: "Gerente de producción" },
  { id: "Gerente de producto", value: "Gerente de producto" },
  { id: "Gerente de proyecto pmo", value: "Gerente de proyecto pmo" },
  { id: "Gerente de proyecto", value: "Gerente de proyecto" },
  { id: "Gerente de proyectos", value: "Gerente de proyectos" },
  { id: "Gerente de qsms", value: "Gerente de qsms" },
  { id: "Gerente de recepción", value: "Gerente de recepción" },
  {
    id: "Gerente de reclutamiento y selección",
    value: "Gerente de reclutamiento y selección"
  },
  { id: "Gerente de recursos humanos", value: "Gerente de recursos humanos" },
  {
    id: "Gerente de responsabilidad social",
    value: "Gerente de responsabilidad social"
  },
  {
    id: "Gerente de seguridad de la información",
    value: "Gerente de seguridad de la información"
  },
  {
    id: "Gerente de seguridad ocupacional",
    value: "Gerente de seguridad ocupacional"
  },
  {
    id: "Gerente de seguridad, salud y medio ambiente",
    value: "Gerente de seguridad, salud y medio ambiente"
  },
  { id: "Gerente de seguros", value: "Gerente de seguros" },
  { id: "Gerente de sem", value: "Gerente de sem" },
  { id: "Gerente de seo", value: "Gerente de seo" },
  { id: "Gerente de servicios", value: "Gerente de servicios" },
  { id: "Gerente de soporte técnico", value: "Gerente de soporte técnico" },
  { id: "Gerente de subsidiaria", value: "Gerente de subsidiaria" },
  { id: "Gerente de suministros", value: "Gerente de suministros" },
  { id: "Gerente de tI", value: "Gerente de tI" },
  { id: "Gerente de taller", value: "Gerente de taller" },
  {
    id: "Gerente de telecomunicaciones",
    value: "Gerente de telecomunicaciones"
  },
  { id: "Gerente de tesorería", value: "Gerente de tesorería" },
  { id: "Gerente de tienda", value: "Gerente de tienda" },
  { id: "Gerente de transporte", value: "Gerente de transporte" },
  { id: "Gerente de turismo", value: "Gerente de turismo" },
  { id: "Gerente de ventas", value: "Gerente de ventas" },
  { id: "Gerente del aeropuerto", value: "Gerente del aeropuerto" },
  {
    id: "Gerente del departamento de personal",
    value: "Gerente del departamento de personal"
  },
  { id: "Gerente del hotel", value: "Gerente del hotel" },
  { id: "Gerente del restaurante", value: "Gerente del restaurante" },
  { id: "Gerente general", value: "Gerente general" },
  { id: "Gerente industrial", value: "Gerente industrial" },
  { id: "Gerente medico", value: "Gerente medico" },
  { id: "Gerente pcp", value: "Gerente pcp" },
  { id: "Gerente técnico", value: "Gerente técnico" },
  { id: "Gerontólogo", value: "Gerontólogo" },
  {
    id: "Gestor de comercio electrónico",
    value: "Gestor de comercio electrónico"
  },
  { id: "Gestor de contenidos", value: "Gestor de contenidos" },
  {
    id: "Gestor de crédito inmobiliario",
    value: "Gestor de crédito inmobiliario"
  },
  { id: "Gestor de inversiones", value: "Gestor de inversiones" },
  { id: "Gestor de procesos", value: "Gestor de procesos" },
  { id: "Gestor de proyectos web", value: "Gestor de proyectos web" },
  { id: "Gestor de reclamaciones", value: "Gestor de reclamaciones" },
  { id: "Gestor de reservas", value: "Gestor de reservas" },
  {
    id: "Gestor de seguridad de activos",
    value: "Gestor de seguridad de activos"
  },
  { id: "Gestor pedagógico", value: "Gestor pedagógico" },
  { id: "Geógrafo", value: "Geógrafo" },
  { id: "Geólogo", value: "Geólogo" },
  { id: "Gitano", value: "Gitano" },
  { id: "Goma", value: "Goma" },
  { id: "Greidista", value: "Greidista" },
  { id: "Guardacoches", value: "Guardacoches" },
  { id: "Guardavía", value: "Guardavía" },
  { id: "Guardería", value: "Guardería" },
  { id: "Guardia de escolta", value: "Guardia de escolta" },
  { id: "Guionista", value: "Guionista" },
  { id: "Guía de turismo", value: "Guía de turismo" },
  { id: "Hecho en casa", value: "Hecho en casa" },
  { id: "Herrero", value: "Herrero" },
  { id: "Historiador", value: "Historiador" },
  { id: "Hondero", value: "Hondero" },
  { id: "Horno", value: "Horno" },
  { id: "Ilustrador", value: "Ilustrador" },
  { id: "Impermeabilización", value: "Impermeabilización" },
  { id: "Implementador de sistemas", value: "Implementador de sistemas" },
  { id: "Impresora compensada", value: "Impresora compensada" },
  { id: "Impresora de huecograbado", value: "Impresora de huecograbado" },
  { id: "Impresora de pantalla", value: "Impresora de pantalla" },
  { id: "Impresora digital", value: "Impresora digital" },
  { id: "Impresora flexográfica", value: "Impresora flexográfica" },
  { id: "Impresora tamográfica", value: "Impresora tamográfica" },
  { id: "Impresora", value: "Impresora" },
  {
    id: "Impuesto de prevención de pérdidas",
    value: "Impuesto de prevención de pérdidas"
  },
  { id: "Impuesto en efectivo", value: "Impuesto en efectivo" },
  { id: "Informe analista", value: "Informe analista" },
  { id: "Ingeniero aeronáutico", value: "Ingeniero aeronáutico" },
  { id: "Ingeniero agricultor", value: "Ingeniero agricultor" },
  { id: "Ingeniero agrónomo", value: "Ingeniero agrónomo" },
  { id: "Ingeniero automotriz", value: "Ingeniero automotriz" },
  { id: "Ingeniero biomédico", value: "Ingeniero biomédico" },
  { id: "Ingeniero cartógrafo", value: "Ingeniero cartógrafo" },
  { id: "Ingeniero civil", value: "Ingeniero civil" },
  { id: "Ingeniero clínico", value: "Ingeniero clínico" },
  { id: "Ingeniero coordinador", value: "Ingeniero coordinador" },
  { id: "Ingeniero de agua", value: "Ingeniero de agua" },
  {
    id: "Ingeniero de aire acondicionado",
    value: "Ingeniero de aire acondicionado"
  },
  { id: "Ingeniero de aplicaciones", value: "Ingeniero de aplicaciones" },
  { id: "Ingeniero de calidad", value: "Ingeniero de calidad" },
  { id: "Ingeniero de caminos", value: "Ingeniero de caminos" },
  { id: "Ingeniero de campo", value: "Ingeniero de campo" },
  { id: "Ingeniero de compras", value: "Ingeniero de compras" },
  {
    id: "Ingeniero de control y automatización",
    value: "Ingeniero de control y automatización"
  },
  { id: "Ingeniero de costos", value: "Ingeniero de costos" },
  { id: "Ingeniero de cálculo", value: "Ingeniero de cálculo" },
  { id: "Ingeniero de instalaciones", value: "Ingeniero de instalaciones" },
  { id: "Ingeniero de instrumentación", value: "Ingeniero de instrumentación" },
  {
    id: "Ingeniero de mantenimiento de edificios",
    value: "Ingeniero de mantenimiento de edificios"
  },
  { id: "Ingeniero de mantenimiento", value: "Ingeniero de mantenimiento" },
  { id: "Ingeniero de manufactura", value: "Ingeniero de manufactura" },
  { id: "Ingeniero de materiales", value: "Ingeniero de materiales" },
  { id: "Ingeniero de minas", value: "Ingeniero de minas" },
  { id: "Ingeniero de pesca", value: "Ingeniero de pesca" },
  { id: "Ingeniero de petróleo", value: "Ingeniero de petróleo" },
  { id: "Ingeniero de planificacion", value: "Ingeniero de planificacion" },
  { id: "Ingeniero de procesos", value: "Ingeniero de procesos" },
  { id: "Ingeniero de producción", value: "Ingeniero de producción" },
  { id: "Ingeniero de producto", value: "Ingeniero de producto" },
  { id: "Ingeniero de proyectos", value: "Ingeniero de proyectos" },
  { id: "Ingeniero de pruebas", value: "Ingeniero de pruebas" },
  {
    id: "Ingeniero de seguridad en el trabajo",
    value: "Ingeniero de seguridad en el trabajo"
  },
  {
    id: "Ingeniero de servicio técnico",
    value: "Ingeniero de servicio técnico"
  },
  { id: "Ingeniero de servicio", value: "Ingeniero de servicio" },
  { id: "Ingeniero de sistemas", value: "Ingeniero de sistemas" },
  { id: "Ingeniero de software", value: "Ingeniero de software" },
  { id: "Ingeniero de suministros", value: "Ingeniero de suministros" },
  {
    id: "Ingeniero de telecomunicaciones",
    value: "Ingeniero de telecomunicaciones"
  },
  { id: "Ingeniero de ventas", value: "Ingeniero de ventas" },
  { id: "Ingeniero electricista", value: "Ingeniero electricista" },
  { id: "Ingeniero electrónico", value: "Ingeniero electrónico" },
  { id: "Ingeniero en alimentos", value: "Ingeniero en alimentos" },
  {
    id: "Ingeniero en automatización industrial",
    value: "Ingeniero en automatización industrial"
  },
  { id: "Ingeniero ferroviario", value: "Ingeniero ferroviario" },
  { id: "Ingeniero forestal", value: "Ingeniero forestal" },
  { id: "Ingeniero geotécnico", value: "Ingeniero geotécnico" },
  { id: "Ingeniero hidráulico", value: "Ingeniero hidráulico" },
  { id: "Ingeniero industrial", value: "Ingeniero industrial" },
  { id: "Ingeniero informático", value: "Ingeniero informático" },
  { id: "Ingeniero mecatrónico", value: "Ingeniero mecatrónico" },
  { id: "Ingeniero mecánico", value: "Ingeniero mecánico" },
  { id: "Ingeniero metalúrgico", value: "Ingeniero metalúrgico" },
  { id: "Ingeniero naval", value: "Ingeniero naval" },
  { id: "Ingeniero presupuestario", value: "Ingeniero presupuestario" },
  { id: "Ingeniero químico", value: "Ingeniero químico" },
  { id: "Ingeniero sanitario", value: "Ingeniero sanitario" },
  { id: "Ingeniero textil", value: "Ingeniero textil" },
  { id: "Ingeniero topógrafo", value: "Ingeniero topógrafo" },
  { id: "Ingeñiero ambiental", value: "Ingeñiero ambiental" },
  { id: "Inspector de calidad", value: "Inspector de calidad" },
  { id: "Inspector de campo", value: "Inspector de campo" },
  { id: "Inspector de construcciones", value: "Inspector de construcciones" },
  { id: "Inspector de equipos", value: "Inspector de equipos" },
  { id: "Inspector de estudiantes", value: "Inspector de estudiantes" },
  { id: "Inspector de fabricación", value: "Inspector de fabricación" },
  { id: "Inspector de flota", value: "Inspector de flota" },
  { id: "Inspector de pintura", value: "Inspector de pintura" },
  { id: "Inspector de riesgos", value: "Inspector de riesgos" },
  { id: "Inspector de seguridad", value: "Inspector de seguridad" },
  { id: "Inspector de soldadura", value: "Inspector de soldadura" },
  { id: "Inspector de tráfico", value: "Inspector de tráfico" },
  { id: "Inspector de vehiculos", value: "Inspector de vehiculos" },
  { id: "Instalador de accesorios", value: "Instalador de accesorios" },
  {
    id: "Instalador de aire acondicionado",
    value: "Instalador de aire acondicionado"
  },
  { id: "Instalador de alarmas", value: "Instalador de alarmas" },
  {
    id: "Instalador de comunicación visual",
    value: "Instalador de comunicación visual"
  },
  {
    id: "Instalador de equipos electrónicos",
    value: "Instalador de equipos electrónicos"
  },
  { id: "Instalador de insultfilm", value: "Instalador de insultfilm" },
  {
    id: "Instalador de rastreador de vehículos",
    value: "Instalador de rastreador de vehículos"
  },
  {
    id: "Instalador de telecomunicaciones",
    value: "Instalador de telecomunicaciones"
  },
  {
    id: "Instalador de televisión por cable",
    value: "Instalador de televisión por cable"
  },
  { id: "Instalador de ventanas", value: "Instalador de ventanas" },
  {
    id: "Instalador de vidrios automotrices",
    value: "Instalador de vidrios automotrices"
  },
  { id: "Instalador", value: "Instalador" },
  { id: "Instructor de autoescuela", value: "Instructor de autoescuela" },
  { id: "Instructor de aviación", value: "Instructor de aviación" },
  { id: "Instructor de conductores", value: "Instructor de conductores" },
  { id: "Instructor de formación", value: "Instructor de formación" },
  { id: "Instructor de peluquería", value: "Instructor de peluquería" },
  { id: "Instructor del curso", value: "Instructor del curso" },
  { id: "Instructora de pilates", value: "Instructora de pilates" },
  { id: "Instrumentación quirúrgica", value: "Instrumentación quirúrgica" },
  { id: "Instrumentista", value: "Instrumentista" },
  { id: "Internado en medicina", value: "Internado en medicina" },
  { id: "Intérprete", value: "Intérprete" },
  { id: "Investigacion", value: "Investigacion" },
  { id: "Investigador de mercado", value: "Investigador de mercado" },
  { id: "Investigador", value: "Investigador" },
  { id: "Jardinero", value: "Jardinero" },
  { id: "Jatista", value: "Jatista" },
  { id: "Jefe de almacén", value: "Jefe de almacén" },
  { id: "Jefe de cocina", value: "Jefe de cocina" },
  { id: "Jefe de costura", value: "Jefe de costura" },
  { id: "Jefe de despacho", value: "Jefe de despacho" },
  { id: "Jefe de enfermeras", value: "Jefe de enfermeras" },
  { id: "Jefe de producción", value: "Jefe de producción" },
  { id: "Jefe de recepción", value: "Jefe de recepción" },
  { id: "Jefe de servicio médico", value: "Jefe de servicio médico" },
  {
    id: "Jefe del departamento de personal",
    value: "Jefe del departamento de personal"
  },
  { id: "Laboratorio fotográfico", value: "Laboratorio fotográfico" },
  { id: "Lacador", value: "Lacador" },
  { id: "Lactarista", value: "Lactarista" },
  { id: "Laminador", value: "Laminador" },
  { id: "Lavado de autos", value: "Lavado de autos" },
  { id: "Lavadora", value: "Lavadora" },
  { id: "Lavandero", value: "Lavandero" },
  { id: "Lector", value: "Lector" },
  { id: "Letrista", value: "Letrista" },
  { id: "Lijadora", value: "Lijadora" },
  { id: "Limpiador de ventanas", value: "Limpiador de ventanas" },
  { id: "Limpiador", value: "Limpiador" },
  { id: "Llavero", value: "Llavero" },
  { id: "Locutor", value: "Locutor" },
  { id: "Lubricador", value: "Lubricador" },
  { id: "Líder comercial", value: "Líder comercial" },
  { id: "Líder de caja", value: "Líder de caja" },
  { id: "Líder de colección", value: "Líder de colección" },
  { id: "Líder de comercialización", value: "Líder de comercialización" },
  { id: "Líder de conserjería", value: "Líder de conserjería" },
  { id: "Líder de control de calidad", value: "Líder de control de calidad" },
  { id: "Líder de equipo", value: "Líder de equipo" },
  { id: "Líder de expedición", value: "Líder de expedición" },
  { id: "Líder de extrusión", value: "Líder de extrusión" },
  { id: "Líder de la asamblea", value: "Líder de la asamblea" },
  { id: "Líder de la mesa de ayuda", value: "Líder de la mesa de ayuda" },
  { id: "Líder de la producción", value: "Líder de la producción" },
  { id: "Líder de la refrigeración", value: "Líder de la refrigeración" },
  { id: "Líder de línea", value: "Líder de línea" },
  {
    id: "Líder de mantenimiento eléctrico",
    value: "Líder de mantenimiento eléctrico"
  },
  { id: "Líder de mantenimiento", value: "Líder de mantenimiento" },
  { id: "Líder de mecanizado", value: "Líder de mecanizado" },
  {
    id: "Líder de merchandising visual",
    value: "Líder de merchandising visual"
  },
  { id: "Líder de pintura", value: "Líder de pintura" },
  { id: "Líder de prueba", value: "Líder de prueba" },
  { id: "Líder de servicio", value: "Líder de servicio" },
  { id: "Líder de tienda", value: "Líder de tienda" },
  { id: "Líder de ventas", value: "Líder de ventas" },
  { id: "Líder logístico", value: "Líder logístico" },
  { id: "Maestra de kindergarten", value: "Maestra de kindergarten" },
  { id: "Maestra de primaria", value: "Maestra de primaria" },
  { id: "Maestro de circo", value: "Maestro de circo" },
  { id: "Maestro de edificios", value: "Maestro de edificios" },
  {
    id: "Maestro de enseñanza religiosa",
    value: "Maestro de enseñanza religiosa"
  },
  { id: "Maestro de español", value: "Maestro de español" },
  { id: "Maestro de hebreo", value: "Maestro de hebreo" },
  {
    id: "Maestro de métodos y procesos",
    value: "Maestro de métodos y procesos"
  },
  { id: "Maestro de scrum", value: "Maestro de scrum" },
  { id: "Maestro de teatro", value: "Maestro de teatro" },
  { id: "Maestro del oráculo", value: "Maestro del oráculo" },
  { id: "Maestro", value: "Maestro" },
  {
    id: "Manager de marketing de comercio",
    value: "Manager de marketing de comercio"
  },
  { id: "Mandril", value: "Mandril" },
  { id: "Manejador de farmacia", value: "Manejador de farmacia" },
  { id: "Manicura y pedicura", value: "Manicura y pedicura" },
  { id: "Manipulador de cosméticos", value: "Manipulador de cosméticos" },
  {
    id: "Mantenimiento electromecanico",
    value: "Mantenimiento electromecanico"
  },
  { id: "Maquilladora", value: "Maquilladora" },
  { id: "Marinero", value: "Marinero" },
  { id: "Marmorista", value: "Marmorista" },
  { id: "Martillo", value: "Martillo" },
  { id: "Masajeador", value: "Masajeador" },
  { id: "Masajista", value: "Masajista" },
  { id: "Masón", value: "Masón" },
  { id: "Matemático", value: "Matemático" },
  { id: "Mayordomo", value: "Mayordomo" },
  { id: "Maître", value: "Maître" },
  { id: "Mecanico electricista", value: "Mecanico electricista" },
  { id: "Mecanico lubricador", value: "Mecanico lubricador" },
  { id: "Mecanizado mecánico", value: "Mecanizado mecánico" },
  { id: "Mecanógrafo", value: "Mecanógrafo" },
  { id: "Mecánica hidráulica", value: "Mecánica hidráulica" },
  {
    id: "Mecánico de aire acondicionado",
    value: "Mecánico de aire acondicionado"
  },
  { id: "Mecánico de bicicletas", value: "Mecánico de bicicletas" },
  { id: "Mecánico de compresores", value: "Mecánico de compresores" },
  { id: "Mecánico de golpes", value: "Mecánico de golpes" },
  {
    id: "Mecánico de mantenimiento de aeronaves",
    value: "Mecánico de mantenimiento de aeronaves"
  },
  {
    id: "Mecánico de mantenimiento de edificios",
    value: "Mecánico de mantenimiento de edificios"
  },
  {
    id: "Mecánico de mantenimiento hidráulico",
    value: "Mecánico de mantenimiento hidráulico"
  },
  { id: "Mecánico de mantenimiento", value: "Mecánico de mantenimiento" },
  { id: "Mecánico de montacargas", value: "Mecánico de montacargas" },
  { id: "Mecánico de máquinas", value: "Mecánico de máquinas" },
  { id: "Mecánico de refrigeración", value: "Mecánico de refrigeración" },
  { id: "Mecánico de vehículos", value: "Mecánico de vehículos" },
  { id: "Mecánico industrial", value: "Mecánico industrial" },
  { id: "Mecánico medio oficial", value: "Mecánico medio oficial" },
  {
    id: "Mecánico oficial de mantenimiento",
    value: "Mecánico oficial de mantenimiento"
  },
  { id: "Mecánico", value: "Mecánico" },
  { id: "Medidor de obras", value: "Medidor de obras" },
  {
    id: "Medio de refrigeración oficial",
    value: "Medio de refrigeración oficial"
  },
  { id: "Medio oficial carpintero", value: "Medio oficial carpintero" },
  {
    id: "Medio oficial de impresión flexográfica",
    value: "Medio oficial de impresión flexográfica"
  },
  { id: "Medio oficial de pintura", value: "Medio oficial de pintura" },
  { id: "Medio oficial electricista", value: "Medio oficial electricista" },
  { id: "Medio oficial hidráulico", value: "Medio oficial hidráulico" },
  { id: "Medio oficial manitas", value: "Medio oficial manitas" },
  {
    id: "Medios oficiales de calderería",
    value: "Medios oficiales de calderería"
  },
  {
    id: "Medios oficiales de herramientas",
    value: "Medios oficiales de herramientas"
  },
  {
    id: "Medios oficiales de mantenimiento de edificios",
    value: "Medios oficiales de mantenimiento de edificios"
  },
  {
    id: "Medios oficiales de mantenimiento",
    value: "Medios oficiales de mantenimiento"
  },
  { id: "Mesero", value: "Mesero" },
  { id: "Mis analista", value: "Mis analista" },
  { id: "Modisto", value: "Modisto" },
  { id: "Molino", value: "Molino" },
  {
    id: "Montador mecánico medio oficial",
    value: "Montador mecánico medio oficial"
  },
  { id: "Niñera", value: "Niñera" },
  { id: "Odontopediatra", value: "Odontopediatra" },
  { id: "Odontólogo auditor", value: "Odontólogo auditor" },
  { id: "Oficial administrativo", value: "Oficial administrativo" },
  { id: "Oficial de cobro", value: "Oficial de cobro" },
  { id: "Oficial de corte", value: "Oficial de corte" },
  { id: "Oficial de presupuesto civil", value: "Oficial de presupuesto civil" },
  {
    id: "Oficial de prevención de pérdidas",
    value: "Oficial de prevención de pérdidas"
  },
  { id: "Oficial financiero", value: "Oficial financiero" },
  { id: "Oficina interna", value: "Oficina interna" },
  { id: "Oficinista", value: "Oficinista" },
  { id: "Operador de audio", value: "Operador de audio" },
  { id: "Operador de cargador", value: "Operador de cargador" },
  { id: "Operador de grúa aérea", value: "Operador de grúa aérea" },
  { id: "Operador de mantenimiento", value: "Operador de mantenimiento" },
  { id: "Operador de molinillo", value: "Operador de molinillo" },
  { id: "Operador de photoshop", value: "Operador de photoshop" },
  { id: "Operador de prensa", value: "Operador de prensa" },
  { id: "Operador de proceso químico", value: "Operador de proceso químico" },
  { id: "Operador de punzonadora", value: "Operador de punzonadora" },
  { id: "Operador de radio", value: "Operador de radio" },
  { id: "Operador de rastreo", value: "Operador de rastreo" },
  { id: "Operador de red", value: "Operador de red" },
  { id: "Operador de refrigeración", value: "Operador de refrigeración" },
  { id: "Operador de remolcador", value: "Operador de remolcador" },
  { id: "Operador de retroexcavadora", value: "Operador de retroexcavadora" },
  {
    id: "Operador de rodillo compactador",
    value: "Operador de rodillo compactador"
  },
  { id: "Operador de sala de máquinas", value: "Operador de sala de máquinas" },
  { id: "Operador de sierra", value: "Operador de sierra" },
  { id: "Operador de sucursal", value: "Operador de sucursal" },
  { id: "Operador de telemarketing", value: "Operador de telemarketing" },
  { id: "Operador de televentas", value: "Operador de televentas" },
  { id: "Operador de torno automático", value: "Operador de torno automático" },
  { id: "Operador de torno cnc", value: "Operador de torno cnc" },
  { id: "Operador de torno", value: "Operador de torno" },
  { id: "Operador de trazador", value: "Operador de trazador" },
  { id: "Operador de tráfico", value: "Operador de tráfico" },
  { id: "Operador de utilidad", value: "Operador de utilidad" },
  { id: "Operador de zinc", value: "Operador de zinc" },
  { id: "Operador telefónico", value: "Operador telefónico" },
  { id: "Operador turístico", value: "Operador turístico" },
  { id: "Orfebre", value: "Orfebre" },
  { id: "Oráculo dba", value: "Oráculo dba" },
  { id: "Paisajista", value: "Paisajista" },
  { id: "Panadero", value: "Panadero" },
  { id: "Panfletista", value: "Panfletista" },
  { id: "Parilla", value: "Parilla" },
  { id: "Pasajero", value: "Pasajero" },
  { id: "Pasantía de ciencias", value: "Pasantía de ciencias" },
  { id: "Pasantía de diseño de joyas", value: "Pasantía de diseño de joyas" },
  { id: "Pasantía de diseño", value: "Pasantía de diseño" },
  {
    id: "Pasantía de la escuela secundaria",
    value: "Pasantía de la escuela secundaria"
  },
  { id: "Pasantía de secretaria", value: "Pasantía de secretaria" },
  {
    id: "Pasantía en administración hospitalaria",
    value: "Pasantía en administración hospitalaria"
  },
  { id: "Pasantía en antropología", value: "Pasantía en antropología" },
  {
    id: "Pasantía en análisis de sistemas",
    value: "Pasantía en análisis de sistemas"
  },
  { id: "Pasantía en archivística", value: "Pasantía en archivística" },
  { id: "Pasantía en biología", value: "Pasantía en biología" },
  { id: "Pasantía en bioquímica", value: "Pasantía en bioquímica" },
  {
    id: "Pasantía en ciencias actuariales",
    value: "Pasantía en ciencias actuariales"
  },
  {
    id: "Pasantía en ciencias sociales",
    value: "Pasantía en ciencias sociales"
  },
  {
    id: "Pasantía en comunicación social",
    value: "Pasantía en comunicación social"
  },
  { id: "Pasantía en derecho civil", value: "Pasantía en derecho civil" },
  {
    id: "Pasantía en el área administrativa financiera",
    value: "Pasantía en el área administrativa financiera"
  },
  {
    id: "Pasantía en el área administrativa",
    value: "Pasantía en el área administrativa"
  },
  { id: "Pasantía en el área de arte", value: "Pasantía en el área de arte" },
  {
    id: "Pasantía en el área de asistencia técnica",
    value: "Pasantía en el área de asistencia técnica"
  },
  {
    id: "Pasantía en el área de asuntos regulatorios",
    value: "Pasantía en el área de asuntos regulatorios"
  },
  {
    id: "Pasantía en el área de auditoría",
    value: "Pasantía en el área de auditoría"
  },
  {
    id: "Pasantía en el área de controles internos",
    value: "Pasantía en el área de controles internos"
  },
  {
    id: "Pasantía en el área de cuentas por cobrar",
    value: "Pasantía en el área de cuentas por cobrar"
  },
  {
    id: "Pasantía en el área de cuentas por pagar",
    value: "Pasantía en el área de cuentas por pagar"
  },
  {
    id: "Pasantía en el área de desarrollo de sistemas",
    value: "Pasantía en el área de desarrollo de sistemas"
  },
  {
    id: "Pasantía en el área de educación",
    value: "Pasantía en el área de educación"
  },
  {
    id: "Pasantía en el área de estética",
    value: "Pasantía en el área de estética"
  },
  {
    id: "Pasantía en el área de facturación",
    value: "Pasantía en el área de facturación"
  },
  {
    id: "Pasantía en el área de geoprocesamiento",
    value: "Pasantía en el área de geoprocesamiento"
  },
  {
    id: "Pasantía en el área de gimnasia",
    value: "Pasantía en el área de gimnasia"
  },
  {
    id: "Pasantía en el área de importación y exportación",
    value: "Pasantía en el área de importación y exportación"
  },
  {
    id: "Pasantía en el área de infraestructura",
    value: "Pasantía en el área de infraestructura"
  },
  {
    id: "Pasantía en el área de la construcción",
    value: "Pasantía en el área de la construcción"
  },
  {
    id: "Pasantía en el área de licitaciones",
    value: "Pasantía en el área de licitaciones"
  },
  {
    id: "Pasantía en el área de mantenimiento",
    value: "Pasantía en el área de mantenimiento"
  },
  {
    id: "Pasantía en el área de metrología",
    value: "Pasantía en el área de metrología"
  },
  {
    id: "Pasantía en el área de microbiología",
    value: "Pasantía en el área de microbiología"
  },
  {
    id: "Pasantía en el área de planificación estratégica",
    value: "Pasantía en el área de planificación estratégica"
  },
  {
    id: "Pasantía en el área de planificación financiera",
    value: "Pasantía en el área de planificación financiera"
  },
  {
    id: "Pasantía en el área de servicio de publicidad",
    value: "Pasantía en el área de servicio de publicidad"
  },
  {
    id: "Pasantía en el área de sistemas",
    value: "Pasantía en el área de sistemas"
  },
  {
    id: "Pasantía en el área de soporte técnico",
    value: "Pasantía en el área de soporte técnico"
  },
  {
    id: "Pasantía en el área del proyecto",
    value: "Pasantía en el área del proyecto"
  },
  { id: "Pasantía en el área mecánica", value: "Pasantía en el área mecánica" },
  { id: "Pasantía en el área técnica", value: "Pasantía en el área técnica" },
  { id: "Pasantía en electrónica", value: "Pasantía en electrónica" },
  { id: "Pasantía en estadística", value: "Pasantía en estadística" },
  {
    id: "Pasantía en farmacia con énfasis en bioquímica",
    value: "Pasantía en farmacia con énfasis en bioquímica"
  },
  { id: "Pasantía en filosofía", value: "Pasantía en filosofía" },
  { id: "Pasantía en física", value: "Pasantía en física" },
  { id: "Pasantía en geografía", value: "Pasantía en geografía" },
  { id: "Pasantía en geología", value: "Pasantía en geología" },
  { id: "Pasantía en historia", value: "Pasantía en historia" },
  {
    id: "Pasantía en ingeniería metalúrgica",
    value: "Pasantía en ingeniería metalúrgica"
  },
  { id: "Pasantía en matemáticas", value: "Pasantía en matemáticas" },
  { id: "Pasantía en museología", value: "Pasantía en museología" },
  { id: "Pasantía en música", value: "Pasantía en música" },
  { id: "Pasantía en naturología", value: "Pasantía en naturología" },
  { id: "Pasantía en nutrición", value: "Pasantía en nutrición" },
  { id: "Pasantía en oceanografía", value: "Pasantía en oceanografía" },
  { id: "Pasantía en odontología", value: "Pasantía en odontología" },
  { id: "Pasantía en pedagogía", value: "Pasantía en pedagogía" },
  { id: "Pasantía en psicología", value: "Pasantía en psicología" },
  { id: "Pasantía en química", value: "Pasantía en química" },
  { id: "Pasantía en radiología", value: "Pasantía en radiología" },
  {
    id: "Pasantía en relaciones públicas",
    value: "Pasantía en relaciones públicas"
  },
  { id: "Pasantía en sociología", value: "Pasantía en sociología" },
  { id: "Pasantía en zootecnia", value: "Pasantía en zootecnia" },
  {
    id: "Pasantía técnica en el área de recursos humanos",
    value: "Pasantía técnica en el área de recursos humanos"
  },
  {
    id: "Pasantía técnica en enfermería ocupacional",
    value: "Pasantía técnica en enfermería ocupacional"
  },
  {
    id: "Pasantía técnica en enfermería",
    value: "Pasantía técnica en enfermería"
  },
  {
    id: "Pasantía técnica en nutrición",
    value: "Pasantía técnica en nutrición"
  },
  { id: "Pasantía veterinaria", value: "Pasantía veterinaria" },
  { id: "Patólogo veterinario", value: "Patólogo veterinario" },
  { id: "Pedagogo", value: "Pedagogo" },
  { id: "Pegatina", value: "Pegatina" },
  { id: "Peluquero", value: "Peluquero" },
  { id: "Perfumista", value: "Perfumista" },
  { id: "Periodista", value: "Periodista" },
  { id: "Pesador", value: "Pesador" },
  { id: "Pescadero", value: "Pescadero" },
  { id: "Piloto agrícola", value: "Piloto agrícola" },
  { id: "Piloto de avión", value: "Piloto de avión" },
  { id: "Piloto de helicóptero", value: "Piloto de helicóptero" },
  { id: "Piloto", value: "Piloto" },
  { id: "Pintor automotriz", value: "Pintor automotriz" },
  {
    id: "Pintor de estructuras metálicas",
    value: "Pintor de estructuras metálicas"
  },
  { id: "Pintor de muebles", value: "Pintor de muebles" },
  { id: "Pintor de obras", value: "Pintor de obras" },
  { id: "Pintor electrostático", value: "Pintor electrostático" },
  { id: "Pintor", value: "Pintor" },
  { id: "Piscina", value: "Piscina" },
  { id: "Piso", value: "Piso" },
  { id: "Pizzaiolo", value: "Pizzaiolo" },
  { id: "Planificador de presupuesto", value: "Planificador de presupuesto" },
  { id: "Planificador de producción", value: "Planificador de producción" },
  { id: "Planificador de proyectos", value: "Planificador de proyectos" },
  { id: "Plomero industrial", value: "Plomero industrial" },
  { id: "Plomero", value: "Plomero" },
  { id: "Podólogo", value: "Podólogo" },
  { id: "Portador de camilla", value: "Portador de camilla" },
  {
    id: "Practicante en el área comercial",
    value: "Practicante en el área comercial"
  },
  {
    id: "Practicante en el área financiera",
    value: "Practicante en el área financiera"
  },
  { id: "Prensista", value: "Prensista" },
  { id: "Preparación cnc", value: "Preparación cnc" },
  { id: "Preparador automático", value: "Preparador automático" },
  {
    id: "Preparador de maquinas de inyeccion",
    value: "Preparador de maquinas de inyeccion"
  },
  { id: "Preparador fisico", value: "Preparador fisico" },
  {
    id: "Presentador de programa de televisión",
    value: "Presentador de programa de televisión"
  },
  { id: "Presupuestario", value: "Presupuestario" },
  { id: "Procesista", value: "Procesista" },
  { id: "Productor artístico", value: "Productor artístico" },
  { id: "Productor cultural", value: "Productor cultural" },
  { id: "Productor de casting", value: "Productor de casting" },
  { id: "Productor de contenido", value: "Productor de contenido" },
  { id: "Productor de eventos", value: "Productor de eventos" },
  { id: "Productor de moda", value: "Productor de moda" },
  { id: "Productor de televisión", value: "Productor de televisión" },
  { id: "Productor de vídeo", value: "Productor de vídeo" },
  { id: "Productor editorial", value: "Productor editorial" },
  { id: "Productor gráfico", value: "Productor gráfico" },
  { id: "Productor musical", value: "Productor musical" },
  { id: "Profesor aleman", value: "Profesor aleman" },
  { id: "Profesor asistente", value: "Profesor asistente" },
  {
    id: "Profesor de administración de empresas",
    value: "Profesor de administración de empresas"
  },
  {
    id: "Profesor de administración pública",
    value: "Profesor de administración pública"
  },
  { id: "Profesor de aeróbic acuático", value: "Profesor de aeróbic acuático" },
  { id: "Profesor de agronomía", value: "Profesor de agronomía" },
  { id: "Profesor de ajedrez", value: "Profesor de ajedrez" },
  { id: "Profesor de anatomía", value: "Profesor de anatomía" },
  {
    id: "Profesor de arquitectura y urbanismo",
    value: "Profesor de arquitectura y urbanismo"
  },
  { id: "Profesor de artes escénicas", value: "Profesor de artes escénicas" },
  { id: "Profesor de artes marciales", value: "Profesor de artes marciales" },
  { id: "Profesor de autocad", value: "Profesor de autocad" },
  {
    id: "Profesor de automatización industrial",
    value: "Profesor de automatización industrial"
  },
  { id: "Profesor de baile", value: "Profesor de baile" },
  { id: "Profesor de biblioteca", value: "Profesor de biblioteca" },
  { id: "Profesor de biologia", value: "Profesor de biologia" },
  { id: "Profesor de biomedicina", value: "Profesor de biomedicina" },
  { id: "Profesor de calculo", value: "Profesor de calculo" },
  { id: "Profesor de canto", value: "Profesor de canto" },
  { id: "Profesor de capoeira", value: "Profesor de capoeira" },
  {
    id: "Profesor de ciencias contables",
    value: "Profesor de ciencias contables"
  },
  {
    id: "Profesor de comercio exterior",
    value: "Profesor de comercio exterior"
  },
  {
    id: "Profesor de comunicacion social",
    value: "Profesor de comunicacion social"
  },
  {
    id: "Profesor de comunicación visual",
    value: "Profesor de comunicación visual"
  },
  {
    id: "Profesor de conceptos básicos visuales",
    value: "Profesor de conceptos básicos visuales"
  },
  { id: "Profesor de construccion", value: "Profesor de construccion" },
  { id: "Profesor de coreldraw", value: "Profesor de coreldraw" },
  { id: "Profesor de costura", value: "Profesor de costura" },
  { id: "Profesor de culturismo", value: "Profesor de culturismo" },
  {
    id: "Profesor de derecho administrativo",
    value: "Profesor de derecho administrativo"
  },
  {
    id: "Profesor de derecho constitucional",
    value: "Profesor de derecho constitucional"
  },
  { id: "Profesor de derecho penal", value: "Profesor de derecho penal" },
  {
    id: "Profesor de derecho tributario",
    value: "Profesor de derecho tributario"
  },
  { id: "Profesor de derecho", value: "Profesor de derecho" },
  { id: "Profesor de dibujo técnico", value: "Profesor de dibujo técnico" },
  {
    id: "Profesor de dirección de empresas",
    value: "Profesor de dirección de empresas"
  },
  { id: "Profesor de diseño gráfico", value: "Profesor de diseño gráfico" },
  { id: "Profesor de diseño web", value: "Profesor de diseño web" },
  { id: "Profesor de economía", value: "Profesor de economía" },
  {
    id: "Profesor de educación a distancia",
    value: "Profesor de educación a distancia"
  },
  {
    id: "Profesor de educación artística",
    value: "Profesor de educación artística"
  },
  {
    id: "Profesor de educación especial",
    value: "Profesor de educación especial"
  },
  { id: "Profesor de educación física", value: "Profesor de educación física" },
  {
    id: "Profesor de educación técnica",
    value: "Profesor de educación técnica"
  },
  { id: "Profesor de electricista", value: "Profesor de electricista" },
  { id: "Profesor de electronica", value: "Profesor de electronica" },
  { id: "Profesor de emprendimiento", value: "Profesor de emprendimiento" },
  { id: "Profesor de enfermería", value: "Profesor de enfermería" },
  { id: "Profesor de escritura", value: "Profesor de escritura" },
  { id: "Profesor de estadística", value: "Profesor de estadística" },
  {
    id: "Profesor de estética y cosmetología",
    value: "Profesor de estética y cosmetología"
  },
  {
    id: "Profesor de expresión corporal",
    value: "Profesor de expresión corporal"
  },
  { id: "Profesor de farmacia", value: "Profesor de farmacia" },
  { id: "Profesor de filosofía", value: "Profesor de filosofía" },
  { id: "Profesor de fisioterapia", value: "Profesor de fisioterapia" },
  { id: "Profesor de fotografía", value: "Profesor de fotografía" },
  { id: "Profesor de francés", value: "Profesor de francés" },
  { id: "Profesor de física", value: "Profesor de física" },
  { id: "Profesor de fútbol", value: "Profesor de fútbol" },
  { id: "Profesor de gastronomía", value: "Profesor de gastronomía" },
  { id: "Profesor de geografía", value: "Profesor de geografía" },
  {
    id: "Profesor de gestión ambiental",
    value: "Profesor de gestión ambiental"
  },
  {
    id: "Profesor de gestión de calidad",
    value: "Profesor de gestión de calidad"
  },
  {
    id: "Profesor de gestión financiera",
    value: "Profesor de gestión financiera"
  },
  {
    id: "Profesor de gestión hospitalaria",
    value: "Profesor de gestión hospitalaria"
  },
  {
    id: "Profesor de gimnasia ocupacional",
    value: "Profesor de gimnasia ocupacional"
  },
  { id: "Profesor de gimnasia", value: "Profesor de gimnasia" },
  { id: "Profesor de griego", value: "Profesor de griego" },
  {
    id: "Profesor de gráficos por ordenador",
    value: "Profesor de gráficos por ordenador"
  },
  { id: "Profesor de guitarra", value: "Profesor de guitarra" },
  { id: "Profesor de historia", value: "Profesor de historia" },
  { id: "Profesor de hostelería", value: "Profesor de hostelería" },
  {
    id: "Profesor de hotelería y turismo",
    value: "Profesor de hotelería y turismo"
  },
  { id: "Profesor de idiomas", value: "Profesor de idiomas" },
  { id: "Profesor de informática", value: "Profesor de informática" },
  {
    id: "Profesor de ingeniería ambiental",
    value: "Profesor de ingeniería ambiental"
  },
  { id: "Profesor de ingeniería civil", value: "Profesor de ingeniería civil" },
  {
    id: "Profesor de ingeniería de producción",
    value: "Profesor de ingeniería de producción"
  },
  {
    id: "Profesor de ingeniería eléctrica",
    value: "Profesor de ingeniería eléctrica"
  },
  {
    id: "Profesor de ingeniería informática",
    value: "Profesor de ingeniería informática"
  },
  {
    id: "Profesor de ingeniería mecatrónica",
    value: "Profesor de ingeniería mecatrónica"
  },
  {
    id: "Profesor de ingeniería mecánica",
    value: "Profesor de ingeniería mecánica"
  },
  { id: "Profesor de ingeniería", value: "Profesor de ingeniería" },
  { id: "Profesor de ingles", value: "Profesor de ingles" },
  { id: "Profesor de inglés y español", value: "Profesor de inglés y español" },
  {
    id: "Profesor de inglés y portugués",
    value: "Profesor de inglés y portugués"
  },
  { id: "Profesor de instituto", value: "Profesor de instituto" },
  { id: "Profesor de interiorismo", value: "Profesor de interiorismo" },
  { id: "Profesor de italiano", value: "Profesor de italiano" },
  { id: "Profesor de literatura", value: "Profesor de literatura" },
  { id: "Profesor de logística", value: "Profesor de logística" },
  { id: "Profesor de mandarín", value: "Profesor de mandarín" },
  { id: "Profesor de manicura", value: "Profesor de manicura" },
  { id: "Profesor de manualidades", value: "Profesor de manualidades" },
  { id: "Profesor de maquillaje", value: "Profesor de maquillaje" },
  {
    id: "Profesor de matemáticas financieras",
    value: "Profesor de matemáticas financieras"
  },
  { id: "Profesor de matemáticas", value: "Profesor de matemáticas" },
  {
    id: "Profesor de mecánica aeronáutica",
    value: "Profesor de mecánica aeronáutica"
  },
  { id: "Profesor de mecánica", value: "Profesor de mecánica" },
  {
    id: "Profesor de medicina del trabajo",
    value: "Profesor de medicina del trabajo"
  },
  { id: "Profesor de medicina", value: "Profesor de medicina" },
  { id: "Profesor de mercadotecnia", value: "Profesor de mercadotecnia" },
  { id: "Profesor de moda", value: "Profesor de moda" },
  { id: "Profesor de música", value: "Profesor de música" },
  { id: "Profesor de natacion", value: "Profesor de natacion" },
  { id: "Profesor de nutrición", value: "Profesor de nutrición" },
  { id: "Profesor de odontología", value: "Profesor de odontología" },
  { id: "Profesor de pedagogía", value: "Profesor de pedagogía" },
  { id: "Profesor de periodismo", value: "Profesor de periodismo" },
  { id: "Profesor de petróleo y gas", value: "Profesor de petróleo y gas" },
  { id: "Profesor de photoshop", value: "Profesor de photoshop" },
  { id: "Profesor de php", value: "Profesor de php" },
  {
    id: "Profesor de pintura en lienzo",
    value: "Profesor de pintura en lienzo"
  },
  { id: "Profesor de podología", value: "Profesor de podología" },
  { id: "Profesor de portugués", value: "Profesor de portugués" },
  {
    id: "Profesor de programacion en red",
    value: "Profesor de programacion en red"
  },
  {
    id: "Profesor de programación informática",
    value: "Profesor de programación informática"
  },
  { id: "Profesor de prótesis dental", value: "Profesor de prótesis dental" },
  { id: "Profesor de psicoanálisis", value: "Profesor de psicoanálisis" },
  { id: "Profesor de psicología", value: "Profesor de psicología" },
  { id: "Profesor de psicopedagogía", value: "Profesor de psicopedagogía" },
  {
    id: "Profesor de publicidad y publicidad",
    value: "Profesor de publicidad y publicidad"
  },
  { id: "Profesor de química", value: "Profesor de química" },
  { id: "Profesor de radiología", value: "Profesor de radiología" },
  { id: "Profesor de recreación", value: "Profesor de recreación" },
  { id: "Profesor de recursos humanos", value: "Profesor de recursos humanos" },
  {
    id: "Profesor de relaciones públicas",
    value: "Profesor de relaciones públicas"
  },
  { id: "Profesor de robótica", value: "Profesor de robótica" },
  { id: "Profesor de ruso", value: "Profesor de ruso" },
  {
    id: "Profesor de seguridad laboral",
    value: "Profesor de seguridad laboral"
  },
  {
    id: "Profesor de sistemas de información",
    value: "Profesor de sistemas de información"
  },
  { id: "Profesor de sociología", value: "Profesor de sociología" },
  { id: "Profesor de sql", value: "Profesor de sql" },
  { id: "Profesor de tI", value: "Profesor de tI" },
  {
    id: "Profesor de tecnología de la información",
    value: "Profesor de tecnología de la información"
  },
  { id: "Profesor de telemercadeo", value: "Profesor de telemercadeo" },
  { id: "Profesor de tenis", value: "Profesor de tenis" },
  { id: "Profesor de teología", value: "Profesor de teología" },
  {
    id: "Profesor de terapia de masaje",
    value: "Profesor de terapia de masaje"
  },
  { id: "Profesor de trabajo social", value: "Profesor de trabajo social" },
  { id: "Profesor de turismo", value: "Profesor de turismo" },
  { id: "Profesor de ventanas", value: "Profesor de ventanas" },
  { id: "Profesor de ventas", value: "Profesor de ventas" },
  { id: "Profesor de yoga", value: "Profesor de yoga" },
  { id: "Profesor de zootecnia", value: "Profesor de zootecnia" },
  { id: "Profesor de árabe", value: "Profesor de árabe" },
  { id: "Profesor de ética", value: "Profesor de ética" },
  { id: "Profesor holandés", value: "Profesor holandés" },
  { id: "Profesor japonés", value: "Profesor japonés" },
  { id: "Profesor noruego", value: "Profesor noruego" },
  {
    id: "Profesor personal del departamento",
    value: "Profesor personal del departamento"
  },
  { id: "Profesor principal", value: "Profesor principal" },
  { id: "Profesor secretario", value: "Profesor secretario" },
  { id: "Profesor sueco", value: "Profesor sueco" },
  { id: "Profesor universitario", value: "Profesor universitario" },
  { id: "Profesor veterinario", value: "Profesor veterinario" },
  { id: "Programador .net", value: "Programador .net" },
  { id: "Programador abap", value: "Programador abap" },
  { id: "Programador advpl", value: "Programador advpl" },
  { id: "Programador analista vb6", value: "Programador analista vb6" },
  { id: "Programador analista", value: "Programador analista" },
  { id: "Programador androide", value: "Programador androide" },
  { id: "Programador asp", value: "Programador asp" },
  { id: "Programador asp.net", value: "Programador asp.net" },
  { id: "Programador back-end", value: "Programador back-end" },
  { id: "Programador bpm", value: "Programador bpm" },
  { id: "Programador c", value: "Programador c" },
  { id: "Programador c#", value: "Programador c#" },
  { id: "Programador c++", value: "Programador c++" },
  { id: "Programador clipper", value: "Programador clipper" },
  { id: "Programador clp", value: "Programador clp" },
  { id: "Programador cnc", value: "Programador cnc" },
  { id: "Programador cobol", value: "Programador cobol" },
  { id: "Programador de base de datos", value: "Programador de base de datos" },
  { id: "Programador de bordado", value: "Programador de bordado" },
  {
    id: "Programador de centro de mecanizado",
    value: "Programador de centro de mecanizado"
  },
  {
    id: "Programador de informes de formularios",
    value: "Programador de informes de formularios"
  },
  {
    id: "Programador de inteligencia empresarial",
    value: "Programador de inteligencia empresarial"
  },
  { id: "Programador de juegos", value: "Programador de juegos" },
  { id: "Programador de mantenimiento", value: "Programador de mantenimiento" },
  { id: "Programador de materiales", value: "Programador de materiales" },
  { id: "Programador de producción", value: "Programador de producción" },
  { id: "Programador de progreso", value: "Programador de progreso" },
  {
    id: "Programador de punto compartido",
    value: "Programador de punto compartido"
  },
  { id: "Programador de software", value: "Programador de software" },
  { id: "Programador delphi", value: "Programador delphi" },
  { id: "Programador dinámico", value: "Programador dinámico" },
  { id: "Programador en prácticas", value: "Programador en prácticas" },
  { id: "Programador etl", value: "Programador etl" },
  { id: "Programador flash", value: "Programador flash" },
  { id: "Programador flexible", value: "Programador flexible" },
  { id: "Programador front-end", value: "Programador front-end" },
  { id: "Programador genexus", value: "Programador genexus" },
  { id: "Programador ios", value: "Programador ios" },
  { id: "Programador java", value: "Programador java" },
  { id: "Programador javascript", value: "Programador javascript" },
  { id: "Programador linux", value: "Programador linux" },
  { id: "Programador microsiga", value: "Programador microsiga" },
  { id: "Programador móvil", value: "Programador móvil" },
  { id: "Programador node.js", value: "Programador node.js" },
  { id: "Programador oráculo", value: "Programador oráculo" },
  { id: "Programador php", value: "Programador php" },
  { id: "Programador pitón", value: "Programador pitón" },
  { id: "Programador pl sql", value: "Programador pl sql" },
  { id: "Programador powerbuilder", value: "Programador powerbuilder" },
  { id: "Programador proteo", value: "Programador proteo" },
  { id: "Programador qlikview", value: "Programador qlikview" },
  { id: "Programador ruby ​​on rails", value: "Programador ruby ​​on rails" },
  { id: "Programador savia", value: "Programador savia" },
  { id: "Programador sql", value: "Programador sql" },
  { id: "Programador visual basico", value: "Programador visual basico" },
  { id: "Programador visual foxpro", value: "Programador visual foxpro" },
  { id: "Programador visual", value: "Programador visual" },
  { id: "Programador", value: "Programador" },
  { id: "Promotor de comercialización", value: "Promotor de comercialización" },
  { id: "Promotor de crédito", value: "Promotor de crédito" },
  { id: "Promotor de eventos", value: "Promotor de eventos" },
  { id: "Promotor de financiación", value: "Promotor de financiación" },
  { id: "Promotor de ventas", value: "Promotor de ventas" },
  { id: "Promotor técnico", value: "Promotor técnico" },
  { id: "Propagandista", value: "Propagandista" },
  { id: "Propuesta ingeniero", value: "Propuesta ingeniero" },
  { id: "Protésico", value: "Protésico" },
  {
    id: "Proveedor de línea de producción",
    value: "Proveedor de línea de producción"
  },
  { id: "Proveedor de máquinas", value: "Proveedor de máquinas" },
  { id: "Proyecto especialista", value: "Proyecto especialista" },
  { id: "Prueba piloto", value: "Prueba piloto" },
  { id: "Prácticas ambientales", value: "Prácticas ambientales" },
  { id: "Prácticas de arquitectura", value: "Prácticas de arquitectura" },
  { id: "Prácticas de base de datos", value: "Prácticas de base de datos" },
  { id: "Prácticas de cine", value: "Prácticas de cine" },
  { id: "Prácticas de derecho", value: "Prácticas de derecho" },
  {
    id: "Prácticas de diseño de productos",
    value: "Prácticas de diseño de productos"
  },
  { id: "Prácticas de diseño web", value: "Prácticas de diseño web" },
  { id: "Prácticas de enfermería", value: "Prácticas de enfermería" },
  { id: "Prácticas de fisioterapia", value: "Prácticas de fisioterapia" },
  { id: "Prácticas de fotografía", value: "Prácticas de fotografía" },
  {
    id: "Prácticas de ingeniería de producto",
    value: "Prácticas de ingeniería de producto"
  },
  {
    id: "Prácticas de ingeniería forestal",
    value: "Prácticas de ingeniería forestal"
  },
  { id: "Prácticas de ingeniería", value: "Prácticas de ingeniería" },
  { id: "Prácticas de marketing", value: "Prácticas de marketing" },
  { id: "Prácticas de moda", value: "Prácticas de moda" },
  { id: "Prácticas de periodismo", value: "Prácticas de periodismo" },
  { id: "Prácticas de psicopedagogía", value: "Prácticas de psicopedagogía" },
  { id: "Prácticas de publicidad", value: "Prácticas de publicidad" },
  { id: "Prácticas de radio y tv", value: "Prácticas de radio y tv" },
  { id: "Prácticas de traducción", value: "Prácticas de traducción" },
  { id: "Prácticas de turismo", value: "Prácticas de turismo" },
  { id: "Prácticas docentes", value: "Prácticas docentes" },
  { id: "Prácticas eléctricas", value: "Prácticas eléctricas" },
  {
    id: "Prácticas en administración de empresas",
    value: "Prácticas en administración de empresas"
  },
  {
    id: "Prácticas en análisis y desarrollo de sistemas",
    value: "Prácticas en análisis y desarrollo de sistemas"
  },
  { id: "Prácticas en artes gráficas", value: "Prácticas en artes gráficas" },
  { id: "Prácticas en artes plásticas", value: "Prácticas en artes plásticas" },
  { id: "Prácticas en autocad", value: "Prácticas en autocad" },
  { id: "Prácticas en biblioteconomía", value: "Prácticas en biblioteconomía" },
  { id: "Prácticas en bienes raíces", value: "Prácticas en bienes raíces" },
  {
    id: "Prácticas en ciencias aeronáuticas",
    value: "Prácticas en ciencias aeronáuticas"
  },
  {
    id: "Prácticas en ciencias biomédicas",
    value: "Prácticas en ciencias biomédicas"
  },
  {
    id: "Prácticas en comercio exterior",
    value: "Prácticas en comercio exterior"
  },
  {
    id: "Prácticas en comunicación y marketing",
    value: "Prácticas en comunicación y marketing"
  },
  { id: "Prácticas en comunicación", value: "Prácticas en comunicación" },
  {
    id: "Prácticas en control de calidad",
    value: "Prácticas en control de calidad"
  },
  {
    id: "Prácticas en derecho corporativo",
    value: "Prácticas en derecho corporativo"
  },
  {
    id: "Prácticas en derecho inmobiliario",
    value: "Prácticas en derecho inmobiliario"
  },
  { id: "Prácticas en derecho laboral", value: "Prácticas en derecho laboral" },
  {
    id: "Prácticas en derecho tributario",
    value: "Prácticas en derecho tributario"
  },
  {
    id: "Prácticas en diseño de interiores",
    value: "Prácticas en diseño de interiores"
  },
  { id: "Prácticas en diseño digital", value: "Prácticas en diseño digital" },
  { id: "Prácticas en diseño gráfico", value: "Prácticas en diseño gráfico" },
  {
    id: "Prácticas en diseño industrial",
    value: "Prácticas en diseño industrial"
  },
  { id: "Prácticas en economía", value: "Prácticas en economía" },
  {
    id: "Prácticas en educación física",
    value: "Prácticas en educación física"
  },
  {
    id: "Prácticas en el campo de la salud bucal",
    value: "Prácticas en el campo de la salud bucal"
  },
  {
    id: "Prácticas en el área audiovisual",
    value: "Prácticas en el área audiovisual"
  },
  {
    id: "Prácticas en el área bancaria",
    value: "Prácticas en el área bancaria"
  },
  {
    id: "Prácticas en el área comercial",
    value: "Prácticas en el área comercial"
  },
  { id: "Prácticas en el área crm", value: "Prácticas en el área crm" },
  {
    id: "Prácticas en el área de abastecimiento",
    value: "Prácticas en el área de abastecimiento"
  },
  {
    id: "Prácticas en el área de alimentos y bebidas",
    value: "Prácticas en el área de alimentos y bebidas"
  },
  {
    id: "Prácticas en el área de alimentos",
    value: "Prácticas en el área de alimentos"
  },
  {
    id: "Prácticas en el área de almacén",
    value: "Prácticas en el área de almacén"
  },
  {
    id: "Prácticas en el área de automatización industrial",
    value: "Prácticas en el área de automatización industrial"
  },
  {
    id: "Prácticas en el área de back office",
    value: "Prácticas en el área de back office"
  },
  {
    id: "Prácticas en el área de beneficios",
    value: "Prácticas en el área de beneficios"
  },
  {
    id: "Prácticas en el área de calidad y medio ambiente",
    value: "Prácticas en el área de calidad y medio ambiente"
  },
  {
    id: "Prácticas en el área de calidad",
    value: "Prácticas en el área de calidad"
  },
  {
    id: "Prácticas en el área de comercio electrónico",
    value: "Prácticas en el área de comercio electrónico"
  },
  {
    id: "Prácticas en el área de compras",
    value: "Prácticas en el área de compras"
  },
  {
    id: "Prácticas en el área de contratos",
    value: "Prácticas en el área de contratos"
  },
  {
    id: "Prácticas en el área de control",
    value: "Prácticas en el área de control"
  },
  {
    id: "Prácticas en el área de costos",
    value: "Prácticas en el área de costos"
  },
  {
    id: "Prácticas en el área de creación",
    value: "Prácticas en el área de creación"
  },
  {
    id: "Prácticas en el área de crédito y cobranza",
    value: "Prácticas en el área de crédito y cobranza"
  },
  {
    id: "Prácticas en el área de desarrollo .net",
    value: "Prácticas en el área de desarrollo .net"
  },
  {
    id: "Prácticas en el área de desarrollo de software",
    value: "Prácticas en el área de desarrollo de software"
  },
  {
    id: "Prácticas en el área de edición de imágenes",
    value: "Prácticas en el área de edición de imágenes"
  },
  {
    id: "Prácticas en el área de edición de video",
    value: "Prácticas en el área de edición de video"
  },
  {
    id: "Prácticas en el área de educación infantil",
    value: "Prácticas en el área de educación infantil"
  },
  {
    id: "Prácticas en el área de enlaces patrocinados",
    value: "Prácticas en el área de enlaces patrocinados"
  },
  {
    id: "Prácticas en el área de envíos",
    value: "Prácticas en el área de envíos"
  },
  {
    id: "Prácticas en el área de eventos",
    value: "Prácticas en el área de eventos"
  },
  {
    id: "Prácticas en el área de exportación",
    value: "Prácticas en el área de exportación"
  },
  {
    id: "Prácticas en el área de formación y desarrollo",
    value: "Prácticas en el área de formación y desarrollo"
  },
  {
    id: "Prácticas en el área de fusiones y adquisiciones",
    value: "Prácticas en el área de fusiones y adquisiciones"
  },
  {
    id: "Prácticas en el área de gestión ambiental",
    value: "Prácticas en el área de gestión ambiental"
  },
  {
    id: "Prácticas en el área de gobernabilidad",
    value: "Prácticas en el área de gobernabilidad"
  },
  {
    id: "Prácticas en el área de implementación de sistemas",
    value: "Prácticas en el área de implementación de sistemas"
  },
  {
    id: "Prácticas en el área de importación",
    value: "Prácticas en el área de importación"
  },
  {
    id: "Prácticas en el área de ingeniería de procesos",
    value: "Prácticas en el área de ingeniería de procesos"
  },
  {
    id: "Prácticas en el área de ingeniería de proyectos",
    value: "Prácticas en el área de ingeniería de proyectos"
  },
  {
    id: "Prácticas en el área de instalaciones",
    value: "Prácticas en el área de instalaciones"
  },
  {
    id: "Prácticas en el área de inteligencia de mercado",
    value: "Prácticas en el área de inteligencia de mercado"
  },
  {
    id: "Prácticas en el área de inteligencia de negocios",
    value: "Prácticas en el área de inteligencia de negocios"
  },
  {
    id: "Prácticas en el área de inversiones",
    value: "Prácticas en el área de inversiones"
  },
  {
    id: "Prácticas en el área de investigación de mercado",
    value: "Prácticas en el área de investigación de mercado"
  },
  {
    id: "Prácticas en el área de la construcción",
    value: "Prácticas en el área de la construcción"
  },
  {
    id: "Prácticas en el área de laboratorio",
    value: "Prácticas en el área de laboratorio"
  },
  {
    id: "Prácticas en el área de maquetación",
    value: "Prácticas en el área de maquetación"
  },
  {
    id: "Prácticas en el área de marketing digital",
    value: "Prácticas en el área de marketing digital"
  },
  {
    id: "Prácticas en el área de medios digitales",
    value: "Prácticas en el área de medios digitales"
  },
  {
    id: "Prácticas en el área de musculación",
    value: "Prácticas en el área de musculación"
  },
  {
    id: "Prácticas en el área de natación",
    value: "Prácticas en el área de natación"
  },
  {
    id: "Prácticas en el área de negocios",
    value: "Prácticas en el área de negocios"
  },
  {
    id: "Prácticas en el área de planificación",
    value: "Prácticas en el área de planificación"
  },
  {
    id: "Prácticas en el área de presupuestos",
    value: "Prácticas en el área de presupuestos"
  },
  {
    id: "Prácticas en el área de procesos industriales",
    value: "Prácticas en el área de procesos industriales"
  },
  {
    id: "Prácticas en el área de procesos",
    value: "Prácticas en el área de procesos"
  },
  {
    id: "Prácticas en el área de producción",
    value: "Prácticas en el área de producción"
  },
  {
    id: "Prácticas en el área de producto",
    value: "Prácticas en el área de producto"
  },
  {
    id: "Prácticas en el área de programación delphi",
    value: "Prácticas en el área de programación delphi"
  },
  {
    id: "Prácticas en el área de programación java",
    value: "Prácticas en el área de programación java"
  },
  {
    id: "Prácticas en el área de programación php",
    value: "Prácticas en el área de programación php"
  },
  {
    id: "Prácticas en el área de programación web",
    value: "Prácticas en el área de programación web"
  },
  {
    id: "Prácticas en el área de programación",
    value: "Prácticas en el área de programación"
  },
  {
    id: "Prácticas en el área de pruebas",
    value: "Prácticas en el área de pruebas"
  },
  {
    id: "Prácticas en el área de recepción",
    value: "Prácticas en el área de recepción"
  },
  {
    id: "Prácticas en el área de reclutamiento y selección",
    value: "Prácticas en el área de reclutamiento y selección"
  },
  {
    id: "Prácticas en el área de recreación",
    value: "Prácticas en el área de recreación"
  },
  {
    id: "Prácticas en el área de redacción publicitaria",
    value: "Prácticas en el área de redacción publicitaria"
  },
  {
    id: "Prácticas en el área de redacción",
    value: "Prácticas en el área de redacción"
  },
  {
    id: "Prácticas en el área de redes sociales",
    value: "Prácticas en el área de redes sociales"
  },
  {
    id: "Prácticas en el área de redes",
    value: "Prácticas en el área de redes"
  },
  {
    id: "Prácticas en el área de relaciones con la prensa",
    value: "Prácticas en el área de relaciones con la prensa"
  },
  {
    id: "Prácticas en el área de relaciones",
    value: "Prácticas en el área de relaciones"
  },
  {
    id: "Prácticas en el área de remuneración y beneficios",
    value: "Prácticas en el área de remuneración y beneficios"
  },
  {
    id: "Prácticas en el área de remuneración",
    value: "Prácticas en el área de remuneración"
  },
  {
    id: "Prácticas en el área de reservas",
    value: "Prácticas en el área de reservas"
  },
  {
    id: "Prácticas en el área de responsabilidad social",
    value: "Prácticas en el área de responsabilidad social"
  },
  {
    id: "Prácticas en el área de revisión de textos",
    value: "Prácticas en el área de revisión de textos"
  },
  {
    id: "Prácticas en el área de riesgo",
    value: "Prácticas en el área de riesgo"
  },
  {
    id: "Prácticas en el área de seguridad de la información",
    value: "Prácticas en el área de seguridad de la información"
  },
  {
    id: "Prácticas en el área de seguridad en el trabajo",
    value: "Prácticas en el área de seguridad en el trabajo"
  },
  {
    id: "Prácticas en el área de seguros",
    value: "Prácticas en el área de seguros"
  },
  {
    id: "Prácticas en el área de servicio",
    value: "Prácticas en el área de servicio"
  },
  {
    id: "Prácticas en el área de sostenibilidad",
    value: "Prácticas en el área de sostenibilidad"
  },
  {
    id: "Prácticas en el área de stock",
    value: "Prácticas en el área de stock"
  },
  {
    id: "Prácticas en el área de tecnología de la información",
    value: "Prácticas en el área de tecnología de la información"
  },
  {
    id: "Prácticas en el área de tesorería",
    value: "Prácticas en el área de tesorería"
  },
  {
    id: "Prácticas en el área de trade marketing",
    value: "Prácticas en el área de trade marketing"
  },
  {
    id: "Prácticas en el área de transporte",
    value: "Prácticas en el área de transporte"
  },
  {
    id: "Prácticas en el área de ventas",
    value: "Prácticas en el área de ventas"
  },
  {
    id: "Prácticas en el área del departamento de personal",
    value: "Prácticas en el área del departamento de personal"
  },
  {
    id: "Prácticas en el área financiera",
    value: "Prácticas en el área financiera"
  },
  { id: "Prácticas en el área fiscal", value: "Prácticas en el área fiscal" },
  {
    id: "Prácticas en el área industrial",
    value: "Prácticas en el área industrial"
  },
  { id: "Prácticas en el área pcp", value: "Prácticas en el área pcp" },
  { id: "Prácticas en farmacia", value: "Prácticas en farmacia" },
  { id: "Prácticas en gastronomía", value: "Prácticas en gastronomía" },
  {
    id: "Prácticas en gestión comercial",
    value: "Prácticas en gestión comercial"
  },
  {
    id: "Prácticas en gestión de calidad",
    value: "Prácticas en gestión de calidad"
  },
  {
    id: "Prácticas en gestión financiera",
    value: "Prácticas en gestión financiera"
  },
  { id: "Prácticas en gestión pública", value: "Prácticas en gestión pública" },
  { id: "Prácticas en hostelería", value: "Prácticas en hostelería" },
  {
    id: "Prácticas en hotelería y turismo",
    value: "Prácticas en hotelería y turismo"
  },
  { id: "Prácticas en informática", value: "Prácticas en informática" },
  {
    id: "Prácticas en ingeniería aeronáutica",
    value: "Prácticas en ingeniería aeronáutica"
  },
  {
    id: "Prácticas en ingeniería agronómica",
    value: "Prácticas en ingeniería agronómica"
  },
  {
    id: "Prácticas en ingeniería ambiental",
    value: "Prácticas en ingeniería ambiental"
  },
  {
    id: "Prácticas en ingeniería automotriz",
    value: "Prácticas en ingeniería automotriz"
  },
  {
    id: "Prácticas en ingeniería biomédica",
    value: "Prácticas en ingeniería biomédica"
  },
  {
    id: "Prácticas en ingeniería cartográfica",
    value: "Prácticas en ingeniería cartográfica"
  },
  {
    id: "Prácticas en ingeniería civil",
    value: "Prácticas en ingeniería civil"
  },
  {
    id: "Prácticas en ingeniería de alimentos",
    value: "Prácticas en ingeniería de alimentos"
  },
  {
    id: "Prácticas en ingeniería de calidad",
    value: "Prácticas en ingeniería de calidad"
  },
  {
    id: "Prácticas en ingeniería de control y automatización",
    value: "Prácticas en ingeniería de control y automatización"
  },
  {
    id: "Prácticas en ingeniería de materiales",
    value: "Prácticas en ingeniería de materiales"
  },
  {
    id: "Prácticas en ingeniería de minas",
    value: "Prácticas en ingeniería de minas"
  },
  {
    id: "Prácticas en ingeniería de producción",
    value: "Prácticas en ingeniería de producción"
  },
  {
    id: "Prácticas en ingeniería de seguridad laboral",
    value: "Prácticas en ingeniería de seguridad laboral"
  },
  {
    id: "Prácticas en ingeniería de telecomunicaciones",
    value: "Prácticas en ingeniería de telecomunicaciones"
  },
  {
    id: "Prácticas en ingeniería electrónica",
    value: "Prácticas en ingeniería electrónica"
  },
  {
    id: "Prácticas en ingeniería eléctrica y electrónica",
    value: "Prácticas en ingeniería eléctrica y electrónica"
  },
  {
    id: "Prácticas en ingeniería eléctrica",
    value: "Prácticas en ingeniería eléctrica"
  },
  {
    id: "Prácticas en ingeniería industrial",
    value: "Prácticas en ingeniería industrial"
  },
  {
    id: "Prácticas en ingeniería informática",
    value: "Prácticas en ingeniería informática"
  },
  {
    id: "Prácticas en ingeniería marina",
    value: "Prácticas en ingeniería marina"
  },
  {
    id: "Prácticas en ingeniería mecatrónica",
    value: "Prácticas en ingeniería mecatrónica"
  },
  {
    id: "Prácticas en ingeniería mecánica",
    value: "Prácticas en ingeniería mecánica"
  },
  {
    id: "Prácticas en ingeniería química",
    value: "Prácticas en ingeniería química"
  },
  {
    id: "Prácticas en ingeniería textil",
    value: "Prácticas en ingeniería textil"
  },
  {
    id: "Prácticas en ingeniería topográfica",
    value: "Prácticas en ingeniería topográfica"
  },
  { id: "Prácticas en letras", value: "Prácticas en letras" },
  { id: "Prácticas en logística", value: "Prácticas en logística" },
  {
    id: "Prácticas en procesamiento de datos",
    value: "Prácticas en procesamiento de datos"
  },
  {
    id: "Prácticas en procesos de gestión",
    value: "Prácticas en procesos de gestión"
  },
  {
    id: "Prácticas en producción editorial",
    value: "Prácticas en producción editorial"
  },
  {
    id: "Prácticas en publicidad y propaganda",
    value: "Prácticas en publicidad y propaganda"
  },
  {
    id: "Prácticas en recursos humanos",
    value: "Prácticas en recursos humanos"
  },
  {
    id: "Prácticas en redes informáticas",
    value: "Prácticas en redes informáticas"
  },
  {
    id: "Prácticas en relaciones internacionales",
    value: "Prácticas en relaciones internacionales"
  },
  {
    id: "Prácticas en secretaría ejecutiva",
    value: "Prácticas en secretaría ejecutiva"
  },
  {
    id: "Prácticas en sistemas de información",
    value: "Prácticas en sistemas de información"
  },
  {
    id: "Prácticas en telecomunicaciones",
    value: "Prácticas en telecomunicaciones"
  },
  {
    id: "Prácticas en terapia del habla",
    value: "Prácticas en terapia del habla"
  },
  {
    id: "Prácticas en terapia ocupacional",
    value: "Prácticas en terapia ocupacional"
  },
  { id: "Prácticas en trabajo social", value: "Prácticas en trabajo social" },
  {
    id: "Prácticas en transacciones inmobiliarias",
    value: "Prácticas en transacciones inmobiliarias"
  },
  {
    id: "Prácticas técnicas en administración de empresas",
    value: "Prácticas técnicas en administración de empresas"
  },
  {
    id: "Prácticas técnicas en alimentos",
    value: "Prácticas técnicas en alimentos"
  },
  {
    id: "Prácticas técnicas en análisis clínicos",
    value: "Prácticas técnicas en análisis clínicos"
  },
  {
    id: "Prácticas técnicas en calidad",
    value: "Prácticas técnicas en calidad"
  },
  {
    id: "Prácticas técnicas en contabilidad",
    value: "Prácticas técnicas en contabilidad"
  },
  {
    id: "Prácticas técnicas en edificios",
    value: "Prácticas técnicas en edificios"
  },
  {
    id: "Prácticas técnicas en el área de mantenimiento",
    value: "Prácticas técnicas en el área de mantenimiento"
  },
  {
    id: "Prácticas técnicas en el área de ventas",
    value: "Prácticas técnicas en el área de ventas"
  },
  {
    id: "Prácticas técnicas en electricidad",
    value: "Prácticas técnicas en electricidad"
  },
  {
    id: "Prácticas técnicas en electromecánica",
    value: "Prácticas técnicas en electromecánica"
  },
  {
    id: "Prácticas técnicas en electrónica",
    value: "Prácticas técnicas en electrónica"
  },
  {
    id: "Prácticas técnicas en farmacia",
    value: "Prácticas técnicas en farmacia"
  },
  {
    id: "Prácticas técnicas en gestión empresarial",
    value: "Prácticas técnicas en gestión empresarial"
  },
  {
    id: "Prácticas técnicas en informática",
    value: "Prácticas técnicas en informática"
  },
  {
    id: "Prácticas técnicas en ingeniería eléctrica",
    value: "Prácticas técnicas en ingeniería eléctrica"
  },
  {
    id: "Prácticas técnicas en logística",
    value: "Prácticas técnicas en logística"
  },
  {
    id: "Prácticas técnicas en mecatrónica",
    value: "Prácticas técnicas en mecatrónica"
  },
  {
    id: "Prácticas técnicas en medio ambiente",
    value: "Prácticas técnicas en medio ambiente"
  },
  {
    id: "Prácticas técnicas en plásticos",
    value: "Prácticas técnicas en plásticos"
  },
  {
    id: "Prácticas técnicas en prótesis dental",
    value: "Prácticas técnicas en prótesis dental"
  },
  {
    id: "Prácticas técnicas en publicidad y propaganda",
    value: "Prácticas técnicas en publicidad y propaganda"
  },
  {
    id: "Prácticas técnicas en química",
    value: "Prácticas técnicas en química"
  },
  {
    id: "Prácticas técnicas en secretaría",
    value: "Prácticas técnicas en secretaría"
  },
  {
    id: "Prácticas técnicas en seguridad en el trabajo",
    value: "Prácticas técnicas en seguridad en el trabajo"
  },
  {
    id: "Prácticas técnicas en telecomunicaciones",
    value: "Prácticas técnicas en telecomunicaciones"
  },
  {
    id: "Prácticas técnicas en topografía",
    value: "Prácticas técnicas en topografía"
  },
  {
    id: "Prácticas técnico en mecánica",
    value: "Prácticas técnico en mecánica"
  },
  { id: "Psicologo clínico", value: "Psicologo clínico" },
  { id: "Psicomotorista", value: "Psicomotorista" },
  { id: "Psicopedagogo", value: "Psicopedagogo" },
  { id: "Psicólogo de la escuela", value: "Psicólogo de la escuela" },
  { id: "Psicólogo de tráfico", value: "Psicólogo de tráfico" },
  { id: "Psicólogo hospitalario", value: "Psicólogo hospitalario" },
  { id: "Psicólogo organizacional", value: "Psicólogo organizacional" },
  { id: "Psicólogo social", value: "Psicólogo social" },
  { id: "Psicólogo", value: "Psicólogo" },
  { id: "Publicista", value: "Publicista" },
  { id: "Pulido de vehículos", value: "Pulido de vehículos" },
  { id: "Pulido", value: "Pulido" },
  { id: "Pulidor de metales", value: "Pulidor de metales" },
  { id: "Puntero de obras", value: "Puntero de obras" },
  { id: "Puntero de producción", value: "Puntero de producción" },
  { id: "Póster", value: "Póster" },
  { id: "Química industrial", value: "Química industrial" },
  { id: "Químico responsable", value: "Químico responsable" },
  { id: "Químico", value: "Químico" },
  { id: "Rebobinador", value: "Rebobinador" },
  { id: "Recepcionista de hospital", value: "Recepcionista de hospital" },
  { id: "Recepcionista de hotel", value: "Recepcionista de hotel" },
  { id: "Recepcionista", value: "Recepcionista" },
  { id: "Receptor", value: "Receptor" },
  { id: "Recogedor", value: "Recogedor" },
  { id: "Recolector de basura", value: "Recolector de basura" },
  { id: "Recolector de muestras", value: "Recolector de muestras" },
  { id: "Rectificador", value: "Rectificador" },
  { id: "Recuperación de crédito", value: "Recuperación de crédito" },
  { id: "Redactor publicitario", value: "Redactor publicitario" },
  { id: "Relaciones públicas", value: "Relaciones públicas" },
  { id: "Relojero", value: "Relojero" },
  { id: "Reparador de vehículos", value: "Reparador de vehículos" },
  { id: "Repartidor", value: "Repartidor" },
  { id: "Reportar mecanógrafo", value: "Reportar mecanógrafo" },
  { id: "Reportero de noticias", value: "Reportero de noticias" },
  { id: "Repositorio", value: "Repositorio" },
  { id: "Repostero", value: "Repostero" },
  { id: "Representante comercial", value: "Representante comercial" },
  { id: "Representante de posventa", value: "Representante de posventa" },
  { id: "Representante legal", value: "Representante legal" },
  { id: "Reservador", value: "Reservador" },
  {
    id: "Responsable técnico en enfermería",
    value: "Responsable técnico en enfermería"
  },
  { id: "Revisor de telas", value: "Revisor de telas" },
  { id: "Revisor de texto", value: "Revisor de texto" },
  { id: "Robotista", value: "Robotista" },
  { id: "Salvador", value: "Salvador" },
  { id: "Salvavidas", value: "Salvavidas" },
  { id: "Sastre", value: "Sastre" },
  { id: "Secretaria de la escuela", value: "Secretaria de la escuela" },
  { id: "Secretaria executiva", value: "Secretaria executiva" },
  { id: "Secretaria financiera", value: "Secretaria financiera" },
  { id: "Secretario comercial", value: "Secretario comercial" },
  { id: "Secretario técnico", value: "Secretario técnico" },
  { id: "Secretario", value: "Secretario" },
  { id: "Seguridad patrimonial", value: "Seguridad patrimonial" },
  { id: "Seguridad", value: "Seguridad" },
  { id: "Sereno", value: "Sereno" },
  {
    id: "Servicio administrativo de ventas",
    value: "Servicio administrativo de ventas"
  },
  { id: "Servicio de publicidad", value: "Servicio de publicidad" },
  { id: "Sirviente de obras", value: "Sirviente de obras" },
  { id: "Socio de negocios", value: "Socio de negocios" },
  { id: "Sociólogo", value: "Sociólogo" },
  { id: "Solador", value: "Solador" },
  { id: "Soldador ensamblador", value: "Soldador ensamblador" },
  { id: "Soldador", value: "Soldador" },
  { id: "Sommelier", value: "Sommelier" },
  { id: "Sonidista", value: "Sonidista" },
  { id: "Sonidos de analista", value: "Sonidos de analista" },
  { id: "Sonidos del programador", value: "Sonidos del programador" },
  { id: "Soplete", value: "Soplete" },
  { id: "Soporte técnico", value: "Soporte técnico" },
  { id: "Subgerente", value: "Subgerente" },
  { id: "Superintendente comercial", value: "Superintendente comercial" },
  {
    id: "Superintendente de operaciones",
    value: "Superintendente de operaciones"
  },
  {
    id: "Supervisor administrativo de obras",
    value: "Supervisor administrativo de obras"
  },
  { id: "Supervisor administrativo", value: "Supervisor administrativo" },
  { id: "Supervisor agrícola", value: "Supervisor agrícola" },
  { id: "Supervisor comercial", value: "Supervisor comercial" },
  { id: "Supervisor de activos", value: "Supervisor de activos" },
  {
    id: "Supervisor de alimentos y bebidas",
    value: "Supervisor de alimentos y bebidas"
  },
  { id: "Supervisor de almacén", value: "Supervisor de almacén" },
  { id: "Supervisor de archivos", value: "Supervisor de archivos" },
  { id: "Supervisor de arrendamiento", value: "Supervisor de arrendamiento" },
  {
    id: "Supervisor de asistencia técnica",
    value: "Supervisor de asistencia técnica"
  },
  { id: "Supervisor de auditoría", value: "Supervisor de auditoría" },
  { id: "Supervisor de automatización", value: "Supervisor de automatización" },
  { id: "Supervisor de back office", value: "Supervisor de back office" },
  { id: "Supervisor de caja", value: "Supervisor de caja" },
  { id: "Supervisor de calderería", value: "Supervisor de calderería" },
  { id: "Supervisor de calidad", value: "Supervisor de calidad" },
  { id: "Supervisor de campo", value: "Supervisor de campo" },
  { id: "Supervisor de cerrajería", value: "Supervisor de cerrajería" },
  {
    id: "Supervisor de comercio electrónico",
    value: "Supervisor de comercio electrónico"
  },
  {
    id: "Supervisor de comercio exterior",
    value: "Supervisor de comercio exterior"
  },
  { id: "Supervisor de compras", value: "Supervisor de compras" },
  { id: "Supervisor de condominio", value: "Supervisor de condominio" },
  { id: "Supervisor de contrato", value: "Supervisor de contrato" },
  {
    id: "Supervisor de control de calidad",
    value: "Supervisor de control de calidad"
  },
  { id: "Supervisor de control", value: "Supervisor de control" },
  { id: "Supervisor de costos", value: "Supervisor de costos" },
  { id: "Supervisor de costura", value: "Supervisor de costura" },
  {
    id: "Supervisor de crédito inmobiliario",
    value: "Supervisor de crédito inmobiliario"
  },
  {
    id: "Supervisor de crédito y cobranza",
    value: "Supervisor de crédito y cobranza"
  },
  { id: "Supervisor de crédito", value: "Supervisor de crédito" },
  { id: "Supervisor de cuenta", value: "Supervisor de cuenta" },
  {
    id: "Supervisor de cuentas por cobrar",
    value: "Supervisor de cuentas por cobrar"
  },
  {
    id: "Supervisor de cuentas por pagar",
    value: "Supervisor de cuentas por pagar"
  },
  { id: "Supervisor de cuentas", value: "Supervisor de cuentas" },
  { id: "Supervisor de despacho", value: "Supervisor de despacho" },
  { id: "Supervisor de eventos", value: "Supervisor de eventos" },
  { id: "Supervisor de facturación", value: "Supervisor de facturación" },
  { id: "Supervisor de farmacia", value: "Supervisor de farmacia" },
  { id: "Supervisor de flota", value: "Supervisor de flota" },
  {
    id: "Supervisor de formación y desarrollo",
    value: "Supervisor de formación y desarrollo"
  },
  { id: "Supervisor de fundición", value: "Supervisor de fundición" },
  { id: "Supervisor de hidraulica", value: "Supervisor de hidraulica" },
  { id: "Supervisor de hospitalidad", value: "Supervisor de hospitalidad" },
  { id: "Supervisor de imprenta", value: "Supervisor de imprenta" },
  {
    id: "Supervisor de infraestructura",
    value: "Supervisor de infraestructura"
  },
  { id: "Supervisor de ingenieria", value: "Supervisor de ingenieria" },
  { id: "Supervisor de instalaciones", value: "Supervisor de instalaciones" },
  { id: "Supervisor de instalación", value: "Supervisor de instalación" },
  {
    id: "Supervisor de inteligencia de mercado",
    value: "Supervisor de inteligencia de mercado"
  },
  {
    id: "Supervisor de inyección de plástico",
    value: "Supervisor de inyección de plástico"
  },
  { id: "Supervisor de jardinería", value: "Supervisor de jardinería" },
  { id: "Supervisor de laboratorio", value: "Supervisor de laboratorio" },
  { id: "Supervisor de lavandería", value: "Supervisor de lavandería" },
  { id: "Supervisor de limpieza", value: "Supervisor de limpieza" },
  { id: "Supervisor de logistica", value: "Supervisor de logistica" },
  {
    id: "Supervisor de mantenimiento automotriz",
    value: "Supervisor de mantenimiento automotriz"
  },
  {
    id: "Supervisor de mantenimiento de edificios",
    value: "Supervisor de mantenimiento de edificios"
  },
  {
    id: "Supervisor de mantenimiento electromecánico",
    value: "Supervisor de mantenimiento electromecánico"
  },
  {
    id: "Supervisor de mantenimiento eléctrico",
    value: "Supervisor de mantenimiento eléctrico"
  },
  {
    id: "Supervisor de mantenimiento industrial",
    value: "Supervisor de mantenimiento industrial"
  },
  {
    id: "Supervisor de mantenimiento mecánico",
    value: "Supervisor de mantenimiento mecánico"
  },
  { id: "Supervisor de manutención", value: "Supervisor de manutención" },
  { id: "Supervisor de maquinado", value: "Supervisor de maquinado" },
  { id: "Supervisor de marketing", value: "Supervisor de marketing" },
  { id: "Supervisor de medio ambiente", value: "Supervisor de medio ambiente" },
  {
    id: "Supervisor de mercadeo comercial",
    value: "Supervisor de mercadeo comercial"
  },
  { id: "Supervisor de mercadeo", value: "Supervisor de mercadeo" },
  { id: "Supervisor de minas", value: "Supervisor de minas" },
  { id: "Supervisor de montaje", value: "Supervisor de montaje" },
  { id: "Supervisor de negocios", value: "Supervisor de negocios" },
  { id: "Supervisor de nutrición", value: "Supervisor de nutrición" },
  { id: "Supervisor de nómina", value: "Supervisor de nómina" },
  { id: "Supervisor de obra", value: "Supervisor de obra" },
  { id: "Supervisor de operaciones", value: "Supervisor de operaciones" },
  { id: "Supervisor de piezas", value: "Supervisor de piezas" },
  { id: "Supervisor de pintura", value: "Supervisor de pintura" },
  { id: "Supervisor de planificación", value: "Supervisor de planificación" },
  { id: "Supervisor de posventa", value: "Supervisor de posventa" },
  {
    id: "Supervisor de prevención de pérdidas",
    value: "Supervisor de prevención de pérdidas"
  },
  { id: "Supervisor de proceso", value: "Supervisor de proceso" },
  { id: "Supervisor de producción", value: "Supervisor de producción" },
  { id: "Supervisor de producto", value: "Supervisor de producto" },
  { id: "Supervisor de proyecto", value: "Supervisor de proyecto" },
  {
    id: "Supervisor de proyectos sociales",
    value: "Supervisor de proyectos sociales"
  },
  { id: "Supervisor de pwp", value: "Supervisor de pwp" },
  { id: "Supervisor de radiología", value: "Supervisor de radiología" },
  { id: "Supervisor de recepción", value: "Supervisor de recepción" },
  {
    id: "Supervisor de reclutamiento y selección",
    value: "Supervisor de reclutamiento y selección"
  },
  {
    id: "Supervisor de recursos humanos",
    value: "Supervisor de recursos humanos"
  },
  { id: "Supervisor de refrigeración", value: "Supervisor de refrigeración" },
  { id: "Supervisor de relaciones", value: "Supervisor de relaciones" },
  { id: "Supervisor de reservas", value: "Supervisor de reservas" },
  { id: "Supervisor de sacos", value: "Supervisor de sacos" },
  { id: "Supervisor de seguimiento", value: "Supervisor de seguimiento" },
  {
    id: "Supervisor de seguridad de activos",
    value: "Supervisor de seguridad de activos"
  },
  {
    id: "Supervisor de seguridad en el trabajo",
    value: "Supervisor de seguridad en el trabajo"
  },
  { id: "Supervisor de seguros", value: "Supervisor de seguros" },
  {
    id: "Supervisor de servicio al cliente",
    value: "Supervisor de servicio al cliente"
  },
  {
    id: "Supervisor de servicios generales",
    value: "Supervisor de servicios generales"
  },
  { id: "Supervisor de sucursal", value: "Supervisor de sucursal" },
  { id: "Supervisor de suministro", value: "Supervisor de suministro" },
  {
    id: "Supervisor de taller de herramientas",
    value: "Supervisor de taller de herramientas"
  },
  {
    id: "Supervisor de tecnología de la información",
    value: "Supervisor de tecnología de la información"
  },
  { id: "Supervisor de tesorería", value: "Supervisor de tesorería" },
  { id: "Supervisor de tienda", value: "Supervisor de tienda" },
  { id: "Supervisor de transporte", value: "Supervisor de transporte" },
  { id: "Supervisor de ventas", value: "Supervisor de ventas" },
  {
    id: "Supervisor del centro de llamadas",
    value: "Supervisor del centro de llamadas"
  },
  {
    id: "Supervisor del defensor del pueblo",
    value: "Supervisor del defensor del pueblo"
  },
  {
    id: "Supervisor del departamento de personal",
    value: "Supervisor del departamento de personal"
  },
  { id: "Supervisor del restaurante", value: "Supervisor del restaurante" },
  { id: "Supervisor eléctrico", value: "Supervisor eléctrico" },
  { id: "Supervisor financiero", value: "Supervisor financiero" },
  { id: "Supervisor fiscal", value: "Supervisor fiscal" },
  { id: "Supervisor forestal", value: "Supervisor forestal" },
  { id: "Supervisor industrial", value: "Supervisor industrial" },
  { id: "Supervisor mecanico", value: "Supervisor mecanico" },
  { id: "Supervisor operativo", value: "Supervisor operativo" },
  { id: "Supervisor pedagógico", value: "Supervisor pedagógico" },
  { id: "Supervisor técnico", value: "Supervisor técnico" },
  { id: "Supervisor", value: "Supervisor" },
  { id: "Sushiman", value: "Sushiman" },
  { id: "Tapicero", value: "Tapicero" },
  { id: "Tapicería de automóviles", value: "Tapicería de automóviles" },
  { id: "Taquillera", value: "Taquillera" },
  { id: "Tasador de bienes raíces", value: "Tasador de bienes raíces" },
  { id: "Tasador de vehículos", value: "Tasador de vehículos" },
  { id: "Tasador físico", value: "Tasador físico" },
  { id: "Techador", value: "Techador" },
  { id: "Tecnico de calidad", value: "Tecnico de calidad" },
  { id: "Tecnico de laboratorio", value: "Tecnico de laboratorio" },
  { id: "Tecnico electromecanico", value: "Tecnico electromecanico" },
  { id: "Tecnico en quimica", value: "Tecnico en quimica" },
  { id: "Tecnico mecanico", value: "Tecnico mecanico" },
  { id: "Tecnólogo de comida", value: "Tecnólogo de comida" },
  { id: "Tecnólogo de la construcción", value: "Tecnólogo de la construcción" },
  { id: "Tecnólogo en radiología", value: "Tecnólogo en radiología" },
  {
    id: "Tecnólogo en redes informáticas",
    value: "Tecnólogo en redes informáticas"
  },
  {
    id: "Tecnólogo en saneamiento ambiental",
    value: "Tecnólogo en saneamiento ambiental"
  },
  { id: "Tejedor", value: "Tejedor" },
  { id: "Telefonista recepcionista", value: "Telefonista recepcionista" },
  { id: "Terapeuta ocupacional", value: "Terapeuta ocupacional" },
  { id: "Terminador de embalaje", value: "Terminador de embalaje" },
  { id: "Tesorero", value: "Tesorero" },
  { id: "Topógrafo", value: "Topógrafo" },
  { id: "Tornero", value: "Tornero" },
  {
    id: "Trabajador de laboratorio de hormigón",
    value: "Trabajador de laboratorio de hormigón"
  },
  { id: "Traductor alemán", value: "Traductor alemán" },
  { id: "Traductor chino", value: "Traductor chino" },
  { id: "Traductor de coreano", value: "Traductor de coreano" },
  { id: "Traductor de español", value: "Traductor de español" },
  { id: "Traductor de ingles", value: "Traductor de ingles" },
  { id: "Traductor de japonés", value: "Traductor de japonés" },
  { id: "Traductor de mandarín", value: "Traductor de mandarín" },
  { id: "Traductor francés", value: "Traductor francés" },
  { id: "Traductor italiano", value: "Traductor italiano" },
  { id: "Traductor árabe", value: "Traductor árabe" },
  { id: "Tramitador de reclamaciones", value: "Tramitador de reclamaciones" },
  { id: "Trazador de calderería", value: "Trazador de calderería" },
  { id: "Turistalogo", value: "Turistalogo" },
  { id: "Técnico administrativo", value: "Técnico administrativo" },
  { id: "Técnico agrícola", value: "Técnico agrícola" },
  { id: "Técnico ambiental", value: "Técnico ambiental" },
  { id: "Técnico bancario", value: "Técnico bancario" },
  { id: "Técnico comercial", value: "Técnico comercial" },
  { id: "Técnico contable", value: "Técnico contable" },
  { id: "Técnico de alimentos", value: "Técnico de alimentos" },
  { id: "Técnico de archivo", value: "Técnico de archivo" },
  {
    id: "Técnico de asistencia técnica",
    value: "Técnico de asistencia técnica"
  },
  { id: "Técnico de audio y video", value: "Técnico de audio y video" },
  {
    id: "Técnico de automatización bancaria",
    value: "Técnico de automatización bancaria"
  },
  { id: "Técnico de ayuda informática", value: "Técnico de ayuda informática" },
  { id: "Técnico de biblioteca", value: "Técnico de biblioteca" },
  { id: "Técnico de cableado", value: "Técnico de cableado" },
  { id: "Técnico de caminos", value: "Técnico de caminos" },
  { id: "Técnico de campo", value: "Técnico de campo" },
  { id: "Técnico de cctv", value: "Técnico de cctv" },
  {
    id: "Técnico de control de calidad",
    value: "Técnico de control de calidad"
  },
  { id: "Técnico de despliegue", value: "Técnico de despliegue" },
  { id: "Técnico de documentación", value: "Técnico de documentación" },
  {
    id: "Técnico de electrocardiograma",
    value: "Técnico de electrocardiograma"
  },
  {
    id: "Técnico de electroencefalograma",
    value: "Técnico de electroencefalograma"
  },
  { id: "Técnico de enfermería", value: "Técnico de enfermería" },
  { id: "Técnico de equipos médicos", value: "Técnico de equipos médicos" },
  { id: "Técnico de equipos", value: "Técnico de equipos" },
  { id: "Técnico de eventos", value: "Técnico de eventos" },
  { id: "Técnico de fibra óptica", value: "Técnico de fibra óptica" },
  { id: "Técnico de geoprocesamiento", value: "Técnico de geoprocesamiento" },
  { id: "Técnico de hemoterapia", value: "Técnico de hemoterapia" },
  { id: "Técnico de impresoras", value: "Técnico de impresoras" },
  { id: "Técnico de infraestructura", value: "Técnico de infraestructura" },
  {
    id: "Técnico de inspección de equipos",
    value: "Técnico de inspección de equipos"
  },
  { id: "Técnico de instalación", value: "Técnico de instalación" },
  { id: "Técnico de instrumentación", value: "Técnico de instrumentación" },
  {
    id: "Técnico de la mesa de servicio",
    value: "Técnico de la mesa de servicio"
  },
  {
    id: "Técnico de laboratorio de suelos",
    value: "Técnico de laboratorio de suelos"
  },
  {
    id: "Técnico de laboratorio industrial",
    value: "Técnico de laboratorio industrial"
  },
  { id: "Técnico de lubricación", value: "Técnico de lubricación" },
  {
    id: "Técnico de mantenimiento automotriz",
    value: "Técnico de mantenimiento automotriz"
  },
  {
    id: "Técnico de mantenimiento de ascensores",
    value: "Técnico de mantenimiento de ascensores"
  },
  {
    id: "Técnico de mantenimiento de computadoras",
    value: "Técnico de mantenimiento de computadoras"
  },
  {
    id: "Técnico de mantenimiento de edificios",
    value: "Técnico de mantenimiento de edificios"
  },
  {
    id: "Técnico de mantenimiento eléctrico",
    value: "Técnico de mantenimiento eléctrico"
  },
  {
    id: "Técnico de mantenimiento industrial",
    value: "Técnico de mantenimiento industrial"
  },
  {
    id: "Técnico de mantenimiento mecánico",
    value: "Técnico de mantenimiento mecánico"
  },
  { id: "Técnico de mantenimiento", value: "Técnico de mantenimiento" },
  { id: "Técnico de materiales", value: "Técnico de materiales" },
  { id: "Técnico de medición", value: "Técnico de medición" },
  { id: "Técnico de minas", value: "Técnico de minas" },
  { id: "Técnico de montaje", value: "Técnico de montaje" },
  {
    id: "Técnico de orientación y movilidad",
    value: "Técnico de orientación y movilidad"
  },
  { id: "Técnico de panadería", value: "Técnico de panadería" },
  {
    id: "Técnico de planificación de mantenimiento",
    value: "Técnico de planificación de mantenimiento"
  },
  { id: "Técnico de planificación", value: "Técnico de planificación" },
  { id: "Técnico de plásticos", value: "Técnico de plásticos" },
  { id: "Técnico de polisomnografía", value: "Técnico de polisomnografía" },
  { id: "Técnico de presupuesto", value: "Técnico de presupuesto" },
  { id: "Técnico de procesos", value: "Técnico de procesos" },
  { id: "Técnico de producto", value: "Técnico de producto" },
  { id: "Técnico de proyectos", value: "Técnico de proyectos" },
  { id: "Técnico de pruebas", value: "Técnico de pruebas" },
  { id: "Técnico de radiología", value: "Técnico de radiología" },
  { id: "Técnico de reclamaciones", value: "Técnico de reclamaciones" },
  { id: "Técnico de recursos humanos", value: "Técnico de recursos humanos" },
  { id: "Técnico de redes", value: "Técnico de redes" },
  { id: "Técnico de refrigeración", value: "Técnico de refrigeración" },
  { id: "Técnico de saneamiento", value: "Técnico de saneamiento" },
  {
    id: "Técnico de seguridad electrónica",
    value: "Técnico de seguridad electrónica"
  },
  { id: "Técnico de seguros", value: "Técnico de seguros" },
  { id: "Técnico de servicio", value: "Técnico de servicio" },
  { id: "Técnico de sonido", value: "Técnico de sonido" },
  { id: "Técnico de soporte", value: "Técnico de soporte" },
  { id: "Técnico de tacógrafo", value: "Técnico de tacógrafo" },
  {
    id: "Técnico de telecomunicaciones",
    value: "Técnico de telecomunicaciones"
  },
  { id: "Técnico de terapia de gas", value: "Técnico de terapia de gas" },
  { id: "Técnico de ventas", value: "Técnico de ventas" },
  { id: "Técnico de vigilancia", value: "Técnico de vigilancia" },
  { id: "Técnico de yeso", value: "Técnico de yeso" },
  { id: "Técnico electronico", value: "Técnico electronico" },
  {
    id: "Técnico en agricultura y ganadería",
    value: "Técnico en agricultura y ganadería"
  },
  { id: "Técnico en agrimensura", value: "Técnico en agrimensura" },
  { id: "Técnico en análisis clínicos", value: "Técnico en análisis clínicos" },
  {
    id: "Técnico en automatización de edificios",
    value: "Técnico en automatización de edificios"
  },
  {
    id: "Técnico en automatización industrial",
    value: "Técnico en automatización industrial"
  },
  { id: "Técnico en edificaciones", value: "Técnico en edificaciones" },
  { id: "Técnico en electrónica", value: "Técnico en electrónica" },
  {
    id: "Técnico en enfermería ocupacional",
    value: "Técnico en enfermería ocupacional"
  },
  { id: "Técnico en espirometría", value: "Técnico en espirometría" },
  { id: "Técnico en farmacología", value: "Técnico en farmacología" },
  { id: "Técnico en gastronomía", value: "Técnico en gastronomía" },
  { id: "Técnico en informática", value: "Técnico en informática" },
  {
    id: "Técnico en inmovilización ortopédica",
    value: "Técnico en inmovilización ortopédica"
  },
  { id: "Técnico en logística", value: "Técnico en logística" },
  {
    id: "Técnico en marcos de aluminio",
    value: "Técnico en marcos de aluminio"
  },
  { id: "Técnico en mecatrónica", value: "Técnico en mecatrónica" },
  { id: "Técnico en mecánica", value: "Técnico en mecánica" },
  { id: "Técnico en metalurgia", value: "Técnico en metalurgia" },
  { id: "Técnico en metrología", value: "Técnico en metrología" },
  { id: "Técnico en nutrición", value: "Técnico en nutrición" },
  { id: "Técnico en optometría", value: "Técnico en optometría" },
  {
    id: "Técnico en procesos industriales",
    value: "Técnico en procesos industriales"
  },
  { id: "Técnico en producción", value: "Técnico en producción" },
  { id: "Técnico en salud bucal", value: "Técnico en salud bucal" },
  {
    id: "Técnico en seguridad del trabajo",
    value: "Técnico en seguridad del trabajo"
  },
  {
    id: "Técnico en seguridad y medio ambiente",
    value: "Técnico en seguridad y medio ambiente"
  },
  {
    id: "Técnico en tecnología de la información",
    value: "Técnico en tecnología de la información"
  },
  { id: "Técnico en turismo", value: "Técnico en turismo" },
  { id: "Técnico financiero", value: "Técnico financiero" },
  { id: "Técnico forestal", value: "Técnico forestal" },
  { id: "Técnico hidráulico", value: "Técnico hidráulico" },
  { id: "Técnico industrial", value: "Técnico industrial" },
  { id: "Técnico móvil", value: "Técnico móvil" },
  { id: "Técnico operativo", value: "Técnico operativo" },
  { id: "Técnico textil", value: "Técnico textil" },
  { id: "Técnico óptico", value: "Técnico óptico" },
  { id: "Técnicos de iluminación", value: "Técnicos de iluminación" },
  { id: "Un traductor", value: "Un traductor" },
  { id: "Vendedor arquitecto", value: "Vendedor arquitecto" },
  { id: "Vendedor de carga", value: "Vendedor de carga" },
  {
    id: "Vendedor de comercio electrónico",
    value: "Vendedor de comercio electrónico"
  },
  { id: "Vendedor de computadoras", value: "Vendedor de computadoras" },
  { id: "Vendedor de consorcio", value: "Vendedor de consorcio" },
  { id: "Vendedor de franquicias", value: "Vendedor de franquicias" },
  { id: "Vendedor de máquinas", value: "Vendedor de máquinas" },
  { id: "Vendedor de proyectos", value: "Vendedor de proyectos" },
  { id: "Vendedor de seguros", value: "Vendedor de seguros" },
  {
    id: "Vendedor de telecomunicaciones",
    value: "Vendedor de telecomunicaciones"
  },
  { id: "Vendedor de tienda", value: "Vendedor de tienda" },
  { id: "Vendedor de vehículos", value: "Vendedor de vehículos" },
  { id: "Vendedor externo", value: "Vendedor externo" },
  { id: "Vendedor industrial", value: "Vendedor industrial" },
  { id: "Vendedor técnico", value: "Vendedor técnico" },
  { id: "Vendedor", value: "Vendedor" },
  { id: "Veterinario", value: "Veterinario" },
  { id: "Videografo", value: "Videografo" },
  { id: "Vidriero", value: "Vidriero" },
  { id: "Vigilante líder", value: "Vigilante líder" },
  { id: "Vigilante", value: "Vigilante" },
  { id: "Visitante de barco", value: "Visitante de barco" },
  { id: "Vocero", value: "Vocero" },
  { id: "Volteador de herramientas", value: "Volteador de herramientas" },
  { id: "Yesero", value: "Yesero" },
  { id: "Zapatero", value: "Zapatero" },
  { id: "Zootecnista", value: "Zootecnista" }
];

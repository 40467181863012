import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IconButton, Button, Typography, Box, Modal } from "@mui/material";

/* ICONS */
import CloseIcon from "@mui/icons-material/Close";
import NuevoTipoTrabajador from "./nuevoTipoTrabajador";

/* CONTROLLER VARIABLES */
import { littleSizeFunc } from "../../../controller/windowSize";
import { useDispatch } from "react-redux";
import {
  setSuccessText,
  setOpenConfirmModal,
  setErrorText,
} from "../../../redux/confirmModalSlice";
import { useAddTipoTrabajadorMutation } from "../../../api/tipotrabajadoresApiSlice";

export default function NuevoTipoTrabajadorModal(props) {
  const { openModal, setOpenModal } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const littleSize = littleSizeFunc();

  const [add, addResult] = useAddTipoTrabajadorMutation();

  const [continueValue, setContinueValue] = useState(false);
  const [tipoTrabajador, setTipoTrabajador] = useState({});

  useEffect(() => {
    if (continueValue && !openModal) {
      setContinueValue(false);
      dispatch(
        setSuccessText("¡Nuevo tipo de trabajador añadido de forma exitosa!")
      );
      dispatch(setOpenConfirmModal(true));
      navigate("./");
    }
  }, [continueValue, openModal]);

  const handleCloseModal = () => {
    setContinueValue(false);
    setOpenModal(false);
  };

  const handleGuardar = async () => {
    try {
      await add(tipoTrabajador).unwrap();
      dispatch(
        setSuccessText("¡Nuevo tipo de trabajador añadido de forma exitosa!")
      );
      handleCloseModal();
    } catch (e) {
      console.error(e);
      dispatch(setErrorText("¡Hubo un error, intente nuevamente más tarde!"));
    }
    dispatch(setOpenConfirmModal(true));
  };

  const modalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    maxWidth: "1400px",
    height: "fit-content",
    maxHeight: "98vh",
    display: "flex",
    alignItems: littleSize ? "center" : "",
    flexDirection: "column",
    background: "#FCFBFB",
    borderRadius: "20px",
    padding: "5.882vh 3.472vw ",
    gap: "11px",
  };
  const closeButtonBox = {
    position: "absolute",
    right: "2vmin",
    top: "2vmin",
  };
  const content = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto",
  };
  const title = {
    fontWeight: 900,
    textAlign: littleSize ? "center" : "",
    fontSize: "32px",
    lineHeight: "140%",
  };
  const subtitle = {
    fontWeight: 400,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "150%",
    alignItems: littleSize ? "center" : "",
    textAlign: littleSize ? "center" : "",
  };
  const buttonBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: littleSize ? "center" : "flex-end",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "17px",
    margin: "0 0 1px 0",
  };
  const button = {
    minWidth: "160px",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: 600,
  };

  // useEffect(() => {
  //   setContinueValue(true);
  // }, [
  //   persona
  // ]);

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={modalBox}>
        <Box sx={closeButtonBox}>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography sx={title}>Nuevo tipo de trabajador</Typography>
        <Typography sx={subtitle}>
          Seleccione el nombre, icono y documentación del nuevo tipo de
          trabajador
        </Typography>
        <Box sx={content}>
          <NuevoTipoTrabajador
            open={openModal}
            setContinueValue={setContinueValue}
            setTipoTrabajador={setTipoTrabajador}
          />
        </Box>
        <Box sx={buttonBox}>
          <Button
            sx={button}
            variant="contained"
            onClick={handleGuardar}
            disableElevation
            disabled={!continueValue}
          >
            Crear
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
